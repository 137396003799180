/* eslint-disable max-len */
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import './MercurxContractLockDetailInputs.scss';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { abiRequestAction } from '../../store/abi/abiActions';
import wallet from '../../helpers/wallet';
import Swal from 'sweetalert2';
import { mainColors } from '../../helpers/colors';
import { getBNB } from '../../store/bnb/bnbActions';
import { setAlertAction } from '../../store/alert/alertActions';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { setLoadingFullAction } from '../../store/loading/loadingActions';
import { Badge } from 'react-bootstrap';
import { transactionRequest } from '../../store/bo_transaction/bo_transactionActions';
const MercurxContractLockDetailInputs = ({ ...props }) => {
  const [selectedUnit, setSelectedUnit] = useState('WEI');

  const handleUnitChange = (unit) => {
    setSelectedUnit(unit);
  };
  const {
    project,
    abiHistoryRequest,
    abiHistory,
    getBNBRequest,
    bnbData,
    setAlert,
    setLoadingFull,
    isLoading,
    transactionRequest,
    accounts
  } = props;

  const [claim, setClaim] = useState('');
  const [completedUniqueAddresses, setCompletedUniqueAddresses] = useState('');
  const [uniqueAddresses, setUniqueAddresses] = useState('');
  const [rewardRates, setRewardRates] = useState('');
  const [spenderLockedInfos, setSpenderLockedInfos] = useState('');
  const [valueLockedInfos, setValueLockedInfos] = useState('');
  const [amountLockTokens, setAmountLockTokens] = useState('');
  const [durationLockTokens, setDurationLockTokens] = useState('');
  const [transferNewOwnership, setTransferNewOwnership] = useState('');
  const [updateDuration, setUpdateDuration] = useState('');
  const [newRewardRate, setNewRewardRate] = useState('');
  const [recipient, setRecipient] = useState('');
  const [amountWithDrawToken, setAmountWithDrawToken] = useState('');
  const [results, setResults] = useState(null);
  const resultList = {};
  const [resultsLists, setResultsLists] = useState({});
  const [divtransactionHash, setDivTransactionHash] = useState('');
  const [showRewardRates, setShowRewardRates] = useState(false);
  const [showUniqueAddresses, setShowUniqueAddresses] = useState(false);
  const [showLockedInfos, setShowLockedInfos] = useState(false);
  const [showCompletedUnique, setShowCompletedUnique] = useState(false);
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setAlert({
          title: 'Success!',
          text: 'Text copied to clipboard',
          variant: 'success',
          outTimeMS: 2000,
        });
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Unable to copy text to clipboard', err);
      });
  };
  const handleAbi = () => {
    abiHistoryRequest();
    getBNBRequest();
  };
  const [txs, setTxs] = useState([]);
  useEffect(() => {
    handleAbi();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      console.log(resultsLists);
    };

    fetchData();
  }, []); // useEffect'in sadece ilk render'da çalışması için dependency array'i boş bırakıldı.

  console.log(resultsLists);
  const handleCompletedUniqueClick = () => {
    setShowCompletedUnique(!showCompletedUnique);
  };
  const handleUniqueAddressesClick = () => {
    setShowUniqueAddresses(!showUniqueAddresses);
  };
  const handleRewardRatesClick = () => {
    setShowRewardRates(!showRewardRates);
  };
  const handleLockedInfosClick = () => {
    setShowLockedInfos(!showLockedInfos);
  };
  function handleCompletedUniqueAddressesInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'completedUniqueAddresses') {
      console.log(`completedUniqueAddresses: ${inputValue}`);
      setCompletedUniqueAddresses(inputValue);
    }
  }
  function handleUniqueAddressesInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'uniqueAddresses') {
      console.log(`uniqueAddresses: ${inputValue}`);
      setUniqueAddresses(inputValue);
    }
  }
  function handleRewardRatesInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'rewardRates') {
      console.log(`rewardRates: ${inputValue}`);
      setRewardRates(inputValue);
    }
  }
  function handleLockedInfosInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'spenderLockedInfos') {
      console.log(`spenderLockedInfos: ${inputValue}`);
      setSpenderLockedInfos(inputValue);
    } else if (inputType === 'valueLockedInfos') {
      console.log(`valueLockedInfos: ${inputValue}`);
      setValueLockedInfos(inputValue);
    }
  }
  //lockTokens
  const [amountLockTokensDuration, setAmountLockTokensDuration] = useState('');
  const [invalidAmountLockTokensDuration, setInvalidAmountLockTokensDuration] = useState(false);
  const handleLockTokensInputDurationChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(0|[1-3])$/;


    if (regex.test(inputValue) || inputValue === '') {
      const amount = inputValue === '' ? '' : parseInt(inputValue, 10);
      setAmountLockTokensDuration(amount);
      setInvalidAmountLockTokensDuration(false);
    } else {
      setInvalidAmountLockTokensDuration(true);
    }
  }
  const [amountBeforeDecimalLockTokens, setAmountBeforeDecimalLockTokens] = useState('1');
  const [invalidAmountLockTokens, setInvalidAmountLockTokens] = useState(false);
  const [amountAfterDecimalLockTokens, setAmountAfterDecimalLockTokens] = useState('0000')

  const handleLockTokensInputBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalLockTokens(inputValue);
      setInvalidAmountLockTokens(false);
    } else {
      setInvalidAmountLockTokens(true);
    }
  }
  const handleLockTokensInputAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,4}$/; // Sadece sayılar, en fazla 4 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 4)) {
      setAmountAfterDecimalLockTokens(inputValue);
    }
  };
  //Claim
  const [amountIndex, setAmountIndex] = useState('');
  const [invalidAmountIndex, setInvalidAmountIndex] = useState(false);
  const handleClaimInputChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(0|[1-9]\d*)$/;
    // TO DO
    // Örneğin: const regex = /^(0|[1-9]\d{0,8})$/; MAKSIMUM AYARLANACAK 

    if (regex.test(inputValue) || inputValue === '') {
      const amount = inputValue === '' ? '' : parseInt(inputValue, 10);
      setAmountIndex(amount);
      setInvalidAmountIndex(false);
    } else {
      setInvalidAmountIndex(true);
    }
  }

  //transferOwnership
  const [spender, setSpender] = useState('');
  const [invalidAddress, setInvalidAddress] = useState(false);
  const handleTransferOwnershipInputChange = (event) => {
    const inputValue = event.target.value;
    setSpender(inputValue);
    setInvalidAddress(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };

  //UpdateReward
  const [amountUpdateRewardDuration, setAmountUpdateRewardDuration] = useState('');
  const [invalidAmountUpdateRewardDuration, setInvalidAmountUpdateRewardDuration] = useState(false);
  const handleUpdateRewardDurationInputChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(0|[1-3])$/;


    if (regex.test(inputValue) || inputValue === '') {
      const amount = inputValue === '' ? '' : parseInt(inputValue, 10);
      setAmountUpdateRewardDuration(amount);
      setInvalidAmountUpdateRewardDuration(false);
    } else {
      setInvalidAmountUpdateRewardDuration(true);
    }
  }
  const [amountBeforeDecimalUpdateReward, setAmountBeforeDecimalUpdateReward] = useState('1');
  const [invalidAmountUpdateReward, setInvalidAmountUpdateReward] = useState(false);
  const [amountAfterDecimalUpdateReward, setAmountAfterDecimalUpdateReward] = useState('0000')

  const handleUpdateRewardInputBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalUpdateReward(inputValue);
      setInvalidAmountUpdateReward(false);
    } else {
      setInvalidAmountUpdateReward(true);
    }
  }
  const handleUpdateRewardInputAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,4}$/; // Sadece sayılar, en fazla 4 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 4)) {
      setAmountAfterDecimalUpdateReward(inputValue);
    }
  };

  //WithDrawToken
  
  const [spenderWithDrawToken, setSpenderWithDrawToken] = useState('');
    const [invalidAddressWithDrawToken, setInvalidAddressWithDrawToken] = useState(false);
    const handleWithDrawTokenAddressInputChange = (event) => {
      const inputValue = event.target.value;
      setSpenderWithDrawToken(inputValue);
      setInvalidAddressWithDrawToken(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
    };
    
    const [amountBeforeDecimalWithDrawToken, setAmountBeforeDecimalWithDrawToken] = useState('1');
    const [invalidAmountWithDrawToken, setInvalidAmountWithDrawToken] = useState(false);
    const [amountAfterDecimalWithDrawToken, setAmountAfterDecimalWithDrawToken] = useState('0000')
    
    const handleWithDrawTokenInputBeforeDecimalChange= (event) => {
      const inputValue = event.target.value;
      const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
      if (regex.test(inputValue) || inputValue === '') {
        setAmountBeforeDecimalWithDrawToken(inputValue);
        setInvalidAmountWithDrawToken(false);
      } else {
        setInvalidAmountWithDrawToken(true);
      }
    }
    const handleWithDrawTokenInputAfterDecimalChange = (event) => {
      const inputValue = event.target.value;
      const regex = /^\d{0,4}$/; // Sadece sayılar, en fazla 4 basamak
      if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 4)) {
        setAmountAfterDecimalWithDrawToken(inputValue);
      }
    };

  const closeModal = () => {
    abiHistoryRequest(false);
  };
  let val = 0.0;
  const runContract = async (functionType, isCall = false, params = '', vals = '') => {
    console.log(functionType);
    let transaction;
    let transactionHashLock;
    setTxs([]);
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const project_id = 1;
      const project_name = "MERCURX_LOCK";
      const user_public_address = signerAddress;
      const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
      const transaction_time = new Date();
      const web3 = new Web3(window.ethereum);

      await wallet.controlAndSwitchOrAddNetwork();
      await window.ethereum.enable();
      const mercurx_lock = new web3.eth.Contract(
        abiHistory?.[0]?.['MERX_lock_abi'],
        '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
      );
      const contractMethod = mercurx_lock.methods[functionType];
      const data = contractMethod(...params).encodeABI();
      if (isCall) {
        if (params == '' && vals == '') {
          const transaction = await mercurx_lock.methods[functionType]().call();
          setResults(transaction);
          resultList[functionType] = transaction;
          setResultsLists((prevState) => {
            return {
              ...prevState,
              [functionType]: transaction,
            };
          });
          setLoadingFull({ isLoading: false });
        } else if (params != '' && vals == '') {
          const transaction = await contractMethod(...params).call();
          setResults(transaction);
          resultList[functionType] = transaction;
          setResultsLists((prevState) => {
            return {
              ...prevState,
              [functionType]: transaction,
            };
          });
          transactionHashLock = transaction["transactionHash"];
          setLoadingFull({ isLoading: false });
        }
      } else {
        if (params == '' && vals != '') {
          val = (parseFloat(bnbData?.price) * parseFloat(vals)) / parseFloat('3');
          val = val.toFixed(4).toString();
          val = parseFloat(val.replace('.', ''), 10);
          let bnb = parseFloat(vals).toFixed(18);
          bnb = parseFloat(bnb.toString().replace('.', ''), 10);
          const transaction = await web3.eth.sendTransaction({
            from: signerAddress,
            to: '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
            data: data,
            value: bnb,
          });
          transactionHashLock = transaction["transactionHash"];
          setDivTransactionHash(transaction);
          const transaction_status = transaction.status;
          const transaction_hash = transaction.transactionHash;
          const token_count = params[params.length - 1];
          const action = functionType;
          const payload2 = {
            action,
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,


          };
          console.log('1' + payload2)
          transactionRequest(payload2);
          console.log(transaction['transactionHash']);
        }
        else if ((params != '' && vals == '')) {
          const transaction = await web3.eth.sendTransaction({
            from: signerAddress,
            to: '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
            data: data,
          });
          transactionHashLock = transaction["transactionHash"];
          setDivTransactionHash(transaction);
          const transaction_status = transaction.status;
          const transaction_hash = transaction.transactionHash;
          const token_count = params[params.length - 1];
          const action = functionType;
          const payload2 = {
            action,
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,


          };
          console.log('2' + payload2)
          transactionRequest(payload2);
          console.log(transaction['transactionHash']);
          console.log(transaction);
        } else {
          const transaction = await mercurx_lock.methods[functionType].send({
            from: signerAddress,
            to: '0x58c12fe3Fa9e3EF857A3B9E32927F7Fca9C75Abb',
            data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
          });
          transactionHashLock = transaction["transactionHash"];
          setDivTransactionHash(transaction);
          const transaction_status = transaction.status;
          const transaction_hash = transaction.transactionHash;
          const token_count = params[params.length - 1];
          const action = functionType;
          const payload2 = {
            action,
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,
          };
          console.log('3' + payload2)
          // console.log(payload2)
          transactionRequest(payload2);
          console.log(transaction['transactionHash']);

        }
      }

      if (!isCall) {
        Swal.fire({
          icon: 'success',
          iconColor: mainColors.primary,
          text: 'Transaction succeed',
          confirmButtonColor: mainColors.primary,
          html: `<a href=https://testnet.bscscan.com/tx/${transactionHashLock}
     target='_blank'> Check Detail Transaction !</a>`,
        }).then(closeModal);
        setLoadingFull({ isLoading: false });
      }
    } catch (err) {
      const receiptObject = err['receipt'];
      setDivTransactionHash(receiptObject);
      if (err?.receipt?.transactionHashLock) {
        Swal.fire({
          icon: 'error',
          iconColor: '#E40039',
          title: 'Transaction is Failed',
          confirmButtonColor: '#E40039',
          html: `<a href=https://testnet.bscscan.com/tx/${transactionHashLock}
     target='_blank'> Check Detail Transaction !</a>`,
        }).then(closeModal);
        setLoadingFull({ isLoading: false });
      } else {
        Swal.fire({
          icon: 'warning',
          iconColor: '#E40039',
          confirmButtonColor: '#E40039',
          text: err.message,
        }).then(closeModal);
        setLoadingFull({ isLoading: false });
      }
      const transaction_status=false;
      const action = functionType;
      const project_id=1;
      const project_name='MERCURX_LOCK'
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const user_public_address = signerAddress;
      const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
      const transaction_time = new Date();
      const transaction_hash = receiptObject?.transactionHash || '';
      const token_count = params[params.length - 1]*(10**4);
      const payload2 = {
        action,
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
      };
      transactionRequest(payload2);
 
    }
  };

  return (
    <div>
      <div className="contract-detail">
        <Accordion className="mt-5 shadow" defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="text-fs-head-sm">Lock Contract</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="text-fs-head-sm">Deployed Contract Write</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col md="12">
                          {/* claim */}
                          <Form.Group className="mt-1">
                            <div className="text-fs-head-sm">claim</div>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>index:</Form.Label>
                            <Form.Control
                              id="index"
                              type="text"
                              placeholder='min 0'
                              value={amountIndex}
                              onChange={handleClaimInputChange}
                              isInvalid={invalidAmountIndex}
                              style={{ maxWidth: '180px' }}
                            />
                            <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                              Min value is 0
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => runContract("claim", false, [amountIndex])}
                            disabled={ !accounts?.[0] ||invalidAmountIndex || amountIndex === ''}
                          >
                            transact
                          </Button>

                          {/* lockTokens */}
                          <Form.Group className="mt-4">
                            <div className="text-fs-head-sm">lockTokens</div>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>amount:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimalLockTokens"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalLockTokens}
                                onChange={handleLockTokensInputBeforeDecimalChange}
                                isInvalid={invalidAmountLockTokens}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimalLockTokens"
                                type="text"
                                value={amountAfterDecimalLockTokens}
                                onChange={handleLockTokensInputAfterDecimalChange}
                                isInvalid={invalidAmountLockTokens}
                                style={{ maxWidth: '90px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value is 1
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>duration:</Form.Label>
                            <Form.Control
                              id="amountDuration"
                              type="text"
                              value={amountLockTokensDuration}
                              onChange={handleLockTokensInputDurationChange}
                              placeholder='0,1,2,3'
                              isInvalid={invalidAmountLockTokensDuration}
                              style={{ maxWidth: '180px' }}
                            />
                            <Form.Control.Feedback className='mx-0 my-2' type="invalid">
                              The value must be 0, 1, 2, or 3
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalLockTokens}${amountAfterDecimalLockTokens}`;
                              const paddedAmount = fullAmount.padEnd(5, '0');
                              runContract("lockTokens", false, [paddedAmount,amountLockTokensDuration])}
                            }
                            // onClick={() =>
                            //   runContract('lockTokens', false, [
                            //     `${amountBeforeDecimalLockTokens}${amountAfterDecimalLockTokens}`,
                            //     durationLockTokens,
                            //   ])
                            // }
                            disabled={ !accounts?.[0] ||invalidAmountLockTokensDuration || !amountBeforeDecimalLockTokens || amountLockTokensDuration === ''}
                          >
                            transact
                          </Button>
                          {/* transferOwnership */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">transferOwnership</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>newOwner:</Form.Label>
                            <Form.Control
                              id="spender"
                              type="text"
                              placeholder="Address"
                              onChange={handleTransferOwnershipInputChange}
                              isInvalid={invalidAddress}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() =>
                              runContract('transferOwnership', false, [spender])
                            }
                            disabled={ !accounts?.[0] ||invalidAddress}
                          >
                            transact
                          </Button>

                          {/* updateReward */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">updateReward</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>duration:</Form.Label>
                            <Form.Control
                              id="spender"
                              type="text"
                              placeholder='0,1,2,3'
                              value={amountUpdateRewardDuration}
                              onChange={handleUpdateRewardDurationInputChange}
                              isInvalid={invalidAmountUpdateRewardDuration}
                              style={{ maxWidth: '180px' }}
                            />
                            <Form.Control.Feedback className='mx-0 my-2' type="invalid">
                              The value must be 0, 1, 2, or 3
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>newRewardRate:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimalLockTokens"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalUpdateReward}
                                onChange={handleUpdateRewardInputBeforeDecimalChange}
                                isInvalid={invalidAmountUpdateReward}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimalLockTokens"
                                type="text"
                                value={amountAfterDecimalUpdateReward}
                                onChange={handleUpdateRewardInputAfterDecimalChange}
                                isInvalid={invalidAmountUpdateReward}
                                style={{ maxWidth: '90px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value is 1
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalUpdateReward}${amountAfterDecimalUpdateReward}`;
                              runContract("updateReward", false, [amountUpdateRewardDuration,fullAmount])}
                            }
                            // onClick={() =>
                            //   runContract('updateReward', false, [updateDuration, `${amountBeforeDecimalUpdateReward}${amountAfterDecimalUpdateReward}`])
                            // }
                            disabled={ !accounts?.[0] ||invalidAmountUpdateRewardDuration ||invalidAmountUpdateReward || !amountBeforeDecimalUpdateReward}

                          >
                            transact
                          </Button>

                          {/* withdrawToken */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">withdrawToken</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>recipient:</Form.Label>
                            <Form.Control
                              id="spender"
                              type="text"
                              placeholder="Address"
                              onChange={handleWithDrawTokenAddressInputChange}
                              isInvalid={invalidAddressWithDrawToken}
                            />
                             <Form.Control.Feedback type="invalid">
                              Please enter a valid address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>amount:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimalWithDrawToken"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalWithDrawToken}
                                onChange={handleWithDrawTokenInputBeforeDecimalChange}
                                isInvalid={invalidAmountWithDrawToken}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimalWithDrawToken"
                                type="text"
                                value={amountAfterDecimalWithDrawToken}
                                onChange={handleWithDrawTokenInputAfterDecimalChange}
                                isInvalid={invalidAmountWithDrawToken}
                                style={{ maxWidth: '90px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value 1
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalWithDrawToken}${amountAfterDecimalWithDrawToken}`;
                              const paddedAmount = fullAmount.padEnd(5, '0');
                              runContract('withdrawToken', false, [spenderWithDrawToken, paddedAmount])}
                            }
                            // onClick={() =>
                            //   runContract('withdrawToken', false, [spenderWithDrawToken, `${amountBeforeDecimalWithDrawToken}${amountAfterDecimalWithDrawToken}`])
                            // }
                            disabled={ !accounts?.[0] ||invalidAmountWithDrawToken || !amountBeforeDecimalWithDrawToken}
                          >
                            transact
                          </Button>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion className="mt-3 shadow">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="text-fs-head-sm">Deployed Contract Read</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-preSaleContractOwnerAddress">_lockContractOwnerAddress</Tooltip>}
                          >
                            <Button
                              onClick={() => runContract("_lockContractOwnerAddress", true)}
                              className="me-2 button-size"
                            >
                              _lockContract...

                            </Button>
                          </OverlayTrigger>
                          {resultsLists["_lockContractOwnerAddress"] && (
                            <div className="ml-2 d-flex align-items-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-owner`}>{resultsLists["_lockContractOwnerAddress"]}</Tooltip>}
                              >
                                <div>
                                  {`address: ${resultsLists["_lockContractOwnerAddress"].substring(0, 3)}...${resultsLists["_lockContractOwnerAddress"].slice(-3)}`}
                                </div>
                              </OverlayTrigger>
                              <div className="ms-2">
                                <Button variant="outline-secondary" size="sm" onClick={() => handleCopyToClipboard(resultsLists["_lockContractOwnerAddress"])}
                                  style={{ background: '#f0f0f0' }}>
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-preSaleContractOwnerAddress">_tokenContractAddress</Tooltip>}
                          >
                            <Button
                              onClick={() => runContract("_tokenContractAddress", true)}
                              className="me-2 button-size"
                            >
                              _tokenContrac...
                            </Button>
                          </OverlayTrigger>
                          {resultsLists["_tokenContractAddress"] && (
                            <div className="ml-2 d-flex align-items-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-owner`}>{resultsLists["_tokenContractAddress"]}</Tooltip>}
                              >
                                <div>
                                  {`address: ${resultsLists["_tokenContractAddress"].substring(0, 3)}...${resultsLists["_tokenContractAddress"].slice(-3)}`}
                                </div>
                              </OverlayTrigger>
                              <div className="ms-2">
                                <Button variant="outline-secondary" size="sm" onClick={() => handleCopyToClipboard(resultsLists["_tokenContractAddress"])}
                                  style={{ background: '#f0f0f0' }}>
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-preSaleContractOwnerAddress">completedLockedTokens</Tooltip>}
                          >
                            <Button
                              onClick={() => runContract("completedLockedTokens", true)}
                              className="me-2 button-size"
                            >
                              completedLoc...
                            </Button>
                          </OverlayTrigger>
                          {resultsLists["completedLockedTokens"] && <div className="ml-2">{`uint256: ${resultsLists["completedLockedTokens"]}`}</div>}
                        </div>

                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-completedRewardTokens">completedRewardTokens</Tooltip>}
                          >
                            <Button
                              onClick={() => runContract("completedRewardTokens", true)}
                              className="me-2 button-size"
                            >
                              completedRe...
                            </Button>
                          </OverlayTrigger>

                          {resultsLists['completedRewardTokens'] && (
                            <div className="ml-2">{`uint256: ${resultsLists['completedRewardTokens']}`}</div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-completedTotalUniqueUsers">completedTotalUniqueUsers</Tooltip>}
                          >
                            <Button
                              onClick={() => runContract("completedTotalUniqueUsers", true)}
                              className="me-2 button-size"
                            >
                              completedTota...
                            </Button>
                          </OverlayTrigger>

                          {resultsLists['completedTotalUniqueUsers'] && (
                            <div className="ml-2">{`uint256: ${resultsLists['completedTotalUniqueUsers']}`}</div>
                          )}
                        </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-completedUniqueAddresses">completedUniqueAddresses</Tooltip>}
                          >
                            <Button
                              onClick={handleCompletedUniqueClick}
                              className="me-2 button-size"
                            >
                              completedUni...
                            </Button>
                          </OverlayTrigger>

                          {showCompletedUnique && (
                            <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Form.Group>
                                <Form.Control
                                  id="spender"
                                  type="text"
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleCompletedUniqueAddressesInputChange(e, 'completedUniqueAddresses')
                                  }
                                />
                              </Form.Group>

                              <Button
                                className="custom-button-call m-2"
                                onClick={() => runContract("completedUniqueAddresses", true, [completedUniqueAddresses])}
                              >
                                call
                              </Button>

                              {resultsLists['completedUniqueAddresses'] && (
                                <div className="ml-2">{`${resultsLists['completedUniqueAddresses']}`}</div>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button
                            onClick={() => runContract('owner', true)}
                            className="me-2 button-size"
                          >
                            owner
                          </Button>
                          {resultsLists['owner'] && (
                            <div className="ml-2 d-flex align-items-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-owner`}>{resultsLists['owner']}</Tooltip>
                                }
                              >
                                <div>
                                  {`address: ${resultsLists['owner'].substring(
                                    0,
                                    3,
                                  )}...${resultsLists['owner'].slice(-3)}`}
                                </div>
                              </OverlayTrigger>
                              <div className="ms-2">
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  onClick={() => handleCopyToClipboard(resultsLists['owner'])}
                                  style={{ background: '#f0f0f0' }}
                                >
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </div>
                          )}{' '}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button
                            onClick={() => runContract('paused', true)}
                            className="me-2 button-size"
                          >
                            paused
                          </Button>
                          {resultsLists['paused'] !== undefined && (
                            <div className="ml-2">{`bool: ${resultsLists['paused']}`}</div>
                          )}
                        </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                          <Button
                            onClick={handleRewardRatesClick}
                            className="me-2 button-size"
                          >
                            rewardRates
                          </Button>

                          {showRewardRates && (
                            <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Form.Group>
                                <Form.Control
                                  id="rewardRates"
                                  type="text"
                                  placeholder="uint8"
                                  onChange={(e) =>
                                    handleRewardRatesInputChange(e, 'rewardRates')
                                  }
                                />
                              </Form.Group>

                              <Button
                                className="custom-button-call m-2"
                                onClick={() => runContract("rewardRates", true, [rewardRates])}
                              >
                                call
                              </Button>

                              {resultsLists['rewardRates'] && (
                                <div className="ml-2">{`${resultsLists['rewardRates']}`}</div>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-totalLockedTokens">totalLockedTokens</Tooltip>}
                          >
                            <Button
                              onClick={() => runContract("totalLockedTokens", true)}
                              className="me-2 button-size"
                            >
                              totalLockedTo...
                            </Button>
                          </OverlayTrigger>

                          {resultsLists['totalLockedTokens'] && (
                            <div className="ml-2">{`uint256: ${resultsLists['totalLockedTokens']}`}</div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-totalRewardTokens">totalRewardTokens</Tooltip>}
                          >
                            <Button
                              onClick={() => runContract("totalRewardTokens", true)}
                              className="me-2 button-size"
                            >
                              totalRewardT...
                            </Button>
                          </OverlayTrigger>

                          {resultsLists['totalRewardTokens'] && (
                            <div className="ml-2">{`uint256: ${resultsLists['totalRewardTokens']}`}</div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-totalUniqueUsers">totalUniqueUsers</Tooltip>}
                          >
                            <Button
                              onClick={() => runContract("totalUniqueUsers", true)}
                              className="me-2 button-size"
                            >
                              totalUniqueUs...
                            </Button>
                          </OverlayTrigger>

                          {resultsLists['totalUniqueUsers'] && (
                            <div className="ml-2">{`uint256: ${resultsLists['totalUniqueUsers']}`}</div>
                          )}
                        </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-uniqueAddresses">uniqueAddresses</Tooltip>}
                          >
                            <Button
                              onClick={handleUniqueAddressesClick}
                              className="me-2 button-size"
                            >
                              uniqueAddres...
                            </Button>
                          </OverlayTrigger>

                          {showUniqueAddresses && (
                            <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Form.Group>
                                <Form.Control
                                  id="uniqueAddresses"
                                  type="text"
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleUniqueAddressesInputChange(e, 'uniqueAddresses')
                                  }
                                />
                              </Form.Group>

                              <Button
                                className="custom-button-call m-2"
                                onClick={() => runContract("uniqueAddresses", true, [uniqueAddresses])}
                              >
                                call
                              </Button>

                              {resultsLists['uniqueAddresses'] && (
                                <div className="ml-2">{`${resultsLists['uniqueAddresses']}`}</div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <Button
                            onClick={handleLockedInfosClick}
                            className="me-2 button-size"
                          >
                            lockedInfos
                          </Button>

                          {showLockedInfos && (
                            <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Form.Group>
                                <Form.Control
                                  id="spenderLockedInfos"
                                  type="text"
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleLockedInfosInputChange(e, 'spenderLockedInfos')
                                  }
                                />
                              </Form.Group>

                              <Form.Group className='m-2'>
                                <Form.Control
                                  id="valueLockedInfos"
                                  type="text"
                                  placeholder="uint256"
                                  onChange={(e) =>
                                    handleLockedInfosInputChange(e, 'valueLockedInfos')
                                  }
                                />
                              </Form.Group>

                              <Button
                                className="custom-button-call m-2"
                                onClick={() => runContract("lockedInfos", true, [spenderLockedInfos, valueLockedInfos])}
                              >
                                call
                              </Button>

                            </div>
                          )}

                          {showLockedInfos && resultsLists['lockedInfos'] && (
                            <div className="mt-2">
                              {Object.keys(resultsLists['lockedInfos']).map((key) => (
                                <div key={key} className="ml-2">
                                  <strong>{key}:</strong> {resultsLists['lockedInfos'][key]}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
                <Col md="6">
                  {/* İkinci Accordion */}
                  <Accordion className="shadow">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="text-fs-head-sm">Transaction Details</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {divtransactionHash && (
                          <div
                            className="transaction-details"
                            style={{ overflowWrap: 'break-word' }}
                          >
                            <Form.Group>
                              <Form.Label>
                                Transaction Status:{' '}
                                {divtransactionHash.status ? (
                                  <Badge bg="success">Success</Badge>
                                ) : (
                                  <Badge bg="danger">Fail</Badge>
                                )}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label className="mt-0">Transaction Hash:</Form.Label>
                              <div>{divtransactionHash.transactionHash}</div>
                              <div className="ms-0">
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  onClick={() => handleCopyToClipboard(divtransactionHash.transactionHash)}
                                  style={{ background: '#f0f0f0' }}
                                >
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Block Hash:</Form.Label>
                              <div>{divtransactionHash.blockHash}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Cumulative Gas Used:</Form.Label>
                              <div>{divtransactionHash.cumulativeGasUsed}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Effective Gas Price:</Form.Label>
                              <div>{divtransactionHash.effectiveGasPrice}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>from:</Form.Label>
                              <div>{divtransactionHash.from}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>to:</Form.Label>
                              <div>{divtransactionHash.to}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Gas Used:</Form.Label>
                              <div>{divtransactionHash.gasUsed}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Transaction Index:</Form.Label>
                              <div>{divtransactionHash.transactionIndex}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Type:</Form.Label>
                              <div>{divtransactionHash.type}</div>
                            </Form.Group>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    abiHistory: state.abiReducer.abiHistory,
    bnbData: state.bnbReducer.bnb,
    isLoading: state.loadingReducer.isLoading,
    accounts: state.walletReducer.accounts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    getBNBRequest: (payload) => {
      dispatch(getBNB(payload));
    },
    setAlert: (payload) => {
      dispatch(setAlertAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurxContractLockDetailInputs);

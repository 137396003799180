export const GET_BACKOFFICEUSERS_REQUEST = 'GET_BACKOFFICEUSERS_REQUEST';
export const GET_BACKOFFICEUSERS_DATA = 'GET_BACKOFFICEUSERS_DATA';
export const GET_BACKOFFICEUSERS_ERROR = 'GET_BACKOFFICEUSERS_ERROR';

export const UPDATE_QUICK_FILTER = 'UPDATE_QUICK_FILTER';
export const FILTER_BACKOFFICEUSERS = 'FILTER_BACKOFFICEUSERS';
export const SET_BACKOFFICEUSERS_SORT_DATA = 'SET_BACKOFFICEUSERS_SORT_DATA';
export const SORT_BACKOFFICEUSERS = 'SORT_BACKOFFICEUSERS';

export const CREATE_BACKOFFICEUSER_REQUEST = 'CREATE_BACKOFFICEUSER_REQUEST';
export const CREATE_BACKOFFICEUSER_DATA = 'CREATE_BACKOFFICEUSER_DATA';
export const CREATE_BACKOFFICEUSER_ERROR = 'CREATE_BACKOFFICEUSER_ERROR';

export const UPDATE_BACKOFFICEUSER_REQUEST = 'UPDATE_BACKOFFICEUSER_REQUEST';
export const UPDATE_BACKOFFICEUSER_DATA = 'UPDATE_BACKOFFICEUSER_DATA';
export const UPDATE_BACKOFFICEUSER_ERROR = 'UPDATE_BACKOFFICEUSER_ERROR';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import './MainLayout.scss';
import MercurXSidebarMenu from '../../components/MercurXSidebarMenu/MercurXSidebarMenu';
import MercurXNavbar from '../../components/MercurXNavbar/MercurXNavbar';
import { Container } from 'react-bootstrap';

function MainLayout({ ...props }) {
  const { children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-layout-container">
      <MercurXSidebarMenu />
      <div className="main-layout-left">
        <MercurXNavbar />
        <Container>
        <div className='main-layout-children-container p-3'>{children}</div>
        </Container>
        
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);

import * as types from './userActionTypes';

export const loginActionRequest = (payload) => {
  return {
    type: types.LOGIN_REQUEST,
    payload,
  };
};

export const loginActionData = (payload) => {
  return {
    type: types.LOGIN_DATA,
    payload,
  };
};

export const loginActionError = (payload) => {
  return {
    type: types.LOGIN_ERROR,
    payload,
  };
};
export const logoutRequestAction = (payload) => {
  return {
    type: types.LOGOUT_REQUEST,
    payload,
  };
};
export const logoutData = (payload) => {
  return {
    type: types.LOGIN_DATA,
    payload,
  };
};
export const logoutError = (payload) => {
  return {
    type: types.LOGIN_ERROR,
    payload,
  };
};

//CheckUserToken
export const checkUserTokenRequestAction = (payload) => {
  return {
    type: types.CHECK_USER_TOKEN_REQUEST,
    payload,
  };
};
export const checkUserTokenDataAction = (payload) => {
  return {
    type: types.CHECK_USER_TOKEN_DATA,
    payload,
  };
};
export const checkUserTokenErrorAction = (payload) => {
  return {
    type: types.CHECK_USER_TOKEN_ERROR,
    payload,
  };
};
/**
 *
 * @param {activationToken,activationCode,new_password} creds
 */
export const activationRequest = (creds) => {
  return {
    type: types.ACTIVATION_REQUEST,
    creds,
  };
};
export const activationData = (payload) => {
  return {
    type: types.ACTIVATION_DATA,
    payload,
  };
};
export const activationError = (payload) => {
  return {
    type: types.ACTIVATION_ERROR,
    payload,
  };
};

export const accountVerifiedAction = (payload) => {
  return {
    type: types.ACCOUNT_VERIFIED,
    payload,
  };
};
/**
 *
 * @param {email} creds
 */
export const forgotPasswordRequest = (creds) => {
  return {
    type: types.FORGOT_PASSWORD_REQUEST,
    creds,
  };
};
export const forgotPasswordData = (payload) => {
  return {
    type: types.FORGOT_PASSWORD_DATA,
    payload,
  };
};
export const forgotPasswordError = (payload) => {
  return {
    type: types.FORGOT_PASSWORD_ERROR,
    payload,
  };
};
/**
 *
 * @param {password,confirmPassword,resetToken} creds
 */
export const resetPasswordRequest = (creds) => {
  return {
    type: types.RESET_PASSWORD_REQUEST,
    creds,
  };
};
export const resetPasswordData = (payload) => {
  return {
    type: types.RESET_PASSWORD_DATA,
    payload,
  };
};
export const resetPasswordError = (payload) => {
  return {
    type: types.RESET_PASSWORD_ERROR,
    payload,
  };
};

export const accountDetailsRequestAction = (creds) => {
  return {
    type: types.ACCOUNT_DETAILS_REQUEST,
    creds,
  };
};
export const accountDetailsDataAction = (payload) => {
  return {
    type: types.ACCOUNT_DETAILS_DATA,
    payload,
  };
};
export const accountDetailsErrorAction = (payload) => {
  return {
    type: types.ACCOUNT_DETAILS_ERROR,
    payload,
  };
};

export const resendVerificationEmailRequestAction = (payload) => {
  return {
    type: types.RESEND_VERIFICATION_EMAIL_REQUEST,
    payload,
  };
};
export const resendVerificationEmailDataAction = (payload) => {
  return {
    type: types.RESEND_VERIFICATION_EMAIL_DATA,
    payload,
  };
};
export const resendVerificationEmailErrorAction = (payload) => {
  return {
    type: types.RESEND_VERIFICATION_EMAIL_ERROR,
    payload,
  };
};


export const enable2faRequestAction = (payload) => {
  return {
    type: types.ENABLE_2FA_REQUEST,
    payload,
  };
};
export const enable2faDataAction = (payload) => {
  return {
    type: types.ENABLE_2FA_DATA,
    payload,
  };
};
export const enable2faErrorAction = (payload) => {
  return {
    type: types.ENABLE_2FA_ERROR,
    payload,
  };
};

export const generateQrRequestAction = (payload) => {
  return {
    type: types.GENERATE_QR_REQUEST,
    payload,
  };
};
export const generateQrDataAction = (payload) => {
  return {
    type: types.GENERATE_QR_DATA,
    payload,
  };
};
export const generateQrErrorAction = (payload) => {
  return {
    type: types.GENERATE_QR_ERROR,
    payload,
  };
};

export const verify2faRequestAction = (payload) => {
  return {
    type: types.VERIFY_2FA_REQUEST,
    payload,
  };
};
export const verify2faDataAction = (payload) => {
  return {
    type: types.VERIFY_2FA_DATA,
    payload,
  };
};
export const verify2faErrorAction = (payload) => {
  return {
    type: types.VERIFY_2FA_ERROR,
    payload,
  };
};

export const verify2faSuccessAction = (data) => ({
  type: 'VERIFY_2FA_SUCCESS',
  payload: data,
});


export const create2faRequestAction = (payload) => {
  return {
    type: types.CREATE_2FA_REQUEST,
    payload,
  };
};
export const create2faDataAction = (payload) => {
  return {
    type: types.CREATE_2FA_DATA,
    payload,
  };
};
export const create2faErrorAction = (payload) => {
  return {
    type: types.CREATE_2FA_ERROR,
    payload,
  };
};
import React, { useEffect, useState } from 'react';
import './BackofficeUsers.scss';
import { connect } from 'react-redux';
import {
  getBackofficeUsersActionRequest,
  filterBackofficeUsersAction,
  setBackofficeUsersSortDataAction,
  sortBackofficeUsersAction,
  updateQuickFilterAction
} from '../../store/backofficeUser/backofficeUserActions';
import MercurXBackofficeUserTable
 from '../../components/MercurXBackofficeUser/MercurXBackofficeUserTable';
import {  Button,Col, Dropdown, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { backofficeUsersQuickFilterConstants, sortKeys, sortTypes } 
from './backofficeUsersConstants';


function BackofficeUsers({ ...props }) {
  const {
    getbackofficeUsersRequest,
    quickFilter,
    backofficeUsersSortData,
    updateQuickFilter,
    setBackofficeUsersSortData,
    sortBackofficeUsers,
    filterBackofficeUsers,
  } = props;

  const history = useHistory();
  const [filterInput, setFilterInput] = useState('');
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  useEffect(() => {
    getbackofficeUsersRequest();
    updateQuickFilter(backofficeUsersQuickFilterConstants.ALL)
  }, []);
  useEffect(() => {
    filterBackofficeUsers(filterInput);
    sortBackofficeUsers();
  }, [quickFilter]);

  useEffect(() => {
    filterBackofficeUsers(filterInput);
  }, [filterInput])

  function changeSortType(sortType) {
    const newbackofficeUserSortData = { ...backofficeUsersSortData, sortType };
    setBackofficeUsersSortData(newbackofficeUserSortData);
    sortBackofficeUsers();
  }

  function changeSortKey(sortKey) {
    const newbackofficeUserSortData = { ...backofficeUsersSortData, sortKey };
    setBackofficeUsersSortData(newbackofficeUserSortData);
    sortBackofficeUsers();
  }
  function createNewUser() {
    history.push('/backoffice-users/create-new-user');
  }


  return (
    <>
      <div className="text-fs-head-md mb-4">Backoffice Users</div>
      <Row className="text-fs-body-md mt-4 text-center">
        <Col>
          <Form.Control
            className="text-fs-body-sm"
            type="text"
            placeholder="Search by full name or email..."
            aria-label="text"
            aria-describedby="basic-addon1"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            size={'sm'}
          />
        </Col>
      </Row>
      <Row
        id="backofficeUsers-container"
        className="d-flex align-items-center justify-content-between mt-4"
      >
        <Col>
          <div id="backofficeUsers-sorting-section" className="d-flex align-items-center py-2">
          <Dropdown className="me-2 projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">{sortTypes[backofficeUsersSortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">{sortKeys[backofficeUsersSortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className="text-md-end">
        <ToggleButtonGroup size={'sm'} type="radio" name="options" defaultValue={1}>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === backofficeUsersQuickFilterConstants.ALL 
                ? 'primary' : 'light'}
              className={
                quickFilter !== backofficeUsersQuickFilterConstants.ALL ? 'text-t-body-color' : ''
              }
              id="tbg-radio-1"
              value={backofficeUsersQuickFilterConstants.ALL}
              onClick={() => updateQuickFilter(backofficeUsersQuickFilterConstants.ALL)}
            >
              All
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === backofficeUsersQuickFilterConstants.TRUE ? 
                'primary' : 'light'}
              className={
                quickFilter !== backofficeUsersQuickFilterConstants.TRUE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-2"
              value={backofficeUsersQuickFilterConstants.TRUE}
              onClick={() => updateQuickFilter(backofficeUsersQuickFilterConstants.TRUE)}
            >
              True
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === backofficeUsersQuickFilterConstants.FALSE ? 
                'primary' : 'light'}
              className={
                quickFilter !== backofficeUsersQuickFilterConstants.FALSE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-3"
              value={backofficeUsersQuickFilterConstants.FALSE}
              onClick={() => updateQuickFilter(backofficeUsersQuickFilterConstants.FALSE)}
            >
              False
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === backofficeUsersQuickFilterConstants.WAIT ? 
                'primary' : 'light'}
              className={
                quickFilter !== backofficeUsersQuickFilterConstants.WAIT ? 'text-t-body-color' : ''
              }
              id="tbg-radio-4"
              value={backofficeUsersQuickFilterConstants.WAIT}
              onClick={() => updateQuickFilter(backofficeUsersQuickFilterConstants.WAIT)}
            >
              Wait
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <div id="backofficeUsers-container">
        <MercurXBackofficeUserTable />
      </div>
      <div className="text-end">
      {user.is_superuser && (
        <Button onClick={createNewUser} size={'sm'}>
          Create new user
        </Button>
      )}
    </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    quickFilter: state.backofficeUserReducer.quickFilter,
    backofficeUsersSortData: state.backofficeUserReducer.backofficeUsersSortData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getbackofficeUsersRequest: (payload) => {
      dispatch(getBackofficeUsersActionRequest(payload));
    },
    updateQuickFilter: (payload) => {
      dispatch(updateQuickFilterAction(payload));
    },
    setBackofficeUsersSortData: (payload) => {
      dispatch(setBackofficeUsersSortDataAction(payload));
    },
    sortBackofficeUsers: (payload) => {
      dispatch(sortBackofficeUsersAction(payload));
    },
    filterBackofficeUsers: (payload) => {
      dispatch(filterBackofficeUsersAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeUsers);

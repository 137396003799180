import React, { useEffect, useState } from 'react';
import './Stakes.scss';
import { connect } from 'react-redux';
import {
  getStakesActionRequest,
  filterStakesAction,
  setStakesSortDataAction,
  sortStakesAction,
  updateQuickFilterAction
} from '../../store/stake/stakeActions';
import MercurXStakeTable 
from '../../components/MercurXStakeTable/MercurXStakeTable';
import {  Col, Dropdown, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { stakesQuickFilterConstants, sortKeys, sortTypes } from './stakesConstants';


function Stakes({ ...props }) {
  const {
    getStakesRequest,
    quickFilter,
    stakesSortData,
    updateQuickFilter,
    setStakesSortData,
    sortStakes,
    filterStakes,
  } = props;

  const [filterInput, setFilterInput] = useState('');

  useEffect(() => {
    getStakesRequest();
    updateQuickFilter(stakesQuickFilterConstants.ALL)
  }, []);
  useEffect(() => {
    filterStakes(filterInput);
    sortStakes();
  }, [quickFilter]);

  useEffect(() => {
    filterStakes(filterInput);
  }, [filterInput])

  function changeSortType(sortType) {
    const newStakeSortData = { ...stakesSortData, sortType };
    setStakesSortData(newStakeSortData);
    sortStakes();
  }

  function changeSortKey(sortKey) {
    const newStakeSortData = { ...stakesSortData, sortKey };
    setStakesSortData(newStakeSortData);
    sortStakes();
  }

  return (
    <>
      <div className="text-fs-head-md mb-4">Stakes</div>
      <Row className="text-fs-body-md mt-4 text-center">
        <Col>
          <Form.Control
            className="text-fs-body-sm"
            type="text"
            placeholder="Search by token symbol, address or txn hash..."
            aria-label="text"
            aria-describedby="basic-addon1"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            size={'sm'}
          />
        </Col>
      </Row>
      <Row
        id="stakes-container"
        className="d-flex align-items-center justify-content-between mt-4"
      >
        <Col>
          <div id="stakes-sorting-section" className="d-flex align-items-center py-2">
          <Dropdown className="me-2 projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">{sortTypes[stakesSortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">{sortKeys[stakesSortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className="text-md-end">
        <ToggleButtonGroup size={'sm'} type="radio" name="options" defaultValue={1}>
        <ToggleButton
              size={'sm'}
              variant={quickFilter === stakesQuickFilterConstants.ALL ? 'primary' : 'light'}
              className={
                quickFilter !== stakesQuickFilterConstants.ALL ? 'text-t-body-color' : ''
              }
              id="tbg-radio-1"
              value={stakesQuickFilterConstants.ALL}
              onClick={() => updateQuickFilter(stakesQuickFilterConstants.ALL)}
            >
              All
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === stakesQuickFilterConstants.STAKED ? 'primary' : 'light'}
              className={
                quickFilter !== stakesQuickFilterConstants.STAKED ? 'text-t-body-color' : ''
              }
              id="tbg-radio-2"
              value={stakesQuickFilterConstants.STAKED}
              onClick={() => updateQuickFilter(stakesQuickFilterConstants.STAKED)}
            >
              Staked
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === stakesQuickFilterConstants.DONE ? 'primary' : 'light'}
              className={
                quickFilter !== stakesQuickFilterConstants.DONE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-4"
              value={stakesQuickFilterConstants.DONE}
              onClick={() => updateQuickFilter(stakesQuickFilterConstants.DONE)}
            >
              Done
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <div id="stakes-container">
        <MercurXStakeTable />
      </div>
      
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    quickFilter: state.stakeReducer.quickFilter,
    stakesSortData: state.stakeReducer.stakesSortData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStakesRequest: (payload) => {
      dispatch(getStakesActionRequest(payload));
    },
    updateQuickFilter: (payload) => {
      dispatch(updateQuickFilterAction(payload));
    },
    setStakesSortData: (payload) => {
      dispatch(setStakesSortDataAction(payload));
    },
    sortStakes: (payload) => {
      dispatch(sortStakesAction(payload));
    },
    filterStakes: (payload) => {
      dispatch(filterStakesAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stakes);

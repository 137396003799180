/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'react-bootstrap';
import MercurXFormControl from '../../components/MercurXFormControl/MercurXFormControl';
import { forgotPasswordRequest } from '../../store/user/userActions';
import './ForgotPassoword.scss';
import { NavLink } from 'react-router-dom';

function ForgotPassword({ ...props }) {
  const { forgotpassword } = props;
  const [state, setState] = useState({
    data: {
      email: '',
    },
    errors: {},
  });
  const validate = () => {
    const { data } = state;
    const errors = {};
    if (data.email === '') errors.email = 'Email cannot be blank.';
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { data } = state;
    const errors = validate();

    if (Object.keys(errors).length === 0) {
      forgotpassword(data);

      setState({
        data: {
          email: '',
        },
        errors: {},
      });
    } else {
      setState({
        errors,
      });
    }
  };
  const handleChange = (e) => {
    setState({
      data: {
        ...state.data,
        [e.target.id]: e.target.value,
      },
      errors: {
        ...state.errors,
        [e.target.id]: '',
      },
    });
  };

  const { data, errors } = state;
  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col className="public-resetpw-layout-col d-flex justify-content-center align-items-center bg-white px-1 py-2">
        <div className="public-resetpw-layout-image d-md-flex d-none flex-column justify-content-center align-items-center bg-primary px-1 py-2">
          <div className="text-fs-head-lg text-center text-light px-2">
            Forgot your password to the <span>MercurX Backoffice?</span>
          </div>
          <div className="text-fs-body-md text-center text-light px-2">
            Please enter the email adress you would like to reset information sent to.
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center aling-items-center px-4 mx-2 public-layout-form">
          <Form onSubmit={handleSubmit}>
            <MercurXFormControl
              label="Email"
              type="text"
              value={data?.email}
              handleChange={handleChange}
              error={errors?.email}
            />
            <Form.Label className="text-danger text-fs-body-sm mercurx-error-label">
              {errors?.email && '*' + errors?.email}
            </Form.Label>
            <Button type="submit" className="mb-4 mt-3 bg-mercurx-primary col-sm-12">
              Send Password Reset Email
            </Button>
          </Form>
          <NavLink className="d-flex justify-content-center m-0 text-primary" to="/login">
            Back to Login
          </NavLink>
        </div>
      </Col>
    </Row>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    forgotpassword: (creds) => {
      dispatch(forgotPasswordRequest(creds));
    },
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);

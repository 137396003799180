import { takeEvery, put, all, call } from 'redux-saga/effects';
import * as types from './lockActionTypes';
import * as actions from './lockActions';
import * as endpoints from '../../services/endpoints';


// function* getLockSaga(action) {
//   try {
    
//     const { data } = yield call(endpoints.getLocks);
//     console.log(data)
//     yield put(actions.getLocksActionData(data));
//     // yield put (projectActions.getProjectByIDData(data))
//   } catch (e) {
//     console.log(e)
//     yield put(actions.getLocksActionError(e));
//   }
// }


function* getLockSaga(action) {
  try {
    const { data } = yield call(endpoints.getLocks);
    yield put(actions.getLocksActionData(data));
  } catch (e) {
    console.log(e)
    yield put(actions.getLocksActionError(e));
  }
}

function* watchGetLock() {
  yield takeEvery(types.GET_LOCKS_REQUEST, getLockSaga);
}


export function* lockSaga() {
  yield all([
    watchGetLock(),
   
  ]);
}

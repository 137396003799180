
import { useEffect, useState } from 'react';
import { Badge, Pagination, Table } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import './MercurXBackofficeUserTable.scss';
import { connect } from 'react-redux';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function MercurXBackofficeUserTable(props) {

  const { backofficeUsers, filteredBackofficeUsers } = props;
  const history = useHistory();
  const maxRowCountPerPage = 10;
  const maxShowingPage = 5;
  const [sourceData, setSourceData] = useState(filteredBackofficeUsers ?
    filteredBackofficeUsers : backofficeUsers);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedBackofficeUsers, setRenderedBackofficeUsers] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] =
    useState(maxShowingPage);
  useEffect(() => {
    setSourceData(filteredBackofficeUsers ? filteredBackofficeUsers : backofficeUsers);
  }, [backofficeUsers, filteredBackofficeUsers]);
  useEffect(() => {
    if (sourceData) {
      setCurrentPage(1);
      const tempPages = [];
      for (let index = 0; index < Math.ceil(sourceData.length / maxRowCountPerPage); index++) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [sourceData]);
  useEffect(() => {
    if (sourceData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = sourceData?.slice(firstIndex, lastIndex);
      setRenderedBackofficeUsers([...tempRendered]);
    }
  }, [currentPage, sourceData]);
  function scrollToBackofficeUsersContainer() {
    const backofficeUsersContainer = document.getElementById('backofficeUsers-container');
    if (backofficeUsersContainer) {
      backofficeUsersContainer.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };
  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToBackofficeUsersContainer();
  };
  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToBackofficeUsersContainer();
  };
  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToBackofficeUsersContainer();
    }
  };
  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToBackofficeUsersContainer();
    }
  };
  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToBackofficeUsersContainer();
  };
  function editUser(backofficeUser) {
    history.push({
      pathname: '/backoffice-users/edit-user',
      state: {
        backofficeUser: backofficeUser,
      },
    });
  }
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  return (
    <>
      <Table className="backofficeUsers-table-design" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Super User</th>
            <th>Developer</th>
            <th>2FA Status</th>
            <th>Active</th>
          </tr>
        </thead>
        {renderedBackofficeUsers?.length ? (
          <tbody>
            {renderedBackofficeUsers.map((item, index) => {
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr className="text-t-head-color">
                    <td>{index + 1}</td>
                    <td>{item.full_name}</td>
                    <td>{item.email}</td>
                    <td>
                      {item.is_superuser ? (
                        <Badge bg="success">True</Badge>
                      ) : (
                        <Badge bg="danger">False</Badge>
                      )}
                    </td>
                    <td>
                      {item.is_developer ? (
                        <Badge bg="success">True</Badge>
                      ) : (
                        <Badge bg="danger">False</Badge>
                      )}
                    </td>
                    <td>
                      {item.two_factor_auth ? (
                        <Badge bg="success">True</Badge>
                      ) : (
                        <Badge bg="danger">False</Badge>
                      )}
                    </td>
                    <td>
                      {item.is_active === "True" ? (
                        <Badge bg="success">True</Badge>
                      ) : item.is_active === "False" ? (
                        <Badge bg="danger">False</Badge>
                      ) : (
                        <Badge bg="info">Waiting...</Badge>
                      )}
                    </td>

                    <td>
                      {user.is_superuser && (
                        <div className="cursor-pointer" onClick={() => editUser(item)}>
                          <MercurXIcons
                            name="BsPencilSquare"
                            size={18}
                            color={mainColors['primary']}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        ) : null}
      </Table>
      {!renderedBackofficeUsers?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No backoffice user found according to search results.
        </div>
      )}
      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    backofficeUsers: state.backofficeUserReducer.backofficeUsers,
    filteredBackofficeUsers: state.backofficeUserReducer.filteredBackofficeUsers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXBackofficeUserTable);


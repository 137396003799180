import React, { 
   useEffect,
   useState } from 'react';
import './BackofficeTransactions.scss';
import { connect } from 'react-redux';
import MercurXBackofficeTransactionTable 
from '../../components/MercurXBackofficeTransactionTable/MercurXBackofficeTransactionTable';
import {
  getBackofficeTransactionsActionRequest,
  filterBackofficeTransactionsAction,
  setBackofficeTransactionsSortDataAction,
  sortBackofficeTransactionsAction,
  updateQuickFilterAction
} from '../../store/bo_transaction/bo_transactionActions';
import {  Col, Dropdown, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import 
{ backofficeTransactionsQuickFilterConstants, sortKeys, sortTypes }
 from './backofficeTransactionsConstants';


function BackofficeTransactions({ ...props }) {
  const {
    getBackofficeTransactionsRequest,
    quickFilter,
    backofficeTransactionsSortData,
    updateQuickFilter,
    setBackofficeTransactionsSortData,
    sortBackofficeTransactions,
    filterBackofficeTransactions,
  } = props;

  const [filterInput, setFilterInput] = useState('');

  useEffect(() => {
    getBackofficeTransactionsRequest();
    updateQuickFilter(backofficeTransactionsQuickFilterConstants.ALL)
  }, []);
  useEffect(() => {
    filterBackofficeTransactions(filterInput);
    sortBackofficeTransactions();
  }, [quickFilter]);

  useEffect(() => {
    filterBackofficeTransactions(filterInput);
  }, [filterInput])

  function changeSortType(sortType) {
    const newTransactionSortData = { ...backofficeTransactionsSortData, sortType };
    setBackofficeTransactionsSortData(newTransactionSortData);
    sortBackofficeTransactions();
  }

  function changeSortKey(sortKey) {
    const newTransactionSortData = { ...backofficeTransactionsSortData, sortKey };
    setBackofficeTransactionsSortData(newTransactionSortData);
    sortBackofficeTransactions();
  }

  return (
<>
      <div className="text-fs-head-md mb-4">Backoffice Transactions</div>
      <Row className="text-fs-body-md mt-4 text-center">
        <Col>
          <Form.Control
            className="text-fs-body-sm"
            type="text"
            placeholder="Search by contract name, 
            address or txn hash..."
            aria-label="text"
            aria-describedby="basic-addon1"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            size={'sm'}
          />
        </Col>
      </Row>
      <Row
        id="transactions-container"
        className="d-flex align-items-center justify-content-between mt-4"
      >
        <Col>
          <div id="transactions-sorting-section" className="d-flex align-items-center py-2">
          <Dropdown className="me-2 projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">
                  {sortTypes[backofficeTransactionsSortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">
                  {sortKeys[backofficeTransactionsSortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className="text-md-end">
        <ToggleButtonGroup size={'sm'} type="radio" name="options" defaultValue={1}>
            <ToggleButton
              size={'sm'}
              variant=
              {quickFilter === backofficeTransactionsQuickFilterConstants.ALL ? 'primary' : 'light'}
              className={
                quickFilter !== backofficeTransactionsQuickFilterConstants.ALL ? 'text-t-body-color'
                 : ''
              }
              id="tbg-radio-1"
              value={backofficeTransactionsQuickFilterConstants.ALL}
              onClick={() => updateQuickFilter(backofficeTransactionsQuickFilterConstants.ALL)}
            >
              All
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter ===
                 backofficeTransactionsQuickFilterConstants.TRUE ? 'primary' : 'light'}
              className={
                quickFilter !== 
                backofficeTransactionsQuickFilterConstants.TRUE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-2"
              value={backofficeTransactionsQuickFilterConstants.TRUE}
              onClick={() => updateQuickFilter(backofficeTransactionsQuickFilterConstants.TRUE)}
            >
              Success
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter ===
                 backofficeTransactionsQuickFilterConstants.FALSE ? 'primary' : 'light'}
              className={
                quickFilter !== 
                  backofficeTransactionsQuickFilterConstants.FALSE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-3"
              value={backofficeTransactionsQuickFilterConstants.FALSE}
              onClick={() => updateQuickFilter(backofficeTransactionsQuickFilterConstants.FALSE)}
            >
              Fail
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <div id="transactions-container">
        <MercurXBackofficeTransactionTable />
      </div>
       </>
  );
}
const mapStateToProps = (state) => {
  return {
    quickFilter: state.backofficeTransactionReducer.quickFilter,
    backofficeTransactionsSortData: 
    state.backofficeTransactionReducer.backofficeTransactionsSortData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBackofficeTransactionsRequest: (payload) => {
      dispatch(getBackofficeTransactionsActionRequest(payload));
    },
    updateQuickFilter: (payload) => {
      dispatch(updateQuickFilterAction(payload));
    },
    setBackofficeTransactionsSortData: (payload) => {
      dispatch(setBackofficeTransactionsSortDataAction(payload));
    },
    sortBackofficeTransactions: (payload) => {
      dispatch(sortBackofficeTransactionsAction(payload));
    },
    filterBackofficeTransactions: (payload) => {
      dispatch(filterBackofficeTransactionsAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackofficeTransactions);

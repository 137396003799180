import React, { useEffect, useState } from 'react';
import './Transactions.scss';
import { connect } from 'react-redux';
import {
  getTransactionsActionRequest,
  filterTransactionsAction,
  setTransactionsSortDataAction,
  sortTransactionsAction,
  updateQuickFilterAction
} from '../../store/transaction/transactionActions';
import MercurXTransactionTable 
from '../../components/MercurXTransactionTable/MercurXTransactionTable';
import {  Col, Dropdown, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { transactionsQuickFilterConstants, sortKeys, sortTypes } from './transactionsConstants';


function Transactions({ ...props }) {
  const {
    getTransactionsRequest,
    quickFilter,
    transactionsSortData,
    updateQuickFilter,
    setTransactionsSortData,
    sortTransactions,
    filterTransactions,
  } = props;

  const [filterInput, setFilterInput] = useState('');

  useEffect(() => {
    getTransactionsRequest();
    updateQuickFilter(transactionsQuickFilterConstants.ALL)
  }, []);
  useEffect(() => {
    filterTransactions(filterInput);
    sortTransactions();
  }, [quickFilter]);

  useEffect(() => {
    filterTransactions(filterInput);
  }, [filterInput])

  function changeSortType(sortType) {
    const newTransactionSortData = { ...transactionsSortData, sortType };
    setTransactionsSortData(newTransactionSortData);
    sortTransactions();
  }

  function changeSortKey(sortKey) {
    const newTransactionSortData = { ...transactionsSortData, sortKey };
    setTransactionsSortData(newTransactionSortData);
    sortTransactions();
  }

  return (
    <>
      <div className="text-fs-head-md mb-4">Transactions</div>
      <Row className="text-fs-body-md mt-4 text-center">
        <Col>
          <Form.Control
            className="text-fs-body-sm"
            type="text"
            placeholder="Search by project name, 
            address or txn hash..."
            aria-label="text"
            aria-describedby="basic-addon1"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            size={'sm'}
          />
        </Col>
      </Row>
      <Row
        id="transactions-container"
        className="d-flex align-items-center justify-content-between mt-4"
      >
        <Col>
          <div id="transactions-sorting-section" className="d-flex align-items-center py-2">
          <Dropdown className="me-2 projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">{sortTypes[transactionsSortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">{sortKeys[transactionsSortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className="text-md-end">
        <ToggleButtonGroup size={'sm'} type="radio" name="options" defaultValue={1}>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === transactionsQuickFilterConstants.ALL ? 'primary' : 'light'}
              className={
                quickFilter !== transactionsQuickFilterConstants.ALL ? 'text-t-body-color' : ''
              }
              id="tbg-radio-1"
              value={transactionsQuickFilterConstants.ALL}
              onClick={() => updateQuickFilter(transactionsQuickFilterConstants.ALL)}
            >
              All
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === transactionsQuickFilterConstants.TRUE ? 'primary' : 'light'}
              className={
                quickFilter !== transactionsQuickFilterConstants.TRUE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-2"
              value={transactionsQuickFilterConstants.TRUE}
              onClick={() => updateQuickFilter(transactionsQuickFilterConstants.TRUE)}
            >
              True
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === transactionsQuickFilterConstants.FALSE ? 'primary' : 'light'}
              className={
                quickFilter !== transactionsQuickFilterConstants.FALSE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-3"
              value={transactionsQuickFilterConstants.FALSE}
              onClick={() => updateQuickFilter(transactionsQuickFilterConstants.FALSE)}
            >
              False
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <div id="transactions-container">
        <MercurXTransactionTable />
      </div>
      
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    quickFilter: state.transactionReducer.quickFilter,
    transactionsSortData: state.transactionReducer.transactionsSortData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionsRequest: (payload) => {
      dispatch(getTransactionsActionRequest(payload));
    },
    updateQuickFilter: (payload) => {
      dispatch(updateQuickFilterAction(payload));
    },
    setTransactionsSortData: (payload) => {
      dispatch(setTransactionsSortDataAction(payload));
    },
    sortTransactions: (payload) => {
      dispatch(sortTransactionsAction(payload));
    },
    filterTransactions: (payload) => {
      dispatch(filterTransactionsAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);

export function BackofficeUserDetail(data) {
    this.full_name = '';
    this.email = '';
    this.password = '';
    this.is_active = '';
    this.is_superuser = true;
    this.is_developer = true;
    if (data) {
      this.full_name = data.full_name;
      this.email = data.email;
      this.password = data.password;
      this.is_superuser = data.is_superuser;
      this.is_developer = data.is_developer;
    }
  }
const STAKES_SORT_TYPES = {
  ASCENDING: 0,
  DESCENDING: 1,
};

const STAKES_SORT_KEYS = {
  TOKEN_NAME: 0,
  UPDATE_DATE: 1,
};

const sortTypes = [
  {
    name: 'Ascending',
    icon: 'BiSortUp',
  },
  {
    name: 'Descending',
    icon: 'BiSortDown',
  },
];

const sortKeys = [
  { name: 'Token Symbol', key: 'symbol' },
  { name: 'Start Date', key: 'date' },
];

const stakesQuickFilterConstants = {
  ALL: 1,
  STAKED: 2,
  DONE: 3,
};

export {
  STAKES_SORT_KEYS,
  STAKES_SORT_TYPES,
  sortTypes,
  sortKeys,
  stakesQuickFilterConstants,
};

const LOCKS_SORT_TYPES = {
  ASCENDING: 0,
  DESCENDING: 1,
};

const LOCKS_SORT_KEYS = {
  TOKEN_NAME: 0,
  UPDATE_DATE: 1,
};

const sortTypes = [
  {
    name: 'Ascending',
    icon: 'BiSortUp',
  },
  {
    name: 'Descending',
    icon: 'BiSortDown',
  },
];

const sortKeys = [
  { name: 'Token Symbol', key: 'symbol' },
  { name: 'Update Date', key: 'date' },
];

const locksQuickFilterConstants = {
  ALL: 1,
  LOCKED: 2,
  CLAIM: 3,
  DONE: 4,
};

export {
  LOCKS_SORT_KEYS,
  LOCKS_SORT_TYPES,
  sortTypes,
  sortKeys,
  locksQuickFilterConstants,
};

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_DATA = 'GET_PROJECTS_DATA';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_DATA = 'CREATE_PROJECT_DATA';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_DATA = 'UPDATE_PROJECT_DATA';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_DATA = 'DELETE_PROJECT_DATA';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';

export const UPDATE_QUICK_FILTER = 'UPDATE_QUICK_FILTER';
export const FILTER_PROJECTS = 'FILTER_PROJECTS';
export const SET_PROJECTS_SORT_DATA = 'SET_PROJECTS_SORT_DATA';
export const SORT_PROJECTS = 'SORT_PROJECTS';
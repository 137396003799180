/* eslint-disable max-len */
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import './MercurXContractDetailInputs.scss';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { abiRequestAction } from '../../store/abi/abiActions';
import wallet from '../../helpers/wallet';
import Swal from 'sweetalert2';
import { mainColors } from '../../helpers/colors';
import { getBNB } from '../../store/bnb/bnbActions';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { setAlertAction } from '../../store/alert/alertActions';
import { setLoadingFullAction } from '../../store/loading/loadingActions';
import { Badge } from 'react-bootstrap';
import { transactionRequest } from '../../store/bo_transaction/bo_transactionActions';
const MercurXContractDetailInputs = ({ ...props }) => {
  const [selectedUnit, setSelectedUnit] = useState('WEI');

  const handleUnitChange = (unit) => {
    setSelectedUnit(unit);
  };
  const {
    project,
    abiHistoryRequest,
    abiHistory,
    getBNBRequest,
    bnbData,
    setAlert,
    setLoadingFull,
    transactionRequest,
    isLoading,
    accounts
  } = props;

  const { utils } = require('ethers');
  const [spender, setSpender] = useState('');
  const [spenderNewOwner, setSpenderNewOwner] = useState('');
  const [amount, setAmount] = useState('');
  const [spenderIncrease, setSpenderIncrease] = useState('');
  const [fromTransferFrom, setFromTransferFrom] = useState('');
  const [toTransferFrom, setToTransferFrom] = useState('');
  const [spenderDecrease, setSpenderDecrease] = useState('');
  const [spenderTransfer, setSpenderTransfer] = useState('');
  const [results, setResults] = useState(null);
  const resultList = {};
  const [resultsLists, setResultsLists] = useState({});
  const [divtransactionHash, setDivTransactionHash] = useState("");
  const [amountBeforeDecimal, setAmountBeforeDecimal] = useState('1');
  const [amountBeforeDecimalIncrease, setAmountBeforeDecimalIncrease] = useState('1');
  const [amountBeforeDecimalDecrease, setAmountBeforeDecimalDecrease] = useState('1');
  const [amountBeforeDecimalTransferFrom, setAmountBeforeDecimalTransferFrom] = useState('1');
  const [amountAfterDecimalTransferFrom, setAmountAfterDecimalTransferFrom] = useState('000000000');
  const [amountAfterDecimal, setAmountAfterDecimal] = useState('000000000');
  const [amountAfterDecimalIncrease, setAmountAfterDecimalIncrease] = useState('000000000');
  const [amountAfterDecimalDecrease, setAmountAfterDecimalDecrease] = useState('000000000');
  const [amountBeforeDecimalTransfer, setAmountBeforeDecimalTransfer] = useState('1')
  const [amountAfterDecimalTransfer, setAmountAfterDecimalTransfer] = useState('000000000')
  const [showBalanceOf, setShowBalanceOf] = useState(false);
  const handleBalanceOfClick = () => {
    setShowBalanceOf(!showBalanceOf);
  };
  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setAlert({
          title: 'Success!',
          text: 'Text copied to clipboard',
          variant: 'success',
          outTimeMS: 2000,
        });
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Unable to copy text to clipboard', err);
      });
  };
  const [balanceOf, setBalanceOf] = useState('');
  function handleBalanceOfInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'balanceOf') {
      console.log(`balanceOf: ${inputValue}`);
      setBalanceOf(inputValue);
    }
  }
  const handleAbi = () => {
    abiHistoryRequest();
    getBNBRequest();
  };
  const [txs, setTxs] = useState([]);
  useEffect(() => {
    handleAbi();
    console.log('divtransactionHash')
    console.log(divtransactionHash)
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      console.log(resultsLists)
    };

    fetchData();
  }, []);  // useEffect'in sadece ilk render'da çalışması için dependency array'i boş bırakıldı.

  console.log(resultsLists);

  const [invalidAddress, setInvalidAddress] = useState(false);
  const [invalidAddressIncrease, setInvalidAddressIncrease] = useState(false);
  const [invalidAddressDecrease, setInvalidAddressDecrease] = useState(false);
  const [invalidAddressTransfer, setInvalidAddressTransfer] = useState(false);
  const [invalidAmountDecrease, setInvalidAmountDecrease] = useState(false);
  const [invalidAddressFromTransfer, setInvalidAddressFromTransfer] = useState(false);
  const [invalidAddressToTransfer, setInvalidAddressToTransfer] = useState(false);
  const [invalidAddressNewOwner, setInvalidAddressNewOwner] = useState(false);
  const [invalidAmount, setInvalidAmount] = useState(false);
  const [invalidAmountIncrease, setInvalidAmountIncrease] = useState(false);
  const [invalidAmountTransferFrom, setInvalidAmountTransferFrom] = useState(false);
  const [invalidAmountTransfer, setInvalidAmountTransfer] = useState(false);
  const [showAllowance, setShowAllowance] = useState(false);
  const [spenderAllowance, setSpenderAllowance] = useState('');
  const [ownerAllowance, setOwnerAllowance] = useState('');
  const handleSpenderChange = (event) => {
    const inputValue = event.target.value;
    setSpender(inputValue);
    setInvalidAddress(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };
  const handleAllowanceClick = () => {
    setShowAllowance(!showAllowance);
  };
  function handleAllowanceInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'spenderAllowance') {
      console.log(`spenderAllowance: ${inputValue}`);
      setSpenderAllowance(inputValue);
    } else if (inputType === 'ownerAllowance') {
      console.log(`ownerAllowance: ${inputValue}`);
      setOwnerAllowance(inputValue);
    }
  }
  const handleAmountBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimal(inputValue);
      setInvalidAmount(false);
    } else {
      setInvalidAmount(true);
    }
  };
  const handleAmountIncreaseBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalIncrease(inputValue);
      setInvalidAmountIncrease(false);
    } else {
      setInvalidAmountIncrease(true);
    }
  };
  const handleAmountTransferFromBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalTransferFrom(inputValue);
      setInvalidAmountTransferFrom(false);
    } else {
      setInvalidAmountTransferFrom(true);
    }
  };
  const handleAmountTransferBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalTransfer(inputValue);
      setInvalidAmountTransfer(false);
    } else {
      setInvalidAmountTransfer(true);
    }
  };
  const handleAmountTransferFromAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 9)) {
      setAmountAfterDecimalTransferFrom(inputValue);
    }
  };
  const handleAmountTransferAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 9)) {
      setAmountAfterDecimalTransfer(inputValue);
    }
  };

  const handleAmountAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 9)) {
      setAmountAfterDecimal(inputValue);
    }
  };
  const handleAmountIncreaseAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 9)) {
      setAmountAfterDecimalIncrease(inputValue);
    }
  };
  const handleAmountDecraseAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 9)) {
      setAmountAfterDecimalDecrease(inputValue);
    }
  };
  const handleIncreaseInputChange = (event) => {
    const inputValue = event.target.value;
    setSpenderIncrease(inputValue);
    setInvalidAddressIncrease(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };
  const handleTransferFromInputChange = (event) => {
    const inputValue = event.target.value;
    setFromTransferFrom(inputValue);
    setInvalidAddressFromTransfer(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };
  const handleTransferToInputChange = (event) => {
    const inputValue = event.target.value;
    setToTransferFrom(inputValue);
    setInvalidAddressToTransfer(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };
  const handleNewOwnerInputChange = (event) => {
    const inputValue = event.target.value;
    setSpenderNewOwner(inputValue);
    setInvalidAddressNewOwner(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };

  const handleDecreaseInputChange = (event) => {
    const inputValue = event.target.value;
    setSpenderDecrease(inputValue);
    setInvalidAddressDecrease(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };
  const handleAmountDecraeseBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalDecrease(inputValue);
      setInvalidAmountDecrease(false);
    } else {
      setInvalidAmountDecrease(true);
    }
  };
  const handleTransferInputChange = (event) => {
    const inputValue = event.target.value;
    setSpenderTransfer(inputValue);
    setInvalidAddressTransfer(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  }
  const closeModal = () => {
    abiHistoryRequest(false);
  };

  let val = 0.0;
  const runContract = async (functionType, isCall = false, params = '', vals = '') => {
    console.log(functionType);
    let transaction;
    let transactionHashContract;
    setTxs([]);
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const project_id = 1;
      const project_name = "MERCURX";
      const user_public_address = signerAddress;
      const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
      const transaction_time = new Date();
      const web3 = new Web3(window.ethereum);
      await wallet.controlAndSwitchOrAddNetwork();
      await window.ethereum.enable();
      const mercurx_contract = new web3.eth.Contract(
        abiHistory?.[0]?.['MERX_abi'],
        '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19',
      );
      const contractMethod = mercurx_contract.methods[functionType];
      const data = contractMethod(...params).encodeABI();
      if (isCall) {
        if (params == '' && vals == '') {
          const transaction = await mercurx_contract.methods[functionType]().call();
          setResults(transaction)
          resultList[functionType] = transaction;
          setResultsLists(prevState => {
            return {
              ...prevState,
              [functionType]: transaction,
            };
          });
          setLoadingFull({ isLoading: false });
        }
        else if (params != '' && vals == '') {
          const transaction = await contractMethod(...params).call();
          setResults(transaction);
          resultList[functionType] = transaction;
          setResultsLists((prevState) => {
            return {
              ...prevState,
              [functionType]: transaction,
            };
          });
          transactionHashContract = transaction["transactionHash"];
          setLoadingFull({ isLoading: false });
        }

        else {
          const transaction = await mercurx_contract.methods[functionType].send({
            from: signerAddress,
            to: '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19',
            data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
          })
          transactionHashContract = transaction["transactionHash"];
          setDivTransactionHash(transaction);
          const transaction_status = transaction.status;
          const transaction_hash = transaction.transactionHash;
          // const token_count = vals * (10 ** project?.token.decimal);
          const token_count = vals;
          const action = functionType;
          const payload2 = {
            action,
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,
          };
          console.log('1' + payload2)
          transactionRequest(payload2);
        }
        console.log(transaction)
      }
      else if (params == "" && vals != "") {
        val = parseFloat(bnbData?.price) * parseFloat(vals) / parseFloat("3")
        val = val.toFixed(9).toString();
        val = parseFloat(val.replace('.', ''), 10);
        let bnb = parseFloat(vals).toFixed(18);
        bnb = parseFloat(bnb.toString().replace('.', ''), 10);
        const transaction = await web3.eth.sendTransaction({
          from: signerAddress,
          to: '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19',
          data: data,
          value: bnb,
        });
        transactionHashContract = transaction["transactionHash"];
        setDivTransactionHash(transaction);
        console.log(transaction["transactionHash"]);
        const transaction_status = transaction.status;
        const transaction_hash = transaction.transactionHash;
        // const token_count = params[params.length - 1] * (10 ** project?.token.decimal);
        const token_count = params[params.length - 1];
        const action = functionType;
        const payload2 = {
          action,
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
        };
        console.log('2' + payload2)
        transactionRequest(payload2);
      }
      else {
        const transaction = await web3.eth.sendTransaction({
          from: signerAddress,
          to: '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19',
          data: data,
        });
        transactionHashContract = transaction["transactionHash"];
        setDivTransactionHash(transaction);
        console.log(transaction["transactionHash"]);
        const transaction_status = transaction.status;
        const transaction_hash = transaction.transactionHash;
        console.log('params[params.length - 1] 222')
        console.log(params[params.length - 1])
        const token_count = params[params.length - 1];
        const action = functionType;
        const payload2 = {
          action,
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
        };
        console.log(payload2)
        transactionRequest(payload2);
      }
      if (!isCall) {
        Swal.fire({
          icon: 'success',
          iconColor: mainColors.primary,
          text: 'Transaction succeed',
          confirmButtonColor: mainColors.primary,
          html: `<a href=https://testnet.bscscan.com/tx/${transactionHashContract}
     target='_blank'> Check Detail Transaction !</a>`,
        }).then(closeModal);
        setLoadingFull({ isLoading: false });
      }
    }
    catch (err) {
      const receiptObject = err['receipt'];
      setDivTransactionHash(receiptObject);
      if (err?.receipt?.transactionHashContract) {
        Swal.fire({
          icon: 'error',
          iconColor: '#E40039',
          title: 'Transaction is Failed',
          confirmButtonColor: '#E40039',
          html: `<a href=https://testnet.bscscan.com/tx/${transactionHashContract}
     target='_blank'> Check Detail Transaction !</a>`,
        }).then(closeModal);
        setLoadingFull({ isLoading: false });
      } else {
        if (err.message === "Internal JSON-RPC error.") {
          Swal.fire({
            icon: 'warning',
            iconColor: '#E40039',
            confirmButtonColor: '#E40039',
            text: 'Please check your wallet!',
          }).then(closeModal);
        } else {
          Swal.fire({
            icon: 'warning',
            iconColor: '#E40039',
            confirmButtonColor: '#E40039',
            text: err.message,
          }).then(closeModal);
        }
        setLoadingFull({ isLoading: false });
      }


      const transaction_status = false;
      const action = functionType;
      const project_id = 1;
      const project_name = 'MERCURX'
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const user_public_address = signerAddress;
      const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
      const transaction_time = new Date();
      const transaction_hash = receiptObject?.transactionHash || '';
      const token_count = (params[params.length - 1] === signerAddress) ? 0 : (params[params.length - 1] ? params[params.length - 1] : parseInt(vals[0], 10));
      const payload2 = {
        action,
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
      };
      transactionRequest(payload2);
    }
  };
  return (
    <div>
      <div className="contract-detail">
        <Accordion className="mt-5 shadow" defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="text-fs-head-sm">Contract</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="text-fs-head-sm">Deployed Contract Write</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col md="12">
                          {/* approve */}
                          <Form.Group className="mt-1">
                            <div className="text-fs-head-sm">approve</div>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>spender:</Form.Label>
                            <Form.Control
                              id="spender"
                              type="text"
                              placeholder="Address"
                              onChange={handleSpenderChange}
                              isInvalid={invalidAddress}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>Amount:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimal"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimal}
                                onChange={handleAmountBeforeDecimalChange}
                                isInvalid={invalidAmount}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimal"
                                type="text"
                                value={amountAfterDecimal}
                                onChange={handleAmountAfterDecimalChange}
                                isInvalid={invalidAmount}
                                style={{ maxWidth: '120px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value 1
                              </Form.Control.Feedback>
                            </div>

                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimal}${amountAfterDecimal}`;
                              const paddedAmount = fullAmount.padEnd(10, '0');
                              runContract("approve", false, [spender, paddedAmount]);
                            }}
                            disabled={!accounts?.[0] || invalidAddress || invalidAmount || amountBeforeDecimal === ''}
                          >
                            transact
                          </Button>

                          {/* transferFrom */}
                          <Form.Group className="mt-4">
                            <div className="text-fs-head-sm">transferFrom</div>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>from:</Form.Label>
                            <Form.Control
                              id="spender-transfer-from"
                              value={fromTransferFrom}
                              type="text"
                              placeholder="Address"
                              onChange={handleTransferFromInputChange}
                              isInvalid={invalidAddressFromTransfer}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>to:</Form.Label>
                            <Form.Control
                              id="spender-transfer-to"
                              value={toTransferFrom}
                              type="text"
                              placeholder="Address"
                              onChange={handleTransferToInputChange}
                              isInvalid={invalidAddressToTransfer}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>addedValue:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimal"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalTransferFrom}
                                onChange={handleAmountTransferFromBeforeDecimalChange}
                                isInvalid={invalidAmountTransferFrom}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimal"
                                type="text"
                                value={amountAfterDecimalTransferFrom}
                                onChange={handleAmountTransferFromAfterDecimalChange}
                                isInvalid={invalidAmountTransferFrom}
                                style={{ maxWidth: '120px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value 1
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalTransferFrom}${amountAfterDecimalTransferFrom}`;
                              const paddedAmount = fullAmount.padEnd(5, '0');
                              runContract("transferFrom", false, [fromTransferFrom, toTransferFrom, paddedAmount])
                            }
                            }
                            // onClick={() => runContract("transferFrom", false, [fromTransferFrom, toTransferFrom, `${amountBeforeDecimalTransferFrom}${amountAfterDecimalTransferFrom}`])}
                            disabled={!accounts?.[0] || invalidAddressFromTransfer || invalidAddressToTransfer || invalidAmountTransferFrom || amountBeforeDecimalTransferFrom === ''}
                          >
                            transact
                          </Button>
                          

                          {/* transfer */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">transfer</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>to:</Form.Label>
                            <Form.Control
                              id="spender"
                              value={spenderTransfer}
                              type="text"
                              placeholder="Address"
                              onChange={handleTransferInputChange}
                              isInvalid={invalidAddressTransfer}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>addedValue:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimal"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalTransfer}
                                onChange={handleAmountTransferBeforeDecimalChange}
                                isInvalid={invalidAmountTransfer}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimal"
                                type="text"
                                value={amountAfterDecimalTransfer}
                                onChange={handleAmountTransferAfterDecimalChange}
                                isInvalid={invalidAmountTransfer}
                                style={{ maxWidth: '120px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value 1
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalTransfer}${amountAfterDecimalTransfer}`;
                              const paddedAmount = fullAmount.padEnd(5, '0');
                              runContract("transfer", false, [spenderTransfer, paddedAmount])
                            }
                            }
                            // onClick={() => runContract("transfer", false, [spenderTransfer, `${amountBeforeDecimalTransfer}${amountAfterDecimalTransfer}`])}
                            disabled={!accounts?.[0] || invalidAddressDecrease || invalidAmountTransfer || amountBeforeDecimalTransfer === ''}
                          >
                            transact
                          </Button>

                          {/* transferOwnership */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">transferOwnership</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>newOwner:</Form.Label>
                            <Form.Control
                              id="spenderNewOwner"
                              value={spenderNewOwner}
                              type="text"
                              placeholder="Address"
                              onChange={handleNewOwnerInputChange}
                              isInvalid={invalidAddressNewOwner}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => runContract("transferOwnership", false, [spenderNewOwner])}
                            disabled={!accounts?.[0] || invalidAddressNewOwner}
                          >
                            transact
                          </Button>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion className="mt-3 shadow" >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="text-fs-head-sm">Deployed Contract Read</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <Button
                            onClick={handleAllowanceClick}
                            className="me-2 button-size"
                          >
                            allowance
                          </Button>

                          {showAllowance && (
                            <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Form.Group>
                                <Form.Control
                                  id="ownerAllowance"
                                  type="text"
                                  placeholder="Owner Address"
                                  onChange={(e) =>
                                    handleAllowanceInputChange(e, 'ownerAllowance')
                                  }
                                />
                              </Form.Group>

                              <Form.Group className='m-2'>
                                <Form.Control
                                  id="spenderAllowance"
                                  type="text"
                                  placeholder="Spender Address"
                                  onChange={(e) =>
                                    handleAllowanceInputChange(e, 'spenderAllowance')
                                  }
                                />
                              </Form.Group>

                              <Button
                                className="custom-button-call m-2"
                                onClick={() => runContract("allowance", true, [ownerAllowance, spenderAllowance])}
                              >
                                call
                              </Button>

                            </div>
                          )}

                          {showAllowance && resultsLists['allowance'] && (
                            <div className="mt-2">
                              <div className="ml-0">{`uint256: ${resultsLists['allowance']}`}</div>
                            </div>
                          )}
                        </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-balanceOf">balanceOf</Tooltip>}
                          >
                            <Button
                              onClick={handleBalanceOfClick}
                              className="me-2 button-size"
                            >
                              balanceOf
                            </Button>
                          </OverlayTrigger>
                          {showBalanceOf && (
                            <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Form.Group>
                                <Form.Control
                                  id="balanceOf"
                                  type="text"
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleBalanceOfInputChange(e, 'balanceOf')
                                  }
                                />
                              </Form.Group>

                              <Button
                                className="custom-button-call m-2"
                                onClick={() => runContract("balanceOf", true, [balanceOf])}
                              >
                                call
                              </Button>

                              {resultsLists['balanceOf'] && (
                                <div className="ml-2">{`uint256: ${resultsLists['balanceOf']}`}</div>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button onClick={() => runContract("decimals", true)} className="me-2 button-size">
                            decimals
                          </Button>
                          {resultsLists["decimals"] && <div className="ml-2">{`uint8: ${resultsLists["decimals"]}`}</div>}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button onClick={() => runContract("name", true)} className="me-2 button-size">
                            name
                          </Button>
                          {resultsLists["name"] && <div className="ml-2">{`name: ${resultsLists["name"]}`}</div>}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button onClick={() => runContract("owner", true)} className="me-2 button-size">
                            owner
                          </Button>
                          {resultsLists["owner"] && (
                            <div className="ml-2 d-flex align-items-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-owner`}>{resultsLists["owner"]}</Tooltip>}
                              >
                                <div>
                                  {`owner: ${resultsLists["owner"].substring(0, 3)}...${resultsLists["owner"].slice(-3)}`}
                                </div>
                              </OverlayTrigger>
                              <div className="ms-2">
                                <Button variant="outline-secondary" size="sm" onClick={() => handleCopyToClipboard(resultsLists["owner"])}
                                  style={{ background: '#f0f0f0' }}>
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </div>
                          )} </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button onClick={() => runContract("symbol", true)} className="me-2 button-size">
                            symbol
                          </Button>
                          {resultsLists["symbol"] && <div className="ml-2">{`symbol: ${resultsLists["symbol"]}`}</div>}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button onClick={() => runContract("totalSupply", true)} className="me-2 button-size">
                            totalSupply
                          </Button>
                          {resultsLists["totalSupply"] && <div className="ml-2">{`totalSupply: ${resultsLists["totalSupply"]}`}</div>}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
                <Col md="6">
                  {/* İkinci Accordion */}
                  <Accordion className="shadow">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="text-fs-head-sm">Transaction Details</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {divtransactionHash && (
                          <div className="transaction-details" style={{ overflowWrap: 'break-word' }}>
                            <Form.Group>
                              <Form.Label>
                                Transaction Status: {divtransactionHash.status ? (
                                  <Badge bg="success">Success</Badge>
                                ) : (
                                  <Badge bg="danger">Fail</Badge>
                                )}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group >
                              <Form.Label className='mt-0'>
                                Transaction Hash:
                              </Form.Label>
                              <div >
                                {divtransactionHash.transactionHash}
                              </div>
                              <div className="ms-0">
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  onClick={() => handleCopyToClipboard(divtransactionHash.transactionHash)}
                                  style={{ background: '#f0f0f0' }}
                                >
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Block Hash:
                              </Form.Label>
                              <div>
                                {divtransactionHash.blockHash}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Cumulative Gas Used:
                              </Form.Label>
                              <div>
                                {divtransactionHash.cumulativeGasUsed}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Effective Gas Price:
                              </Form.Label>
                              <div>
                                {divtransactionHash.effectiveGasPrice}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                from:
                              </Form.Label>
                              <div>
                                {divtransactionHash.from}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                to:
                              </Form.Label>
                              <div>
                                {divtransactionHash.to}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Gas Used:
                              </Form.Label>
                              <div>
                                {divtransactionHash.gasUsed}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Transaction Index:
                              </Form.Label>
                              <div>
                                {divtransactionHash.transactionIndex}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Type:
                              </Form.Label>
                              <div>
                                {divtransactionHash.type}
                              </div>
                            </Form.Group>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    abiHistory: state.abiReducer.abiHistory,
    bnbData: state.bnbReducer.bnb,
    isLoading: state.loadingReducer.isLoading,
    project: state.projectReducer.project,
    accounts: state.walletReducer.accounts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    getBNBRequest: (payload) => {
      dispatch(getBNB(payload));
    },
    setAlert: (payload) => {
      dispatch(setAlertAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurXContractDetailInputs);


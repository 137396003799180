import { takeEvery, put, all, call } from 'redux-saga/effects';
import * as types from './transactionActionTypes';
import * as actions from './transactionActions';
import * as endpoints from '../../services/endpoints';


function* getTransactionSaga(action) {
  try {
    
    const { data } = yield call(endpoints.getTransactions);
    console.log(data)
    yield put(actions.getTransactionsActionData(data));
    // yield put (projectActions.getProjectByIDData(data))
  } catch (e) {
    yield put(actions.getTransactionsActionError(e));
  }
}

function* watchGetTransaction() {
  yield takeEvery(types.GET_TRANSACTIONS_REQUEST, getTransactionSaga);
}


export function* transactionSaga() {
  yield all([
    watchGetTransaction(),
   
  ]);
}

import * as types from './userActionTypes';

const initialState = {
  user: null,
  forgotPasswordData: null,
  resetPasswordData: null,
  checkUserToken: null,
  accountVerifiedData: null,
  resendVerificationEmailData: null,
  enable2faData: [],
  generateQrData: null,
  verify2faData: null,
  create2faData: null,
  error: {
    type: null,
    data: null,
  },
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_DATA:
      const user = action.payload;
      return {
        ...state,
        user: user ? Object.assign({}, user) : null,
        // user: user ? { ...user, username: user.username } : null,
        // user: user ? { ...user } : null,
        // user: action.user ? Object.assign({}, action.user) : null,

      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        error: {
          type: types.LOGIN_ERROR,
          data: action.payload,
        },
      };
      case types.LOGOUT_DATA:
        return {
          ...state,
          user: null,
        };
      case types.LOGOUT_ERROR:
        return {
          ...state,
          error: {
            type: types.LOGOUT_ERROR,
            data: action.payload,
          },
        };
        case types.FORGOT_PASSWORD_DATA:
      return {
        ...state,
        forgotPasswordData: action.payload ? Object.assign({}, action.payload) : null,
      };
    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        error: {
          type: types.FORGOT_PASSWORD_ERROR,
          data: action.payload,
        },
      };
    case types.RESET_PASSWORD_DATA:
      return {
        ...state,
        resetPasswordData: action.payload ? Object.assign({}, action.payload) : null,
      };
    case types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        error: {
          type: types.RESET_PASSWORD_ERROR,
          data: action.payload,
        },
      };
        case types.ACTIVATION_DATA:
      return {
        ...state,
        activationData: action.payload ? Object.assign({}, action.payload) : null,
      };
    case types.ACTIVATION_ERROR:
      return {
        ...state,
        error: {
          type: types.ACTIVATION_ERROR,
          data: action.payload,
        },
      };
      case types.ACCOUNT_VERIFIED:
      return {
        ...state,
        accountVerifiedData: action?.payload,
      };
    case types.RESEND_VERIFICATION_EMAIL_DATA:
      return {
        ...state,
        resendVerificationEmailData: action.payload ? Object.assign({}, action.payload) : null,
      };
    case types.RESEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        error: {
          type: types.RESEND_VERIFICATION_EMAIL_ERROR,
          data: action.payload,
        },
      };
      case types.CHECK_USER_TOKEN_DATA:
      return {
        ...state,
        checkUserToken: action.payload,
      };
    case types.CHECK_USER_TOKEN_ERROR:
      return {
        ...state,
        error: {
          type: types.CHECK_USER_TOKEN_ERROR,
          data: action.payload,
        },
      };
  //   case types.ENABLE_2FA_DATA:
  // return {
  //   ...state,
  //   // enable2faData: action.payload ? Object.assign({}, action.payload) : null,
  //   enable2faData: action.data,
  //   two_factor_auth: action.payload.is_enabled,
  // };
  case types.ENABLE_2FA_DATA:
  return {
    ...state,
    enable2faData: action.payload,
    two_factor_auth: action.payload.is_enabled,
  };

case types.GENERATE_QR_DATA:
  return {
    ...state,
    generateQrData: action.payload ? Object.assign({}, action.payload) : null,
  };
  case types.CREATE_2FA_DATA:
    return {
      ...state,
      create2faData: action.payload ? Object.assign({}, action.payload) : null,
    };
  case types.VERIFY_2FA_DATA:
    return {
      ...state,
      verify2faData: action.payload ? Object.assign({}, action.payload) : null,
    };
case types.ENABLE_2FA_ERROR:
  return {
    ...state,
    error: {
      type: types.ENABLE_2FA_ERROR,
      data: action.payload,
    },
  };

case types.GENERATE_QR_ERROR:
  return {
    ...state,
    error: {
      type: types.GENERATE_QR_ERROR,
      data: action.payload,
    },
  };
  case types.CREATE_2FA_ERROR:
    return {
      ...state,
      error: {
        type: types.VERIFY_2FA_ERROR,
        data: action.payload,
      },
    };
  case types.VERIFY_2FA_ERROR:
  return {
    ...state,
    error: {
      type: types.VERIFY_2FA_ERROR,
      data: action.payload,
    },
  };
  case 'VERIFY_2FA_SUCCESS':
  return {
    ...state,
    verify2faData: action.payload,
    verify2faError: null,
  };
  // case types.UPDATE_USER_TWO_FACTOR_AUTH:
  // return {
  //   ...state,
  //   user: state.user ? { ...state.user, two_factor_auth: action.payload.is_enabled } : null,
  // };
    default:
      return state;
  }
}
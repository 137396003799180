import { connect } from 'react-redux';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import './MercurXSidebarMenu.scss';
import { Link } from 'react-router-dom';
import backOfficeMiniLogo from '../../assets/img/logo/mercurx-logo-mini.png';
import mercurxLogo from '../../assets/img/logo/mercurx-logo.png';
import { useState } from 'react';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function MercurXSidebarMenu({ ...props }) {
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const { pathname: activePath } = useLocation();
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  function controlPath(cPath) {
    if (activePath.startsWith(cPath)) {
      return true;
    }
    return false;
  }

  return (
    <Sidebar collapsed={collapsedMenu} className="bg-gray-300" backgroundColor="white">
      <div className="d-flex align-items-center justify-content-between py-3 px-2">
        <img
          src={collapsedMenu ? backOfficeMiniLogo : mercurxLogo}
          alt="mercurx-backoffice-logo"
          height={30}
        />
        <span className="cursor-pointer" onClick={() => setCollapsedMenu(!collapsedMenu)}>
          <MercurXIcons name={collapsedMenu ? 'BsChevronRight' : 'BsChevronLeft'} size={20} />
        </span>
      </div>
      <Menu>
        <MenuItem
          icon={<MercurXIcons name="BsHouse" size={20} />}
          className={`${activePath === '/' ? 'active-menu-item' : ''}`}
          component={<Link to="/" />}
        >
          Home
        </MenuItem>
        <SubMenu
          label="Projects"
          icon={<MercurXIcons name="BsFolder" size={20} />}
          className={`${controlPath('/projects') || controlPath('/tokens') ? 'active-submenu' : ''
            }`}
          defaultOpen={controlPath('/projects') || controlPath('/tokens')}
        >
          <MenuItem
            icon={<MercurXIcons name="BsLayoutTextWindowReverse" size={20} />}
            className={`${controlPath('/projects') ? 'active-menu-item' : ''}`}
            component={<Link to="/projects" />}
          >
            Project List
          </MenuItem>
          <MenuItem
            icon={<MercurXIcons name="BsCoin" size={20} />}
            className={`${controlPath('/tokens') ? 'active-menu-item' : ''}`}
            component={<Link to="/tokens" />}
          >
            Token List
          </MenuItem>
        </SubMenu>
        <SubMenu
          label="Transactions"
          icon={<MercurXIcons name="BsActivity" size={20} />}
          className={`${activePath === '/transactions' ||
            activePath === '/stakes' ? 'active-submenu' : ''
              || activePath === '/backoffice-transactions' ? 'active-submenu' : ''
            }`}
          defaultOpen={activePath === '/transactions' || activePath === '/stakes'
            || activePath === '/backoffice-transactions'}
        >
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-txn">Transaction List</Tooltip>}
          >
            <MenuItem
              icon={<MercurXIcons name="BsCpu" size={20} />}
              className={`${activePath === '/transactions' ? 'active-menu-item' : ''}`}
              component={<Link to="/transactions" />}
            >
              Txn List
            </MenuItem>
          </OverlayTrigger>
          {/* <MenuItem
            icon={<MercurXIcons name="BsFileLock" size={20} />}
            className={`${activePath === '/locks' ? 'active-menu-item' : ''}`}
            component={<Link to="/locks" />}
          >
            Lock List
          </MenuItem> */}
          <MenuItem
            icon={<MercurXIcons name="BsFileLock" size={20} />}
            className={`${activePath === '/stakes' ? 'active-menu-item' : ''}`}
            component={<Link to="/stakes" />}
          >
            Stake List
          </MenuItem>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-boTxn">Backoffice Transaction List</Tooltip>}
          >
            <MenuItem
              icon={<MercurXIcons name="BsListTask" size={20} />}
              className={`${activePath === '/backoffice-transactions' ? 'active-menu-item' : ''}`}
              component={<Link to="/backoffice-transactions" />}
            >
              Bo Txn List
            </MenuItem>
          </OverlayTrigger>
        </SubMenu>
        <SubMenu
          label="Users"
          icon={<MercurXIcons name="BsPerson" size={20} />}
          className={`${activePath === '/launchpad-users' || activePath === '/backoffice-users'
            ? 'active-submenu'
            : ''
            }`}
          defaultOpen={activePath === '/launchpad-users' || activePath === '/backoffice-users'}
        >
          <MenuItem
            icon={<MercurXIcons name="BsPersonGear" size={20} />}
            className={`${activePath === '/launchpad-users' ? 'active-menu-item' : ''}`}
            component={<Link to="/launchpad-users" />}
          >
            Launchpad Users
          </MenuItem>
          <MenuItem
            icon={<MercurXIcons name="BsPersonExclamation" size={20} />}
            className={`${activePath === '/backoffice-users' ? 'active-menu-item' : ''}`}
            component={<Link to="/backoffice-users" />}
          >
            Backoffice Users
          </MenuItem>
        </SubMenu>
        {user.is_superuser && (
          <SubMenu
            label="Reports"
            icon={<MercurXIcons name="BsFileText" size={20} />}
            className={`
    ${activePath === '/transaction-report' ||
                activePath === '/lock-report' ||
                activePath === '/user-report'
                ? 'active-submenu'
                : ''
              } disable-click
  `}
            defaultOpen={
              activePath === '/transaction-report' ||
              activePath === '/lock-report' ||
              activePath === '/user-report'
            }
          >
            <MenuItem
              icon={<MercurXIcons name="BsFileEarmarkSpreadsheet" size={20} />}
              className={`${activePath === '/transaction-report' ? 'active-menu-item' : ''}`}
              component={<Link to="/transaction-report" />}
            >
              Transaction Report
            </MenuItem>
            <MenuItem
              icon={<MercurXIcons name="BsFileEarmarkLock2" size={20} />}
              className={`${activePath === '/lock-report' ? 'active-menu-item' : ''}`}
              component={<Link to="/lock-report" />}
            >
              Lock Report
            </MenuItem>
            <MenuItem
              icon={<MercurXIcons name="BsFileEarmarkPerson" size={20} />}
              className={`${activePath === '/user-report' ? 'active-menu-item' : ''}`}
              component={<Link to="/user-report" />}
            >
              User Report
            </MenuItem>
          </SubMenu>
        )}
        {user.is_superuser && (
          <SubMenu
            label="Contracts"
            icon={<MercurXIcons name="BsFileCode" size={20} />}
            className={`${activePath === '/mercurx-contract' ||
              activePath === '/mercurx-presale-contract' ||
              activePath === '/lock-contract' ||
              activePath === '/mercurx-stake-contract'
              ? 'active-submenu'
              : ''
              }`}
            defaultOpen={
              activePath === '/mercurx-contract' ||
              activePath === '/mercurx-presale-contract' ||
              activePath === '/lock-contract' ||
              activePath === '/mercurx-stake-contract'
            }
          >
            <MenuItem
              icon={<MercurXIcons name="BsCodeSlash" size={20} />}
              className={`${activePath === '/mercurx-contract' ? 'active-menu-item' : ''}`}
              component={<Link to="/mercurx-contract" />}
            >
              Mercurx
            </MenuItem>
            <MenuItem
              icon={<MercurXIcons name="BsCode" size={20} />}
              className={`${activePath === '/mercurx-presale-contract' ? 'active-menu-item' : ''}`}
              component={<Link to="/mercurx-presale-contract" />}
            >
              Mercurx Presale
            </MenuItem>
            {/* <MenuItem
            icon={<MercurXIcons name="BsFileEarmarkLock2" size={20} />}
            className={`${activePath === '/lock-contract' ? 'active-menu-item' : ''}`}
            component={<Link to="/lock-contract" />}
          >
            Lock
          </MenuItem> */}
            <MenuItem
              icon={<MercurXIcons name="BsFileEarmarkLock2" size={20} />}
              className={`${activePath === '/mercurx-stake-contract' ? 'active-menu-item' : ''}`}
              component={<Link to="/mercurx-stake-contract" />}
            >
              Mercurx Stake
            </MenuItem>
          </SubMenu>
        )}
        <SubMenu
          label="Settings"
          icon={<MercurXIcons name="TbSettings" size={20} />}
          className={`${activePath === '/profile#profile' ||
            activePath === '/profile#verify' ||
            activePath === '/profile#2fa'
            ? 'active-submenu'
            : ''
            }`}
          defaultOpen={
            activePath === '/profile#profile' ||
            activePath === '/profile#verify' ||
            activePath === '/profile#2fa'
          }
        >
          <MenuItem
            icon={<MercurXIcons name="MdPerson" size={20} />}
            className={`${activePath === '/profile#profile' ? 'active-menu-item' : ''}`}
            component={<Link to="/profile#profile" />}
          >
            Profile
          </MenuItem>
          <MenuItem
            icon={<MercurXIcons name="BiCheckShield" size={20} />}
            className={`${activePath === '/profile#verify' ? 'active-menu-item' : ''}`}
            component={<Link to="/profile#verify" />}
          >
            Verify Wallet
          </MenuItem>
          <MenuItem
            icon={<MercurXIcons name="Tb2Fa" size={20} />}
            className={`${activePath === '/profile#2fa' ? 'active-menu-item' : ''}`}
            component={<Link to="/profile#2fa" />}
          >
            2FA
          </MenuItem>
        </SubMenu>
        <MenuItem
          icon={<MercurXIcons name="BiBook" size={20} />}
          onClick={() => {
            window.open('https://mercurx-user-guide.gitbook.io/mercurx-backoffice', '_blank');
          }}
          component={<Link to="/" />}
        >
          User Guide
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXSidebarMenu);

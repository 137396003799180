import { useEffect, useState } from 'react';
import { Pagination, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import './MercurXTokenTable.scss';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';
import {
  deleteTokenActionRequest,
} from '../../store/token/tokenActions';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function MercurXTokenTable(props) {
  const { deleteTokenRequest, tokens, filteredTokens } = props;
  const history = useHistory();

  const maxRowCountPerPage = 10;
  const maxShowingPage = 5;
  const [sourceData, setSourceData] = useState(filteredTokens ? filteredTokens : tokens);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedTokens, setRenderedTokens] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] = useState(maxShowingPage);

  useEffect(() => {
    setSourceData(filteredTokens ? filteredTokens : tokens);
    
  }, [tokens, filteredTokens]);

  useEffect(() => {
    if (sourceData) {
      setCurrentPage(1);
      const tempPages = [];
      for (let index = 0; index < Math.ceil(sourceData.length / maxRowCountPerPage); index++) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [sourceData]);

  useEffect(() => {
    if (sourceData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = sourceData?.slice(firstIndex, lastIndex);
      setRenderedTokens([...tempRendered]);
    }
  }, [currentPage, sourceData]);

  function scrollToTokensContainer() {
    const tokensContainer = document.getElementById('tokens-container');
    if (tokensContainer) {
      tokensContainer.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
        maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
        maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToTokensContainer();
  };

  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToTokensContainer();
  };

  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToTokensContainer();
    }
  };

  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToTokensContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToTokensContainer();
  };

  function editToken(token) {
    history.push({
      pathname: '/tokens/edit-token',
      state: {
        token: token,
      },
    });
  }

  function deleteToken(tokenID, tokenIndex) {
    Swal.fire({
      icon: 'warning',
      iconColor: mainColors.warning,
      text: 'This process can not be undo. Are you sure?',
      showCancelButton: true,
    }).then((control) => {
      if (control.isConfirmed) {
        const payload = {
          tokenID,
          tokenIndex,
        };
        deleteTokenRequest(payload);
      }
    });
  }
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  function calculateFormat(x) {

    const formatBalance = (Math.floor((Number(x) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  return (
    <>
      <Table className="tokens-table-design" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Token Name</th>
            <th>Token Symbol</th>
            <th>Decimal</th>
            <th>Token Address</th>
            <th>Token Owner Address</th>
            <th>Total Supply</th>
            <th>Token Price in MERX</th>
            <th>Token Price in USD</th>
            <th>Distribution</th>
            <th>Current Price</th>
          </tr>
        </thead>
        {renderedTokens?.length ? (
          <tbody>
            {renderedTokens.map((item, index) => {
              if (item.status){
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr className="text-t-head-color">
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.symbol}</td>
                    <td>{item.decimals}</td>
                    <td>{`${item.address.slice(0, 3)}...${item.address.slice(-3)}`}</td>
                    <td>{`${item.owner_address.slice(0, 3)}...${item.owner_address.slice(-3)}`}</td>
                    <td>
                      {calculateFormat(item.total_supply)}
                    </td>

                    <td>{item.price_in_mercurx}</td> 
                     <td>
                      {' '}
                      {item.price_in_usd.toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'USD',
                        currencyDisplay: 'symbol',
                        useGrouping: true,
                        minimumFractionDigits: 4,
                      })}
                    </td>
                    <td>
                      {calculateFormat(item.distribution)}
                    </td>
                    <td>{item.current_price}</td> 
                    <td>
                    {user.is_superuser && (
                      <div className="cursor-pointer mt-1" onClick={() => editToken(item)}>
                        <MercurXIcons
                          name="BsPencilSquare"
                          size={18}
                          color={mainColors['primary']}
                        />
                      </div>
                    )}
                    </td>
                    <td>
                      {user.is_superuser && (
                      <div
                        className="cursor-pointer mt-1"
                        onClick={() => deleteToken(item.id, index)}
                      >
                        <MercurXIcons name="BsTrash3" size={18} color={mainColors['danger']} />
                      </div>
                      )}
                    </td>
                  </tr>
                );
              }}
            })}
          </tbody>
        ) : null}
      </Table>

      {!renderedTokens?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No token found according to search results.
        </div>
      )}

      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tokens: state.tokenReducer.tokens,
    filteredTokens: state.tokenReducer.filteredTokens
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteTokenRequest: (payload) => {
      dispatch(deleteTokenActionRequest(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXTokenTable);

import React from 'react';
import './CreateNewToken.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
// eslint-disable-next-line max-len
import MercurXProjectDetailInputs from '../../components/MercurXProjectDetailInputs/MercurXProjectDetailInputs';

function CreateNewToken({ ...props }) {
  return (
    <div>
      <MercurXBackButton buttonText="Back to token list" />
      <div className="text-fs-head-sm">Create new token</div>
      <MercurXProjectDetailInputs showProject={false} showToken={true}
         showSocial={false} showContract={true}/>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewToken);

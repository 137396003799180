/* eslint-disable max-len */
import { put, all, call, takeEvery } from 'redux-saga/effects';
import * as types from './bnbActionTypes';
import * as actions from './bnbActions';
// import * as alert from '../alert/alertActions';

import * as endpoints from '../../services/endpoints';

function* getBNBSaga(action) {
  try {
    const { data } = yield call(endpoints.getBNB);
    console.log("data");

    console.log(data);
    yield put(actions.getBNBData(data));
  } catch (e) {
    console.error(e);
    yield put(actions.getBNBError(e));
  }
}

function* watchGetBNB() {
  yield takeEvery(types.GET_BNB_REQUEST, getBNBSaga);
}
export function* bnbSaga() {
  yield all([watchGetBNB()]);
}

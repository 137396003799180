import { takeEvery, put, all, call } from 'redux-saga/effects';
import * as types from './bo_transactionActionTypes';
import * as actions from './bo_transactionActions';
import * as endpoints from '../../services/endpoints';

function* getBackofficeTransactionSaga(action) {
  try {
    
    const { data } = yield call(endpoints.getBoTransaction);
    console.log(data)
    yield put(actions.getBackofficeTransactionsActionData(data));
    // yield put (projectActions.getProjectByIDData(data))
  } catch (e) {
    yield put(actions.getBackofficeTransactionsActionError(e));
  }
}

function* BackofficeTransactionSaga({ creds }) {
  try {
    const { data } = yield call(endpoints.boTransaction, creds);
    console.log('data'+data)
    console.log(data)
    yield put(actions.transactionData(data));
  } catch (e) {
    yield put(actions.transactionError(e));
  }
}

function* watchBackofficeTransaction() {
  yield takeEvery(types.TRANSACTION_REQUEST, BackofficeTransactionSaga);
 
}

function* watchGetBackofficeTransaction() {
  yield takeEvery(types.GET_BACKOFFICETRANSACTIONS_REQUEST, getBackofficeTransactionSaga);
}

export function* backofficeTransactionSaga() {
  yield all([
    watchGetBackofficeTransaction(),
    watchBackofficeTransaction(),
  ]);
}

const LAUNCHPADUSERS_SORT_TYPES = {
  ASCENDING: 0,
  DESCENDING: 1,
};

const LAUNCHPADUSERS_SORT_KEYS = {
  FULL_NAME: 0,
  EMAIL: 1,
};

const sortTypes = [
  {
    name: 'Ascending',
    icon: 'BiSortUp',
  },
  {
    name: 'Descending',
    icon: 'BiSortDown',
  },
];

const sortKeys = [
  { name: 'Full Name', key: 'full_name' },
  { name: 'E-mail', key: 'mail' },
];

const launchpadUsersQuickFilterConstants = {
  ALL: 1,
  TRUE: 2,
  FALSE: 3,
};

export {
  LAUNCHPADUSERS_SORT_KEYS,
  LAUNCHPADUSERS_SORT_TYPES,
  sortTypes,
  sortKeys,
  launchpadUsersQuickFilterConstants,
};

import * as types from './launchpadUserActionTypes';

export const getLaunchpadUsersActionRequest = (payload) => {
  return {
    type: types.GET_LAUNCHPADUSERS_REQUEST,
    payload,
  };
};

export const getLaunchpadUsersActionData = (payload) => {
  return {
    type: types.GET_LAUNCHPADUSERS_DATA,
    payload,
  };
};

export const getLaunchpadUsersActionError = (payload) => {
  return {
    type: types.GET_LAUNCHPADUSERS_ERROR,
    payload,
  };
};
export const updateQuickFilterAction = (payload) => {
  return {
    type: types.UPDATE_QUICK_FILTER,
    payload,
  };
};

export const filterLaunchpadUsersAction = (payload) => {
  return {
    type: types.FILTER_LAUNCHPADUSERS,
    payload,
  };
};

export const setLaunchpadUsersSortDataAction = (payload) => {
  return {
    type: types.SET_LAUNCHPADUSERS_SORT_DATA,
    payload,
  };
};
export const sortLaunchpadUsersAction = (payload) => {
  return {
    type: types.SORT_LAUNCHPADUSERS,
    payload,
  };
};
 export function RoundSale(data) {
   this.description = '';
   this.start_date = '';
   this.end_date = '';
   if (data) {
     this.description = data.description ;
     this.start_date = data.start_date 
     this.end_date = data.end_date ;
   }
 }
 export function StakingContract(data) {
   this.contract_address = '';
   this.contract_owner_address = '';
   this.reward_token_address = '';
   this.reward_token_owner_address = '';
   if (data) {
     this.contract_address = data.contract_address;
     this.contract_owner_address = data.contract_owner_address;
     this.reward_token_address = data.reward_token_address;
     this.reward_token_owner_address = data.reward_token_owner_address;
   }
 }
 export function PresaleContract(data) {
   this.contract_address = '';
   this.contract_owner_address = '';
   if (data) {
     this.contract_address = data.contract_address;
     this.contract_owner_address = data.contract_owner_address;
   }
 }
 export function Token(data) {
   this.name = '';
   this.symbol = '';
   this.decimals = '';
   this.address = '';
   this.owner_address = '';
   this.total_supply = '';
   this.price_in_usd = '';
   this.price_in_mercurx = '';
   this.distribution = '';
   this.current_price = '';
   this.all_time_high = '';
   this.staking_contract = new StakingContract();
   this.presale_contract = new PresaleContract();
   if (data) {
     this.name = data.name;
     this.symbol = data.symbol;
     this.decimals = data.decimals;
     this.address = data.address;
     this.owner_address = data.owner_address;
     this.total_supply = data.total_supply;
     this.price_in_usd = data.price_in_usd;
     this.price_in_mercurx = data.price_in_mercurx;
     this.distribution = data.distribution;
     this.current_price = data.current_price;
     this.all_time_high = data.all_time_high;
     this.staking_contract = new StakingContract(data.staking_contract);
     this.presale_contract = new PresaleContract(data.presale_contract);
   }
 }
 export function Social(data) {
   this.twitter = '';
   this.discord = '';
   this.telegram = '';
   if (data) {
     this.twitter = data.twitter;
     this.discord = data.discord;
     this.telegram = data.telegram;
   }
 }
 export function RegistrationRound(data) {
   this.description = '';
   this.start_date = '';
   this.end_date = '';
   if (data) {
     this.description = data.description;
     this.start_date = data.start_date;
     this.end_date = data.end_date;
   }
 }
 export function StakingRound(data) {
   this.description = '';
   this.start_date = '';
   this.end_date = '';
   if (data) {
     this.description = data.description;
     this.start_date = data.start_date;
     this.end_date = data.end_date;
   }
 }
 export function PublicRound(data) {
   this.description = '';
   this.start_date = '';
   this.end_date = '';
   if (data) {
     this.description = data.description;
     this.start_date = data.start_date;
     this.end_date = data.end_date;
   }
 }
 export function PrivateRound(data) {
   this.description = '';
   this.start_date = '';
   this.end_date = '';
   if (data) {
     this.description = data.description;
     this.start_date = data.start_date;
     this.end_date = data.end_date;
   }
 }
 export function ValidationRound(data) {
   this.description = '';
   this.start_date = '';
   this.end_date = '';
   if (data) {
     this.description = data.description;
     this.start_date = data.start_date;
     this.end_date = data.end_date;
   }
 }
 export function ProjectDetail(data) {
   this.name = '';
   this.number_of_registrations = '';
   this.number_of_participants = '';
   this.sale_type = '';
   this.percent_raised = '';
   this.round_sale = new RoundSale();
   this.target_raised = '';
   this.total_tokens_sold = '';
   this.total_raised = '';
   this.state = '';
   this.explanation_text = '';
   this.website_url = '';
   this.withdraw_all = true;
   this.staking_idle_time = '';
   this.is_staking_idle = true;
   this.is_airdrop = true;
   this.token = new Token();
   this.social = new Social();
   this.round_publicsale = new PublicRound();
   this.round_privatesale = new PrivateRound();
   this.round_validation = new ValidationRound();
   this.round_staking = new StakingRound();
   this.round_registration = new RegistrationRound();
   if (data) {
     this.name = data.name;
     this.number_of_registrations = data.number_of_registrations;
     this.number_of_participants = data.number_of_participants;
     this.sale_type = data.sale_type;
     this.percent_raised = data.percent_raised;
     this.round_sale = new RoundSale(data.round_sale);
     this.target_raised = data.target_raised;
     this.total_tokens_sold = data.total_tokens_sold;
     this.total_raised = data.total_raised;
     this.state = data.state;
     this.explanation_text = data.explanation_text;
     this.website_url = data.website_url;
     this.withdraw_all = data.withdraw_all;
     this.staking_idle_time = data.staking_idle_time;
     this.is_staking_idle = data.is_staking_idle;
     this.is_airdrop = data.is_airdrop;
     this.token = new Token(data.token); 
     this.social = new Social(data.social);

   }
 }
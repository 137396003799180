import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { checkUser } from '../helpers/userHelper';
import { loginActionData } from './user/userActions';
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
const user = checkUser();

if (user) {
  store.dispatch(loginActionData(user));
}

sagaMiddleware.run(rootSaga);

export default store;

/* eslint-disable max-len */
import React, { useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, FormLabel } from 'react-bootstrap';
import MercurXFormControl from '../../components/MercurXFormControl/MercurXFormControl';
import { activationRequest } from '../../store/user/userActions';
import './Activation.scss';
import { InputGroup } from 'react-bootstrap';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';

const params = new URLSearchParams(window.location.search);
const activationToken = params.get('token');

const initialState = {
  activationCode: '',
  new_password: '',
  confirm_password: '',
  errors: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return {
        ...state,
        [action.field]: action.value,
        errors: {
          ...state.errors,
          [action.field]: '',
        },
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.errors,
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

const validatePassword = (password) => {
  const errors = [];

  if (!password || password.length < 8) {
    errors.push({ message: 'Must be at least 8 characters long.', isValid: false });
  } else {
    errors.push({ message: 'Must be at least 8 characters long.', isValid: true });
  }

  if (!/[A-Z]/.test(password)) {
    errors.push({ message: 'Must contain at least one uppercase letter.', isValid: false });
  } else {
    errors.push({ message: 'Must contain at least one uppercase letter.', isValid: true });
  }

  if (!/[a-z]/.test(password)) {
    errors.push({ message: 'Must contain at least one lowercase letter.', isValid: false });
  } else {
    errors.push({ message: 'Must contain at least one lowercase letter.', isValid: true });
  }

  if (!/\d/.test(password)) {
    errors.push({ message: 'Must contain at least one digit.', isValid: false });
  } else {
    errors.push({ message: 'Must contain at least one digit.', isValid: true });
  }

  if (!/[@$!%*?&]/.test(password)) {
    errors.push({ message: 'Must contain at least one special character (@, $, !, %, *, ?, &).', isValid: false });
  } else {
    errors.push({ message: 'Must contain at least one special character (@, $, !, %, *, ?, &).', isValid: true });
  }

  return errors;
};

function Activation({ history, activation }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirm_passwordVisibility, setConfirmPasswordVisibility] = useState(false);

  const validate = () => {
    const errors = {};
    if (state.activationCode === '') errors.activationCode = 'Activation Code cannot be blank.';
    if (state.new_password === '') errors.new_password = 'New Password cannot be blank.';
    if (state.confirm_password === '') errors.confirm_password = 'Confirm Password cannot be blank.';
    if (state.new_password !== state.confirm_password) errors.confirm_password = 'Passwords do not match.';
    if (state.new_password.length > 32 || state.new_password.length < 6) {
      errors.new_password = 'Password should be between 6-32 characters.';
    }
    const passwordErrors = validatePassword(state.new_password);
    passwordErrors.forEach((error, index) => {
      if (!error.isValid) {
        // Add error to the errors object
        errors.new_password = errors.new_password
          ? `${errors.new_password} ${error.message}`
          : error.message;
      }
    });
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();

    if (Object.keys(errors).length === 0) {
      const payload = {
        activationCode: state.activationCode,
        new_password: state.new_password,
        confirm_password: state.confirm_password,
        activationToken,
        history,
      };
      console.log(payload)
      activation(payload);

      dispatch({ type: 'RESET_STATE' });
    } else {
      dispatch({ type: 'SET_ERRORS', errors });
    }
  };

  const handleChange = (e) => {
    dispatch({ type: 'SET_FIELD', field: e.target.id, value: e.target.value });
  };

  const handleTogglePasswordVisibility = () => {
    setPasswordVisibility((prevVisibility) => !prevVisibility);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility((prevVisibility) => !prevVisibility);
  };

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col className="public-activation-layout-col d-flex justify-content-center align-items-center bg-white px-1 py-2">
          <div className="public-activation-layout-image d-md-flex d-none flex-column justify-content-center align-items-center bg-white px-1 py-2">
            <div className="text-fs-head-lg text-center text-primary px-2">
              Welcome to the exciting world of <span>MercurX</span>
            </div>
            <div className="text-fs-body-md text-center text-light px-2">
              {state?.new_password && validatePassword(state.new_password).map((error, index) => (
                <Form.Label key={index} className={`text-${error.isValid ? 'success' : 'danger'} text-fs-body-sm mercurx-error-label d-flex`}>
                  {error.isValid ? '✓ ' : '* '}
                  {error.message}
                </Form.Label>
              ))}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center aling-items-center px-4 mx-2 public-activation-layout-form">
            <Form onSubmit={handleSubmit}>
              <MercurXFormControl
                label="Activation Code"
                type="password"
                id="activationCode"
                value={state.activationCode}
                handleChange={handleChange}
                error={state.errors.activationCode}
              />
              <FormLabel className='text-fs-body-lg text-t-head-color'>
                Password
              </FormLabel>
              <InputGroup>
                <Form.Control
                  type={passwordVisibility ? 'text' : 'password'}
                  name="new_password"
                  id="new_password"
                  value={state.new_password}
                  isInvalid={state.errors.new_password}
                  onChange={(e) => {
                    handleChange(e, 'new_password');
                  }}
                />
                <InputGroup.Text id="basic-addon2" className='d-flex justify-content-between align-items-center input-group-text-custom'>
                  <Col>
                    <div
                     onClick={handleTogglePasswordVisibility}
                    >
                      <MercurXIcons
                        name={passwordVisibility ? 'BsEyeSlash' : 'BsEye'}
                        iconClassName="mercurx-password-icon"
                      />
                    </div>
                  </Col>
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">{state.errors.password}</Form.Control.Feedback>
              </InputGroup>
              <FormLabel className='text-fs-body-lg text-t-head-color'>
                Confirm Password
              </FormLabel>
              <InputGroup>
                <Form.Control
                  type={confirm_passwordVisibility ? 'text' : 'password'}
                  name="confirm_password"
                  id="confirm_password"
                  value={state.confirm_password}
                  isInvalid={state.errors.confirm_password}
                  onChange={(e) => {
                    handleChange(e, 'confirm_password');
                  }}
                />
                <InputGroup.Text id="basic-addon2" className='d-flex justify-content-center align-items-center input-group-text-custom'>
                  <Col >
                    <div
                       onClick={handleToggleConfirmPasswordVisibility}
                    >
                      <MercurXIcons
                         name={confirm_passwordVisibility ? 'BsEyeSlash' : 'BsEye'}
                        iconClassName="mercurx-password-icon"
                      />
                    </div>
                  </Col>
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">{state.errors.confirm_password}</Form.Control.Feedback>
              </InputGroup>
              <Button className="mb-4 mt-4 col-sm-12" type="submit">
                Activate your account
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  activation: (creds) => dispatch(activationRequest(creds)),
});

export default connect(null, mapDispatchToProps)(Activation);

import { takeEvery, call, put, all } from 'redux-saga/effects';

import * as types from './projectActionTypes';
import * as actions from './projectActions';
import * as endpoints from '../../services/endpoints';
import * as alert from '../alert/alertActions';
import { errorHandler } from '../../helpers/errorHandler';
//Worker Sagas

function* getProjectsSaga(action) {
  try {
    const { data } = yield call(endpoints.getProjects);
    yield put(actions.getProjectsActionData(data));
    yield put(actions.sortProjectsAction());
  } catch (e) {
    yield put(actions.getProjectsActionError(e));
  }
}

function* createProjectSaga(action) {
  try {
    const { body } = action.payload;
    console.log(body);
    const { data } = yield call(endpoints.createProject, body);
    yield put(actions.createProjectActionData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: "Project creation process is successful.",
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) 
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.detail,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.createProjectActionError(e));
  }
}

function* updateProjectSaga(action) {
  try {
    const { projectID, body } = action.payload;
    console.log(projectID);
    console.log(body);
    const { data } = yield call(endpoints.updateProject, projectID, body);
    console.log(data);
    yield put(actions.updateProjectActionData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: "The project update process is successful.",
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) 
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.detail,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.updateProjectActionError(e));
  }
}

function* deleteProjectSaga(action) {
  try {
    const { projectID } = action.payload;
    console.log('deleteProjectSaga: ', projectID);
    const { data } = yield call(endpoints.deleteProject, projectID);
    yield put(actions.deleteProjectActionData(projectID));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: "The project deletion process is successful.",
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) 
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.detail,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.deleteProjectActionError(e));
  }
}

//Watcher Sagas
function* watchGetProjects() {
  yield takeEvery(types.GET_PROJECTS_REQUEST, getProjectsSaga);
}
function* watchCreateProject() {
  yield takeEvery(types.CREATE_PROJECT_REQUEST, createProjectSaga);
}

function* watchUpdateProject() {
  yield takeEvery(types.UPDATE_PROJECT_REQUEST, updateProjectSaga);
}

function* watchDeleteProject() {
  yield takeEvery(types.DELETE_PROJECT_REQUEST, deleteProjectSaga);
}

export function* projectSaga() {
  yield all([watchGetProjects(), watchDeleteProject(), watchCreateProject(), watchUpdateProject()]);
}

import { all } from 'redux-saga/effects';
import { userSaga } from './user/userSaga';
import { projectSaga } from './project/projectSaga';
import { tokenSaga } from './token/tokenSaga';
import { transactionSaga } from './transaction/transactionSaga';
import { lockSaga } from './lock/lockSaga';
import { stakeSaga } from './stake/stakeSaga';
import { launchpadUserSaga } from './launchpadUser/launchpadUserSaga';
import { backofficeUserSaga } from './backofficeUser/backofficeUserSaga';
import {walletSaga} from './wallet/walletSaga'
import { tierSaga } from './tier/tierSaga';
import { abiSaga } from './abi/abiSaga';
import { bnbSaga } from './bnb/bnbSaga';
import { backofficeTransactionSaga } from './bo_transaction/bo_transactionSaga';
export default function* rootSaga() {
  yield all([
    userSaga(), 
    projectSaga(),
    tokenSaga(),
    transactionSaga(),
    lockSaga(),
    stakeSaga(),
    launchpadUserSaga(),
    backofficeUserSaga(),
    walletSaga(),
    tierSaga(),
    abiSaga(),
    bnbSaga(),
    backofficeTransactionSaga(),
  ]);
     
}
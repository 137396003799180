import * as types from './lockActionTypes';

export const getLocksActionRequest = (payload) => {
  return {
    type: types.GET_LOCKS_REQUEST,
    payload,
  };
};

export const getLocksActionData = (payload) => {
  return {
    type: types.GET_LOCKS_DATA,
    payload,
  };
};

export const getLocksActionError = (payload) => {
  return {
    type: types.GET_LOCKS_ERROR,
    payload,
  };
};
export const updateQuickFilterAction = (payload) => {
  return {
    type: types.UPDATE_QUICK_FILTER,
    payload,
  };
};

export const filterLocksAction = (payload) => {
  return {
    type: types.FILTER_LOCKS,
    payload,
  };
};

export const setLocksSortDataAction = (payload) => {
  return {
    type: types.SET_LOCKS_SORT_DATA,
    payload,
  };
};
export const sortLocksAction = (payload) => {
  return {
    type: types.SORT_LOCKS,
    payload,
  };
};
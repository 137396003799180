import {
  STAKES_SORT_KEYS,
  STAKES_SORT_TYPES,
  stakesQuickFilterConstants,
  sortKeys,
} from '../../pages/Stakes/stakesConstants';
import * as types from './stakeActionTypes';

const initialState = {
  stakes: null,
  filteredStakes: null,
  stakesSortData: {
    sortType: STAKES_SORT_TYPES.ASCENDING,
    sortKey: STAKES_SORT_KEYS.TOKEN_NAME,
  },
  quickFilter: 1,
  error: {
    type: null,
    data: null,
  },
};

export const stakeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STAKES_DATA:
      let stakes = action.payload
      return {
        ...state,
        stakes: stakes ? [...stakes] : null,
      };

    case types.GET_STAKES_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_STAKES_ERROR,
          data: action.payload,
        },
      };
    case types.FILTER_STAKES:
      function filterStakes(filterInput) {
        console.log(filterInput)
        const filteredStakes = [];
        const date1 = new Date();
        let tempStakesForFilter = state.stakes;
        const formatDateStatus = (originalDate) => {

          const dateObj = new Date(originalDate);
          const newdateObj = new Date(
            dateObj.getUTCFullYear(),
            dateObj.getUTCMonth(),
            dateObj.getUTCDate(),
            dateObj.getUTCHours(),
            dateObj.getUTCMinutes(),
            dateObj.getUTCSeconds(),
          );
          return newdateObj;
        };

        if (state.quickFilter === stakesQuickFilterConstants.STAKED) {
          tempStakesForFilter = tempStakesForFilter.filter((t) => t.claim_status === true )
        } else if (state.quickFilter === stakesQuickFilterConstants.DONE) {
          tempStakesForFilter = tempStakesForFilter.filter((t) => t.claim_status === false);
        }


        if (tempStakesForFilter) {
          for (const fStakes of tempStakesForFilter) {
            if (
              fStakes?.symbol?.toString().toLowerCase().includes(filterInput) ||
              fStakes?.tx_hash?.toString().toLowerCase().includes(filterInput) ||
              fStakes?.wallet?.toString().toLowerCase().includes(filterInput)
            ) {
              filteredStakes.push(fStakes);
            }
          }

          return filteredStakes;
        }
      }

      let filteredStakes = null;
      filteredStakes = filterStakes(action?.payload?.toString().toLowerCase());

      return {
        ...state,
        filteredStakes: filteredStakes ? [...filteredStakes] : null,
      };
    case types.SET_STAKES_SORT_DATA:
      return {
        ...state,
        stakesSortData: { ...action.payload },
      };
    case types.SORT_STAKES:
      const selectedKey = sortKeys[state.stakesSortData.sortKey].key;
      const tempStakesForSorting = state.filteredStakes?.length
        ? state.filteredStakes
        : state.stakes;
      console.log("Temp Stakes For Sorting:", tempStakesForSorting);

      const sortedStakes = tempStakesForSorting?.sort((a, b) => {
        if (state.stakesSortData.sortType === STAKES_SORT_TYPES.ASCENDING) {


          return (

            (selectedKey === 'date' ? new Date(a.start_lock_time) - new Date(b.start_lock_time) :
              a[selectedKey]
                ?.toString()
                .toLowerCase()
                .localeCompare(b[selectedKey]?.toString().toLowerCase())
            )
          );
        } else {


          return (

            (selectedKey === 'date' ? new Date(b.start_lock_time) - new Date(a.start_lock_time) :
              b[selectedKey]
                ?.toString()
                .toLowerCase()
                .localeCompare(a[selectedKey]?.toString().toLowerCase())
            )
          );

        }
      });

      if (state.filteredStakes?.length) {
        return {
          ...state,
          filteredStakes: sortedStakes ? [...sortedStakes] : null,
        };
      }
      return {
        ...state,
        stakes: sortedStakes ? [...sortedStakes] : state.stakes,
      };
    case types.UPDATE_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action?.payload,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import './Tokens.scss';
import { connect } from 'react-redux';
import {
  filterTokensAction,
  getTokensActionRequest,
  setTokensSortDataAction,
  sortTokensAction,
  updateQuickFilterAction,
} from '../../store/token/tokenActions';
import MercurXTokenTable from '../../components/MercurXTokenTable/MercurXTokenTable';
import { Button, Col, Dropdown, Form, Row,  } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import {  sortKeys, sortTypes } from './tokensConstants';

function Tokens({ ...props }) {
  const {
    getTokensRequest,
    quickFilter,
    tokensSortData,
    setTokensSortData,
    sortTokens,
    filterTokens,
  } = props;

  const history = useHistory();
  const [filterInput, setFilterInput] = useState('');
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  useEffect(() => {
    getTokensRequest();
  }, []);


  useEffect(() => {
    filterTokens(filterInput);
  }, [filterInput])

  function createNewToken() {
    history.push('/tokens/create-new-token');
  }

  function changeSortType(sortType) {
    const newTokenSortData = { ...tokensSortData, sortType };
    setTokensSortData(newTokenSortData);
    sortTokens();
  }

  function changeSortKey(sortKey) {
    const newTokenSortData = { ...tokensSortData, sortKey };
    setTokensSortData(newTokenSortData);
    sortTokens();
  }

  return (
    <>
      <div className="text-fs-head-md mb-4">Tokens</div>
      <Row className="text-fs-body-md mt-4 text-center">
        <Col>
          <Form.Control
            className="text-fs-body-sm"
            type="text"
            placeholder="Search by token name, token symbol or token contract adress..."
            aria-label="text"
            aria-describedby="basic-addon1"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            size={'sm'}
          />
        </Col>
      </Row>
      <Row
        id="tokens-container"
        className="d-flex align-items-center justify-content-between mt-4"
      >
        <Col>
          <div id="tokens-sorting-section" className="d-flex align-items-center py-2">
            <Dropdown className="me-2 tokens-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">{sortTypes[tokensSortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="tokens-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">{sortKeys[tokensSortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className="text-md-end">
          
        </Col>
      </Row>
      <div id="tokens-container">
        <MercurXTokenTable />
      </div>
      <div className="text-end">
      {user.is_superuser && (
        <Button onClick={createNewToken} size={'sm'}>
          Create new token
        </Button>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    quickFilter: state.tokenReducer.quickFilter,
    tokensSortData: state.tokenReducer.tokensSortData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTokensRequest: (payload) => {
      dispatch(getTokensActionRequest(payload));
    },
    updateQuickFilter: (payload) => {
      dispatch(updateQuickFilterAction(payload));
    },
    setTokensSortData: (payload) => {
      dispatch(setTokensSortDataAction(payload));
    },
    sortTokens: (payload) => {
      dispatch(sortTokensAction(payload));
    },
    filterTokens: (payload) => {
      dispatch(filterTokensAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tokens);

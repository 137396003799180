import React, { useEffect, useState } from 'react';
import './LaunchpadUsers.scss';
import { connect } from 'react-redux';
import {
  getLaunchpadUsersActionRequest,
  filterLaunchpadUsersAction,
  setLaunchpadUsersSortDataAction,
  sortLaunchpadUsersAction,
  updateQuickFilterAction
} from '../../store/launchpadUser/launchpadUserActions';
import MercurXLaunchpadUserTable 
from '../../components/MercurXLaunchpadUserTable/MercurXLaunchpadUserTable';
import {  Col, Dropdown, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { launchpadUsersQuickFilterConstants, sortKeys, sortTypes } from './launchpadUsersConstants';


function LaunchpadUsers({ ...props }) {
  const {
    getlaunchpadUsersRequest,
    quickFilter,
    launchpadUsersSortData,
    updateQuickFilter,
    setLaunchpadUsersSortData,
    sortLaunchpadUsers,
    filterLaunchpadUsers,
  } = props;

  const [filterInput, setFilterInput] = useState('');

  useEffect(() => {
    getlaunchpadUsersRequest();
    updateQuickFilter(launchpadUsersQuickFilterConstants.ALL)
  }, []);
  useEffect(() => {
    filterLaunchpadUsers(filterInput);
    sortLaunchpadUsers();
  }, [quickFilter]);

  useEffect(() => {
    filterLaunchpadUsers(filterInput);
  }, [filterInput])

  function changeSortType(sortType) {
    const newlaunchpadUserSortData = { ...launchpadUsersSortData, sortType };
    setLaunchpadUsersSortData(newlaunchpadUserSortData);
    sortLaunchpadUsers();
  }

  function changeSortKey(sortKey) {
    const newlaunchpadUserSortData = { ...launchpadUsersSortData, sortKey };
    setLaunchpadUsersSortData(newlaunchpadUserSortData);
    sortLaunchpadUsers();
  }

  return (
    <>
      <div className="text-fs-head-md mb-4">Launchpad Users</div>
      <Row className="text-fs-body-md mt-4 text-center">
        <Col>
          <Form.Control
            className="text-fs-body-sm"
            type="text"
            placeholder="Search by full name or email..."
            aria-label="text"
            aria-describedby="basic-addon1"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            size={'sm'}
          />
        </Col>
      </Row>
      <Row
        id="launchpadUsers-container"
        className="d-flex align-items-center justify-content-between mt-4"
      >
        <Col>
          <div id="launchpadUsers-sorting-section" className="d-flex align-items-center py-2">
          <Dropdown className="me-2 projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">{sortTypes[launchpadUsersSortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">{sortKeys[launchpadUsersSortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className="text-md-end">
        <ToggleButtonGroup size={'sm'} type="radio" name="options" defaultValue={1}>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === launchpadUsersQuickFilterConstants.ALL ? 'primary' : 'light'}
              className={
                quickFilter !== launchpadUsersQuickFilterConstants.ALL ? 'text-t-body-color' : ''
              }
              id="tbg-radio-1"
              value={launchpadUsersQuickFilterConstants.ALL}
              onClick={() => updateQuickFilter(launchpadUsersQuickFilterConstants.ALL)}
            >
              All
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === launchpadUsersQuickFilterConstants.TRUE ? 
                'primary' : 'light'}
              className={
                quickFilter !== launchpadUsersQuickFilterConstants.TRUE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-2"
              value={launchpadUsersQuickFilterConstants.TRUE}
              onClick={() => updateQuickFilter(launchpadUsersQuickFilterConstants.TRUE)}
            >
              True
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === launchpadUsersQuickFilterConstants.FALSE ? 
                'primary' : 'light'}
              className={
                quickFilter !== launchpadUsersQuickFilterConstants.FALSE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-3"
              value={launchpadUsersQuickFilterConstants.FALSE}
              onClick={() => updateQuickFilter(launchpadUsersQuickFilterConstants.FALSE)}
            >
              False
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <div id="launchpadUsers-container">
        <MercurXLaunchpadUserTable />
      </div>
      
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    quickFilter: state.launchpadUserReducer.quickFilter,
    launchpadUsersSortData: state.launchpadUserReducer.launchpadUsersSortData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getlaunchpadUsersRequest: (payload) => {
      dispatch(getLaunchpadUsersActionRequest(payload));
    },
    updateQuickFilter: (payload) => {
      dispatch(updateQuickFilterAction(payload));
    },
    setLaunchpadUsersSortData: (payload) => {
      dispatch(setLaunchpadUsersSortDataAction(payload));
    },
    sortLaunchpadUsers: (payload) => {
      dispatch(sortLaunchpadUsersAction(payload));
    },
    filterLaunchpadUsers: (payload) => {
      dispatch(filterLaunchpadUsersAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchpadUsers);

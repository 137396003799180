import React, { useState, useEffect } from 'react';
import './Login.scss';
import { Button, Form, Modal, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  accountDetailsRequestAction, loginActionRequest,
  verify2faRequestAction
} from '../../store/user/userActions';
import { useDispatch } from 'react-redux';
import { InputGroup } from 'react-bootstrap';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';

function Login({ loginRequest, user, verify2faRequest }) {


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const setVisibility = (visibility) => {
    setPasswordVisibility(visibility);
  };

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.two_factor_auth) {
      setShowModal(true);
    }
  }, [user?.two_factor_auth]);

  async function login() {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const payload = {
        username,
        password,
        history,
      };

      // Login isteğini gönder
      await loginRequest(payload);
      // TODO: Gelen verileri kontrol et, two_factor_auth true ise modal'ı aç
      // const data = {}; // TODO: Gelen verileri güncelleyin
      console.log(payload)
      if (user?.two_factor_auth) {
        setShowModal(true);
      }
    } else {
      setErrors(errors);
    }
  }

  function validateForm() {
    const errors = {};
    if (username.trim() === '') {
      errors.username = 'Username cannot be blank.';
    }
    if (password.trim() === '') {
      errors.password = 'Password cannot be blank.';
    }
    return errors;
  }
  const handleVerify = async () => {
    try {
      const userId = user.id;
      const is_enabled = user.two_factor_auth;
      const totp_code = inputValue.replace(/-/g, "");
      console.log(totp_code)
      // totp_code = totp_code.trim();
      const payload = {
        userId: userId,
        totp_code: totp_code,
        is_enabled: is_enabled,
        history,
        loginPayload: {
          username,
          password,
        },
      };

      console.log(payload);
      await verify2faRequest(payload);

      // State'i sıfırla
      setInputValue('');

      setShowModal(false);
      // dispatch(verify2faRequestAction(payload));
      dispatch(verify2faRequestAction({ userId, totp_code, is_enabled, history }));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  const handleInputChange = (e) => {
    let value = e.target.value;
    value = value.replace(/-/g, "");
    value = value.replace(/[^0-9]/g, "").slice(0, 6);
    if (value.length > 3) {
      value = value.slice(0, 3) + "-" + value.slice(3);
    }
    setInputValue(value);
  };


  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()} onKeyPress={(e) => e.key === 'Enter' && login()}>
        <Form.Group>
          <Form.Label className="text-fs-header-xs">Email address</Form.Label>
          <Form.Control className='input'
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setErrors({});
            }}
            isInvalid={errors?.username}

          />
          <Form.Control.Feedback type="invalid">{errors?.username}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label className="text-fs-header-xs">Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={passwordVisibility ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrors({});
              }}
              isInvalid={errors?.password}
            />
            <InputGroup.Text id="basic-addon2"
             className='d-flex justify-content-between align-items-center input-group-text-custom'>
              <Col>
                <div
                  onClick={() => setVisibility(!passwordVisibility)}
                >
                  <MercurXIcons
                    name={passwordVisibility ? 'BsEyeSlash' : 'BsEye'}
                    iconClassName="mercurx-password-icon"
                  />
                </div>
              </Col>
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">{errors?.password}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Button
          className="mb-4 mt-3 bg-mercurx-primary col-sm-12"
          variant="primary"
          onClick={() => {
            login();
            if (user?.two_factor_auth) {
              setShowModal(true);
            }
          }}
        >
          Login
        </Button>
        <NavLink
          className="forgotpassword-link d-flex justify-content-center m-2 text-primary"
          to="/forgotpassword"
        >
          Forgot Password?
        </NavLink>
      </Form>

      {/* Two Factor Auth Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Two Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <p>Step 2 Content</p> */}
            <Form.Group className="mb-3">
              <Form.Label>Enter 2FA code:</Form.Label>
              <Form.Control
                type="text"
                placeholder="XXX-XXX"
                value={inputValue}
                // onChange={(e) => setInputValue(e.target.value)}
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleVerify()}>
            Enter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    // user: state.userReducer.user,
    user: state.userReducer.user || { two_factor_auth: false },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (payload) => {
      return dispatch(loginActionRequest(payload));
    },
    accountDetailsRequest: () => {
      dispatch(accountDetailsRequestAction());
    },
    verify2faRequest: (payload) => {
      dispatch(verify2faRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

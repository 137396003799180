import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './MercurXBackofficeUserDetailInputs.scss';
import {
  BackofficeUserDetail,
} from '../../models/BackofficeUserDetailModel';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';
// import moment from 'moment/moment';
import {
  createBackofficeUserActionRequest,
  updateBackofficeUserActionRequest,
} from '../../store/backofficeUser/backofficeUserActions';
import { useHistory } from 'react-router-dom';

const MercurXBackofficeUserDetailInputs = (props) => {
  const {
    isEdit,
    createBackofficeUserRequest,
    updateBackofficeUserRequest,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const backofficeUser = location.state?.backofficeUser;

  const [backofficeUserDetail, setBackofficeUserDetail] = useState(new BackofficeUserDetail());

  const [requiredErrors, setRequiredErrors] = useState([]);
  const [DateErrors, setDateErrors] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  // useEffect(() => {
  //   if (backofficeUser) {
  //     setBackofficeUserDetail(new (BackofficeUserDetail));
  //   }
  // }, []);
  useEffect(() => {
    if (backofficeUser) {
      setBackofficeUserDetail(backofficeUser);
    } else {
      setBackofficeUserDetail(new BackofficeUserDetail());
    }
  }, [backofficeUser]);
  // function backofficeUserDetailOnChange(e, value) {
  //   const key = e.target.id;
  //   if (value === undefined || value === null) value = e.target.value;
  //   backofficeUserDetail[key] = value;
  //   setBackofficeUserDetail({ ...backofficeUserDetail });
  //   const errorKey = 'backofficeUser_detail_' + key;
  //   clearError(errorKey);
  // }
  function backofficeUserDetailOnChange(e, value) {
    const key = e.target.id;
    
    if (value === undefined || value === null) {
      value = e.target.type === 'radio' ? e.target.value === 'true' : e.target.value;
    }
  
    backofficeUserDetail[key] = value;
    setBackofficeUserDetail({ ...backofficeUserDetail });
    const errorKey = 'backofficeUser_detail_' + key;
    clearError(errorKey);
  }
  
  function isEmailValid(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function controlBackofficeUserDetail() {
    const requiredErrors = [];

    if (!backofficeUserDetail.full_name) {
      requiredErrors.push('backofficeUser_detail_full_name');
    }
    if (!backofficeUserDetail.email) {
      requiredErrors.push('backofficeUser_detail_email');
    } else if (!isEmailValid(backofficeUserDetail.email)) {
      // E-posta formatı doğru değilse hata ekleyin
      requiredErrors.push('backofficeUser_detail_email_format');
    }


    setRequiredErrors([...requiredErrors]);
    if (requiredErrors.length) {
      return false;
    } else {
      return true;
    }
  }



  function controlInputs(key) {
    const errors = new Set(requiredErrors);
    if (errors.has(key)) {
      return true;
    } else {
      return false;
    }
  }

  function clearError(key) {
    const errors = new Set(requiredErrors);
    if (errors.has(key)) {
      errors.delete(key);
    }
    setRequiredErrors([...errors]);
  }

  function updateBackofficeUser() {
    if (controlBackofficeUserDetail()) {

      const payload = {
        userID: backofficeUser.id,
        body: backofficeUserDetail,
      };

      updateBackofficeUserRequest(payload);
      setTimeout(() => {
        history.goBack();
      }, 2000);
    }
  }

  function createBackofficeUser() {
    if (controlBackofficeUserDetail()) {
      const payload = {
        body: backofficeUserDetail,
      };
      createBackofficeUserRequest(payload);
      setTimeout(() => {
        history.goBack();
      }, 2000);
    }
  }


  if (isEdit && !backofficeUser) {
    return null;
  }

  return (
    <div className="backofficeUser-detail">
      <Accordion className="mt-5 shadow" defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="text-fs-head-sm">Backoffice User</div>
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md="6">
                <Form.Group className="mt-2">
                  <Form.Label>Full Name*</Form.Label>
                  <Form.Control
                    id="full_name"
                    type="text"
                    value={backofficeUserDetail.full_name}
                    onChange={backofficeUserDetailOnChange}
                    isInvalid={controlInputs('backofficeUser_detail_full_name')}
                  />
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label>E-mail*</Form.Label>
                  <Form.Control
                    id="email"
                    type="text"
                    value={backofficeUserDetail.email}
                    onChange={backofficeUserDetailOnChange}
                    isInvalid={controlInputs('backofficeUser_detail_email') ||
                      controlInputs('backofficeUser_detail_email_format')}
                    readOnly={isEdit}
                    style={isEdit && backofficeUserDetail.email !== '' ?
                     { backgroundColor: '#d9d9d9' } : {}}
                  />
                  <Form.Control.Feedback type="invalid">
                    {controlInputs('backofficeUser_detail_email_format') ?
                      'Invalid email format' : 'Required field'}
                  </Form.Control.Feedback>
                </Form.Group>
                {/* {!isEdit && (
                  <Form.Group className="mt-2">
                    <Form.Label>Password*</Form.Label>
                    <Form.Control
                      id="password"
                      type="password"
                      value={backofficeUserDetail.password}
                      onChange={backofficeUserDetailOnChange}
                      isInvalid={controlInputs('backofficeUser_detail_password')}
                     
                    />
                  </Form.Group>
                )} */}
              </Col>
              <Col md="6">
                {isEdit && (
                  <Form.Group className="mt-2">
                    <Form.Label>Is active?</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        className="me-3"
                        id="is_active"
                        type="radio"
                        label="True"
                        checked={backofficeUserDetail.is_active === "True"}
                        onChange={(e) => {
                          console.log("Event object:", e);
                          backofficeUserDetailOnChange(e, "True");
                        }}                        
                      />
                      <Form.Check
                        id="is_active"
                        type="radio"
                        label="False"
                        checked={backofficeUserDetail.is_active !== "True"}
                        onChange={(e) => {
                          console.log("Event object:", e);
                          backofficeUserDetailOnChange(e, "False");
                        }}
                        
                      />
                    </div>
                  </Form.Group>
                )}
                <Form.Group className="mt-2">
                  <Form.Label>Is superuser?</Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      className="me-3"
                      id="is_superuser"
                      type="radio"
                      label="True"
                      checked={backofficeUserDetail.is_superuser}
                      onChange={(e) => backofficeUserDetailOnChange(e, true)}
                    />
                    <Form.Check
                      id="is_superuser"
                      type="radio"
                      label="False"
                      checked={!backofficeUserDetail.is_superuser}
                      onChange={(e) => backofficeUserDetailOnChange(e, false)}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mt-2">
                  <Form.Label>Is developer?</Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      className="me-3"
                      id="is_developer"
                      type="radio"
                      label="True"
                      checked={backofficeUserDetail.is_developer}
                      onChange={(e) => backofficeUserDetailOnChange(e, true)}
                    />
                    <Form.Check
                      id="is_developer"
                      type="radio"
                      label="False"
                      checked={!backofficeUserDetail.is_developer}
                      onChange={(e) => backofficeUserDetailOnChange(e, false)}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="text-end">
        {requiredErrors.length > 0 ? (
          <div className="text-danger mt-2">*Required fields must be filled.</div>
        ) : (
          <></>
        )}
        {isEdit && user.is_superuser &&(
          <Button className="mt-2" onClick={updateBackofficeUser}>
            Update Backoffice User
          </Button>
        )}
        {!isEdit && user.is_superuser &&(
          <Button className="mt-2" onClick={createBackofficeUser}>
            Create Backoffice User
          </Button>
        )}
      </div>
    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    backofficeUser: state.backofficeUserReducer.backofficeUser,
    // token: state.tokenReducer.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createBackofficeUserRequest: (payload) => {
      dispatch(createBackofficeUserActionRequest(payload));
    },
    updateBackofficeUserRequest: (payload) => {
      dispatch(updateBackofficeUserActionRequest(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurXBackofficeUserDetailInputs);


import { useEffect, useState } from 'react';
import { Badge, Pagination, Table } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import './MercurXLaunchpadUserTable.scss';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function MercurXLaunchpadUserTable(props) {

  const { launchpadUsers, filteredLaunchpadUsers } = props;
  const history = useHistory();
  const maxRowCountPerPage = 10;
  const maxShowingPage = 5;
  const [sourceData, setSourceData] = useState(filteredLaunchpadUsers ?
    filteredLaunchpadUsers : launchpadUsers);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedLaunchpadUsers, setRenderedLaunchpadUsers] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] =
    useState(maxShowingPage);
  useEffect(() => {
    setSourceData(filteredLaunchpadUsers ? filteredLaunchpadUsers : launchpadUsers);
  }, [launchpadUsers, filteredLaunchpadUsers]);
  useEffect(() => {
    if (sourceData) {
      setCurrentPage(1);
      const tempPages = [];
      for (let index = 0; index < Math.ceil(sourceData.length / maxRowCountPerPage); index++) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [sourceData]);
  useEffect(() => {
    if (sourceData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = sourceData?.slice(firstIndex, lastIndex);
      setRenderedLaunchpadUsers([...tempRendered]);
    }
  }, [currentPage, sourceData]);
  function scrollToLaunchpadUsersContainer() {
    const launchpadUsersContainer = document.getElementById('launchpadUsers-container');
    if (launchpadUsersContainer) {
      launchpadUsersContainer.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };
  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToLaunchpadUsersContainer();
  };
  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToLaunchpadUsersContainer();
  };
  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToLaunchpadUsersContainer();
    }
  };
  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToLaunchpadUsersContainer();
    }
  };
  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToLaunchpadUsersContainer();
  };

  console.log(renderedLaunchpadUsers)
  return (
    <>
      <Table className="launchpadUsers-table-design" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Kyc Status</th>
            <th>2FA Status</th>
            <th>Email Verify</th>
            <th>Active</th>
          </tr>
        </thead>
        {renderedLaunchpadUsers?.length ? (
          <tbody>
            {renderedLaunchpadUsers.map((item, index) => {
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr className="text-t-head-color">
                    <td>{index + 1}</td>
                    <td>{item.full_name}</td>
                    <td>{item.email}</td>
                    <td>
                      { item.kyc.length === 0 ||
                        item.kyc[0]?.kyc_status === "false" ||
                        item.kyc[0]?.kyc_status === "Cancelled" ? (
                          <Badge bg="danger">False</Badge>
                        ) : item.kyc[0]?.kyc_status === "Approved" ? (
                          <Badge bg="success">Approved</Badge>
                        ) : (
                          <Badge bg="warning">In Progress</Badge>
                        )}
                    </td>
                    <td>
                      {item.two_factor_auth ? (
                        <Badge bg="success">True</Badge>
                      ) : (
                        <Badge bg="danger">False</Badge>
                      )}
                    </td>
                    <td>
                      {item.is_active === "True" ? (
                        <Badge bg="success">True</Badge>
                      ) : (
                        <Badge bg="danger">False</Badge>
                      )}
                    </td>
                    <td>
                      {item.is_active === "True" ? (
                        <Badge bg="success">True</Badge>
                      ) : (
                        <Badge bg="danger">False</Badge>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        ) : null}
      </Table>
      {!renderedLaunchpadUsers?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No launchpad user found according to search results.
        </div>
      )}
      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    launchpadUsers: state.launchpadUserReducer.launchpadUsers,
    filteredLaunchpadUsers: state.launchpadUserReducer.filteredLaunchpadUsers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXLaunchpadUserTable);



import { useEffect, useState } from 'react';
import { Badge, Pagination, Table } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import './MercurXLockTable.scss';
import { connect } from 'react-redux';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function MercurXLockTable(props) {

  const { locks, filteredLocks } = props;
  const history = useHistory();
  const maxRowCountPerPage = 10;
  const maxShowingPage = 5;
  const [sourceData, setSourceData] = useState(filteredLocks ?
    filteredLocks : locks);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedLocks, setRenderedLocks] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] =
    useState(maxShowingPage);
  useEffect(() => {
    setSourceData(filteredLocks ? filteredLocks : locks);
  }, [locks, filteredLocks]);
  useEffect(() => {
    if (sourceData) {
      setCurrentPage(1);
      const tempPages = [];
      for (let index = 0; index < Math.ceil(sourceData.length / maxRowCountPerPage); index++) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [sourceData]);
  useEffect(() => {
    if (sourceData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = sourceData?.slice(firstIndex, lastIndex);
      setRenderedLocks([...tempRendered]);
    }
  }, [currentPage, sourceData]);
  function scrollToLocksContainer() {
    const locksContainer = document.getElementById('locks-container');
    if (locksContainer) {
      locksContainer.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };
  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToLocksContainer();
  };
  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToLocksContainer();
  };
  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToLocksContainer();
    }
  };
  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToLocksContainer();
    }
  };
  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToLocksContainer();
  };

  function formatDate(dateString) {
    const options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short',
    };
    const formattedDate = new Intl.DateTimeFormat('default', options).format(new Date(dateString));
    return formattedDate;
  }
  function formatDateStartEnd(dateString) {
    const originalDate = new Date(dateString);
    const adjustedDate = new Date(originalDate.getTime() - 3 * 60 * 60 * 1000);
    const options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    };
    const formattedDate = new Intl.DateTimeFormat('default', options).format(adjustedDate);
  
    return formattedDate;
  }
  const formatDateStatus = (originalDate) => {

    const dateObj = new Date(originalDate);
    const newdateObj = new Date(
      dateObj.getUTCFullYear(),
      dateObj.getUTCMonth(),
      dateObj.getUTCDate(),
      dateObj.getUTCHours(),
      dateObj.getUTCMinutes(),
      dateObj.getUTCSeconds(),
    );
    return newdateObj;
  };
  function calculateFormat(x) {

    const formatBalance = (Math.floor((Number(x) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  return (
    <>
      <Table className="locks-table-design" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Token Symbol</th>
            <th>Value</th>
            <th>Reward</th>
            <th>Month</th>
            <th>Wallet Address</th>
            <th>Txn Hash</th>
            <th>Start Time</th>
            <th>End time</th>
            <th>Update Date</th>
            <th>Status</th>
          </tr>
        </thead>
        {renderedLocks?.length ? (
          <tbody>
            {renderedLocks.map((item, index) => {
              const dateObj = formatDateStatus(item.finish_lock_time);
              const date1 = new Date();
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr className="text-t-head-color">
                    <td>{index + 1}</td>
                    <td>{item.symbol}</td>
                    <td>{calculateFormat(item.value)}</td>
                    <td>{(parseFloat(item.reward) - (parseFloat(item.value) / 10000))
                   .toLocaleString('en-US', { minimumFractionDigits: 1, 
                   maximumFractionDigits: 4 })
                   .replace('.', ',')}</td>
                    <td>{item.month}</td>
                    <td>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-${index}`}>{
                          item.wallet}</Tooltip>}
                      >
                        <div>{`${item.wallet.slice(0, 3)}...${
                          item.wallet.slice(-3)}`}</div>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-${index}`}>{
                          item.tx_hash}</Tooltip>}
                      >
                        <div>{`${item.tx_hash.slice(0, 3)}...${
                          item.tx_hash.slice(-3)}`}</div>
                      </OverlayTrigger>
                    </td>
                    <td>{formatDateStartEnd(item.start_lock_time)+' GMT+3'}</td>
                    <td>{formatDateStartEnd(item.finish_lock_time)+' GMT+3'}</td>
                    <td>{formatDate(item.udate)}</td>
                    <td>
                      {item.claim_status ? (

                        dateObj < date1.getTime() ? (
                          <Badge bg="primary">Claim</Badge>
                        ): (
                          <Badge bg="primary">Locked</Badge>
                        )
                      ) : (
                        <Badge bg="primary">Done</Badge>
                      )}
                    </td>

                    <td>
                      <p className="border-bottom-detail mt-1">
                        <a
                          href={'https://testnet.bscscan.com/tx/' + item.tx_hash}
                          target="_blank"
                          className="link"
                        >
                          <MercurXIcons
                            name="BsFillInfoSquareFill"
                            size={18}
                            color={mainColors['primary']}
                          />
                        </a>
                      </p>
                    </td>

                  </tr>
                );
              }
            })}
          </tbody>
        ) : null}
      </Table>
      {!renderedLocks?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No lock found according to search results.
        </div>
      )}
      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    locks: state.lockReducer.locks,
    filteredLocks: state.lockReducer.filteredLocks
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXLockTable);


import * as types from './bnbActionTypes';

export const getBNB = (payload) => {
  return {
    type: types.GET_BNB_REQUEST,
    payload,
  };
};

export const getBNBData = (payload) => {
  return {
    type: types.GET_BNB_DATA,
    payload,
  };
};

export const getBNBError = (payload) => {
  return {
    type: types.GET_BNB_ERROR,
    payload,
  };
};
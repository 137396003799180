/* eslint-disable max-len */
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import './MercurxContractPresaleDetailInputs.scss'
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { abiRequestAction } from '../../store/abi/abiActions';
import wallet from '../../helpers/wallet';
import Swal from 'sweetalert2';
import { mainColors } from '../../helpers/colors';
import { getBNB } from '../../store/bnb/bnbActions';
import { setAlertAction } from '../../store/alert/alertActions';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { setLoadingFullAction } from '../../store/loading/loadingActions';
import { Badge } from 'react-bootstrap';
import { transactionRequest } from '../../store/bo_transaction/bo_transactionActions';
const MercurxContractPresaleDetailInputs = ({ ...props }) => {
  const [selectedUnit, setSelectedUnit] = useState('WEI');

  const handleUnitChange = (unit) => {
    setSelectedUnit(unit);
  };
  const {
    project,
    abiHistoryRequest,
    abiHistory,
    getBNBRequest,
    bnbData,
    setAlert,
    setLoadingFull,
    isLoading,
    transactionRequest,
    accounts
  } = props;

  const { utils } = require('ethers');
  const [results, setResults] = useState(null);
  const resultList = {};
  const [resultsLists, setResultsLists] = useState({});
  const [divtransactionHash, setDivTransactionHash] = useState("");

  //buyTokens
  const [amountBeforeDecimalBuy, setAmountBeforeDecimalBuy] = useState('1');
  const [invalidAmountBuy, setInvalidAmountBuy] = useState(false);
  const [amountAfterDecimalBuy, setAmountAfterDecimalBuy] = useState('000000000')

  const handleBuyInputBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalBuy(inputValue);
      setInvalidAmountBuy(false);
    } else {
      setInvalidAmountBuy(true);
    }
  }
  const handleBuyInputAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 9)) {
      setAmountAfterDecimalBuy(inputValue);
    }
  };

  //maxBuy
  const [amountBeforeDecimalMaxBuy, setAmountBeforeDecimalMaxBuy] = useState('1');
  const [invalidAmountMaxBuy, setInvalidAmountMaxBuy] = useState(false);
  const [amountAfterDecimalMaxBuy, setAmountAfterDecimalMaxBuy] = useState('000000000')

  const handleMaxBuyInputBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalMaxBuy(inputValue);
      setInvalidAmountMaxBuy(false);
    } else {
      setInvalidAmountMaxBuy(true);
    }
  }
  const handleMaxBuyInputAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 9)) {
      setAmountAfterDecimalMaxBuy(inputValue);
    }
  };

  //minBuy
  const [amountBeforeDecimalMinBuy, setAmountBeforeDecimalMinBuy] = useState('1');
  const [invalidAmountMinBuy, setInvalidAmountMinBuy] = useState(false);
  const [amountAfterDecimalMinBuy, setAmountAfterDecimalMinBuy] = useState('000000000')

  const handleMinBuyInputBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalMinBuy(inputValue);
      setInvalidAmountMinBuy(false);
    } else {
      setInvalidAmountMinBuy(true);
    }
  }
  const handleMinBuyInputAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 9)) {
      setAmountAfterDecimalMinBuy(inputValue);
    }
  };

  //transferOwnership
  const [spender, setSpender] = useState('');
  const [invalidAddress, setInvalidAddress] = useState(false);
  const handleTransferOwnershipInputChange = (event) => {
    const inputValue = event.target.value;
    setSpender(inputValue);
    setInvalidAddress(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };


  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setAlert({
          title: 'Success!',
          text: 'Text copied to clipboard',
          variant: 'success',
          outTimeMS: 2000,
        });
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Unable to copy text to clipboard', err);
      });
  };

  const handleAbi = () => {
    abiHistoryRequest();
    getBNBRequest();
  };
  const [txs, setTxs] = useState([]);
  useEffect(() => {
    handleAbi();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      console.log(resultsLists)
    };

    fetchData();
  }, []);  // useEffect'in sadece ilk render'da çalışması için dependency array'i boş bırakıldı.
  console.log(divtransactionHash);
  console.log(resultsLists);


  const closeModal = () => {
    abiHistoryRequest(false);
  };
  let val = 0.0;
  const runContract = async (functionType, isCall = false, params = "", vals = "") => {
    console.log(functionType);
    let transaction;
    let transactionHashPresale;
    setTxs([]);
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        const signerAddress = await signer.getAddress();
        const project_id = 1;
        const project_name = "MERCURX_PRESALE";
        const user_public_address = signerAddress;
        const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
        const transaction_time = new Date();
        const web3 = new Web3(window.ethereum);

        await wallet.controlAndSwitchOrAddNetwork();
        await window.ethereum.enable();
        const mercurx_presale = new web3.eth.Contract(
            abiHistory?.[0]?.['MERX_presale_abi'],
            '0xA3dEE80bf8b816049a5226b4689488f3ff369C66',
        );

        if (params == "" && vals == "") {
            if (isCall) {
                const transaction = await mercurx_presale.methods[functionType]().call();
                setResults(transaction);
                resultList[functionType] = transaction;
                setResultsLists(prevState => {
                    return {
                        ...prevState,
                        [functionType]: transaction,
                    };
                });
                setLoadingFull({ isLoading: false });
            } else {
                const transaction = await mercurx_presale.methods[functionType]().send({ from: signerAddress });
                console.log(transaction);
                const transaction_status = transaction.status;
                const transaction_hash = transaction.transactionHash;
                const token_count = parseInt(vals[0], 10);
                const action = functionType;
                const payload2 = {
                    action,
                    project_id,
                    project_name,
                    token_count,
                    user_public_address,
                    token_address,
                    transaction_hash,
                    transaction_time,
                    transaction_status,
                };
                transactionHashPresale = transaction["transactionHash"];
                setDivTransactionHash(transaction);
                console.log('1' + payload2);
                transactionRequest(payload2);
            }
        } else if (params == "" && vals != "") {
            val = parseFloat(bnbData?.price) * parseFloat(vals) / parseFloat("3");
            val = val.toFixed(4).toString();
            val = parseFloat(val.replace('.', ''), 10);
            let bnb = parseFloat(vals).toFixed(18);
            bnb = parseFloat(bnb.toString().replace('.', ''), 10);
            const transaction = await mercurx_presale.methods.buy(val).send({
                from: signerAddress,
                to: project?.token.presale_contract.contract_address,
                value: bnb,
            });
            transactionHashPresale = transaction["transactionHash"];
            setDivTransactionHash(transaction);
            const transaction_status = transaction.status;
            const transaction_hash = transaction.transactionHash;
            const token_count = params[params.length - 1];
            const action = functionType;
            const payload2 = {
                action,
                project_id,
                project_name,
                token_count,
                user_public_address,
                token_address,
                transaction_hash,
                transaction_time,
                transaction_status,
            };
            console.log('2' + payload2);
            console.log(payload2);
            transactionRequest(payload2);
        } else {
            const data = mercurx_presale.methods[functionType](...params).encodeABI();
            const transaction = await web3.eth.sendTransaction({
                from: signerAddress,
                to: '0xA3dEE80bf8b816049a5226b4689488f3ff369C66',
                data: data,
            });
            const transaction_status = transaction.status;
            const transaction_hash = transaction.transactionHash;
            const token_count = params[params.length - 1];
            const action = functionType;
            const payload2 = {
                action,
                project_id,
                project_name,
                token_count,
                user_public_address,
                token_address,
                transaction_hash,
                transaction_time,
                transaction_status,
            };
            transactionHashPresale = transaction["transactionHash"];
            setDivTransactionHash(transaction);
            console.log(payload2);
            console.log('params' + params[params.length - 1]);
            console.log('vals' + vals);
            console.log('vals parse' + parseInt(vals[0], 10));
            transactionRequest(payload2);
            console.log(transaction["transactionHash"]);
        }
        if (!isCall) {
            Swal.fire({
                icon: 'success',
                iconColor: mainColors.primary,
                text: 'Transaction succeed',
                confirmButtonColor: mainColors.primary,
                html: `<a href=https://testnet.bscscan.com/tx/${transactionHashPresale} target='_blank'> Check Detail Transaction !</a>`,
            }).then(closeModal);
            setLoadingFull({ isLoading: false });
        }
    } catch (err) {
        const receiptObject = err['receipt'];
        setDivTransactionHash(receiptObject);
        if (err?.receipt?.transaction["transactionHash"]) {
            Swal.fire({
                icon: 'error',
                iconColor: '#E40039',
                title: 'Transaction is Failed',
                confirmButtonColor: '#E40039',
                html: `<a href=https://testnet.bscscan.com/tx/${err.receipt.transactionHashPresale} target='_blank'> Check Detail Transaction !</a>`,
            }).then(closeModal);
            setLoadingFull({ isLoading: false });
        } else {
          if (err.message === "Internal JSON-RPC error.") {
            Swal.fire({
              icon: 'warning',
              iconColor: '#E40039',
              confirmButtonColor: '#E40039',
              text: 'Please check your wallet!',
            }).then(closeModal);
          } else {
            Swal.fire({
              icon: 'warning',
              iconColor: '#E40039',
              confirmButtonColor: '#E40039',
              text: err.message,
            }).then(closeModal);
          }
          
          setLoadingFull({ isLoading: false });
          
        }
        const transaction_status = false;
        const action = functionType;
        const project_id = 1;
        const project_name = 'MERCURX_PRESALE';
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        const signerAddress = await signer.getAddress();
        const user_public_address = signerAddress;
        const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
        const transaction_time = new Date();
        const transaction_hash = receiptObject?.transactionHash || '';
        const token_count = params[params.length - 1] ? params[params.length - 1] : parseInt(vals[0], 10);
        const payload2 = {
            action,
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,
        };
        transactionRequest(payload2);
    }
};


  return (
    <div>
      <div className="contract-detail">
        <Accordion className="mt-5 shadow" defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="text-fs-head-sm">Presale Contract</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="text-fs-head-sm">Deployed Contract Write</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col md="12">
                          {/* buyTokens */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">buyTokens</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>amount:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimalBuy"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalBuy}
                                onChange={handleBuyInputBeforeDecimalChange}
                                isInvalid={invalidAmountBuy}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimal"
                                type="text"
                                value={amountAfterDecimalBuy}
                                onChange={handleBuyInputAfterDecimalChange}
                                isInvalid={invalidAmountBuy}
                                style={{ maxWidth: '120px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value 1
                              </Form.Control.Feedback>
                            </div>

                          </Form.Group>
                          <Button className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalBuy}${amountAfterDecimalBuy}`;
                              const paddedAmount = fullAmount.padEnd(10, '0');
                              runContract("buyTokens", false, [paddedAmount])
                            }
                            }
                            // onClick={() => runContract("setMax", false, [`${amountBeforeDecimalMaxBuy}${amountAfterDecimalMaxBuy}`])}
                            disabled={!accounts?.[0] || invalidAmountBuy || !amountBeforeDecimalBuy}
                          >
                            transact
                          </Button>
                          {/* setMax */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">setMax</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>maxBuyCount:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimalMaxBuy"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalMaxBuy}
                                onChange={handleMaxBuyInputBeforeDecimalChange}
                                isInvalid={invalidAmountMaxBuy}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimal"
                                type="text"
                                value={amountAfterDecimalMaxBuy}
                                onChange={handleMaxBuyInputAfterDecimalChange}
                                isInvalid={invalidAmountMaxBuy}
                                style={{ maxWidth: '120px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value 1
                              </Form.Control.Feedback>
                            </div>

                          </Form.Group>
                          <Button className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalMaxBuy}${amountAfterDecimalMaxBuy}`;
                              const paddedAmount = fullAmount.padEnd(10, '0');
                              runContract("setMax", false, [paddedAmount])
                            }
                            }
                            // onClick={() => runContract("setMax", false, [`${amountBeforeDecimalMaxBuy}${amountAfterDecimalMaxBuy}`])}
                            disabled={!accounts?.[0] || invalidAmountMaxBuy || !amountBeforeDecimalMaxBuy}
                          >
                            transact
                          </Button>

                          {/* setMin */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">setMin</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>minBuyCount:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimalMinBuy"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalMinBuy}
                                onChange={handleMinBuyInputBeforeDecimalChange}
                                isInvalid={invalidAmountMinBuy}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimal"
                                type="text"
                                value={amountAfterDecimalMinBuy}
                                onChange={handleMinBuyInputAfterDecimalChange}
                                isInvalid={invalidAmountMinBuy}
                                style={{ maxWidth: '120px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value 1
                              </Form.Control.Feedback>
                            </div>

                          </Form.Group>
                          <Button className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalMinBuy}${amountAfterDecimalMinBuy}`;
                              const paddedAmount = fullAmount.padEnd(10, '0');
                              runContract("setMin", false, [paddedAmount])
                            }
                            }
                            // onClick={() => runContract("setMin", false, [`${amountBeforeDecimalMinBuy}${amountAfterDecimalMinBuy}`])}
                            disabled={!accounts?.[0] || invalidAmountMinBuy || !amountBeforeDecimalMinBuy}
                          >
                            transact
                          </Button>

                          {/* pause */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">pause</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Button className="mt-4 custom-button button-size"
                            onClick={() => {
                              runContract("pause", false)
                            }
                            }
                            disabled={!accounts?.[0]}
                          >
                            pause
                          </Button>
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">unpause</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Button className="mt-4 custom-button button-size"
                            onClick={() => {
                              runContract("unpause", false)
                            }
                            }
                            // onClick={() => runContract("setMin", false, [`${amountBeforeDecimalMinBuy}${amountAfterDecimalMinBuy}`])}
                            disabled={!accounts?.[0]}
                          >
                            unpause
                          </Button>
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">renounceOwnership</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Button className="mt-4 custom-button button-size"
                            onClick={() => {
                              runContract("renounceOwnership", false)
                            }
                            }
                            // onClick={() => runContract("setMin", false, [`${amountBeforeDecimalMinBuy}${amountAfterDecimalMinBuy}`])}
                            disabled={!accounts?.[0]}
                          >
                            renounceOwn...
                          </Button>
                        </Col>


                      </Row>

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion className="mt-3 shadow">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="text-fs-head-sm">Deployed Contract Read</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Button
                            onClick={() => runContract("max", true)}
                            className="me-2 button-size">max</Button>
                          {resultsLists["max"] && <div className="ml-2">{`uint256: ${resultsLists["max"]}`}</div>}
                        </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Button
                            onClick={() => runContract("min", true)}
                            className="me-2 button-size">min</Button>
                          {resultsLists["min"] && <div className="ml-2">{`uint256: ${resultsLists["min"]}`}</div>}
                        </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Button
                            onClick={() => runContract("owner", true)}
                            className="me-2 button-size">owner</Button>
                          {resultsLists["owner"] && (
                            <div className="ml-2 d-flex align-items-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-owner`}>{resultsLists["owner"]}</Tooltip>}
                              >
                                <div>
                                  {`address: ${resultsLists["owner"].substring(0, 3)}...${resultsLists["owner"].slice(-3)}`}
                                </div>
                              </OverlayTrigger>
                              <div className="ms-2">
                                <Button variant="outline-secondary" size="sm" onClick={() => handleCopyToClipboard(resultsLists["owner"])}
                                  style={{ background: '#f0f0f0' }}>
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </div>
                          )} </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Button
                            onClick={() => runContract("paused", true)}
                            className="me-2 button-size">paused</Button>
                          {resultsLists["paused"] !== undefined && (
                            <div className="ml-2">{`bool: ${resultsLists["paused"]}`}</div>
                          )}
                        </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Button
                            onClick={() => runContract("token", true)}
                            className="me-2 button-size">token</Button>
                          {resultsLists["token"] !== undefined && (
                            <div className="ml-2">{`address: ${resultsLists["token"]}`}</div>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
                <Col md="6">
                  {/* İkinci Accordion */}
                  <Accordion className="shadow">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="text-fs-head-sm">Transaction Details</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {divtransactionHash && (
                          <div className="transaction-details" style={{ overflowWrap: 'break-word' }}>
                            <Form.Group>
                              <Form.Label>
                                Transaction Status: {divtransactionHash.status ? (
                                  <Badge bg="success">Success</Badge>
                                ) : (
                                  <Badge bg="danger">Fail</Badge>
                                )}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group >
                              <Form.Label className='mt-0'>
                                Transaction Hash:
                              </Form.Label>
                              <div >
                                {divtransactionHash.transactionHash}
                              </div>
                              <div className="ms-0">
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  onClick={() => handleCopyToClipboard(divtransactionHash.transactionHash)}
                                  style={{ background: '#f0f0f0' }}
                                >
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Block Hash:
                              </Form.Label>
                              <div>
                                {divtransactionHash.blockHash}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Cumulative Gas Used:
                              </Form.Label>
                              <div>
                                {divtransactionHash.cumulativeGasUsed}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Effective Gas Price:
                              </Form.Label>
                              <div>
                                {divtransactionHash.effectiveGasPrice}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                from:
                              </Form.Label>
                              <div>
                                {divtransactionHash.from}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                to:
                              </Form.Label>
                              <div>
                                {divtransactionHash.to}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Gas Used:
                              </Form.Label>
                              <div>
                                {divtransactionHash.gasUsed}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Transaction Index:
                              </Form.Label>
                              <div>
                                {divtransactionHash.transactionIndex}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Type:
                              </Form.Label>
                              <div>
                                {divtransactionHash.type}
                              </div>
                            </Form.Group>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>

        </Accordion>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    abiHistory: state.abiReducer.abiHistory,
    bnbData: state.bnbReducer.bnb,
    isLoading: state.loadingReducer.isLoading,
    accounts: state.walletReducer.accounts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    getBNBRequest: (payload) => {
      dispatch(getBNB(payload));
    },
    setAlert: (payload) => {
      dispatch(setAlertAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurxContractPresaleDetailInputs);

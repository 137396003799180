import { connect } from 'react-redux';
import { useEffect } from 'react';
import './PublicLayout.scss';
import mercurxLogo from '../../assets/img/logo/mercurx-logo.png';

function PublicLayout({ ...props }) {
  const { children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="public-layout-container">
      <div className="mb-2 d-inline-block">
        <img alt="mercurx-icon" src={mercurxLogo} height={45} />
        <span className="text-fs-head-sm">Backoffice</span>
      </div>
      {children}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout);

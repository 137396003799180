import React from 'react';
import './EditToken.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
// eslint-disable-next-line max-len
import MercurXProjectDetailInputs from '../../components/MercurXProjectDetailInputs/MercurXProjectDetailInputs';
import { useHistory, useLocation } from 'react-router';

function EditToken({ ...props }) {
  const history = useHistory();
  const location = useLocation();
  const token = location.state?.token;

  if (!token) {
    history.goBack();
  }

  if (token) {
    return (
      <div>
        <MercurXBackButton buttonText="Back to token list" />
        <div className="text-fs-head-sm">Edit token</div>
        <MercurXProjectDetailInputs isEdit={true} showProject={false} showToken={true}
         showSocial={false} showContract={true}/>
      </div>
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditToken);

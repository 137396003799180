export const GET_BACKOFFICETRANSACTIONS_REQUEST = 'GET_BACKOFFICETRANSACTIONS_REQUEST';
export const GET_BACKOFFICETRANSACTIONS_DATA = 'GET_BACKOFFICETRANSACTIONS_DATA';
export const GET_BACKOFFICETRANSACTIONS_ERROR = 'GET_BACKOFFICETRANSACTIONS_ERROR';

export const UPDATE_QUICK_FILTER = 'UPDATE_QUICK_FILTER';
export const FILTER_BACKOFFICETRANSACTIONS = 'FILTER_BACKOFFICETRANSACTIONS';
export const SET_BACKOFFICETRANSACTIONS_SORT_DATA = 'SET_BACKOFFICETRANSACTIONS_SORT_DATA';
export const SORT_BACKOFFICETRANSACTIONS = 'SORT_BACKOFFICETRANSACTIONS';

export const TRANSACTION_REQUEST = 'TRANSACTION_REQUEST';
export const TRANSACTION_DATA = 'TRANSACTION_DATA';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
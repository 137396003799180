import { takeEvery, put, all, call } from 'redux-saga/effects';
import * as types from './stakeActionTypes';
import * as actions from './stakeActions';
import * as endpoints from '../../services/endpoints';


// function* getStakeSaga(action) {
//   try {
    
//     const { data } = yield call(endpoints.getStakes);
//     console.log(data)
//     yield put(actions.getStakesActionData(data));
//     // yield put (projectActions.getProjectByIDData(data))
//   } catch (e) {
//     console.log(e)
//     yield put(actions.getStakesActionError(e));
//   }
// }


function* getStakeSaga(action) {
  try {
    const { data } = yield call(endpoints.getStakes);
    yield put(actions.getStakesActionData(data));
  } catch (e) {
    console.log(e)
    yield put(actions.getStakesActionError(e));
  }
}

function* watchGetStake() {
  yield takeEvery(types.GET_STAKES_REQUEST, getStakeSaga);
}


export function* stakeSaga() {
  yield all([
    watchGetStake(),
   
  ]);
}

/* eslint-disable max-len */
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import './MercurxContractStakeDetailInputs.scss';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { abiRequestAction } from '../../store/abi/abiActions';
import wallet from '../../helpers/wallet';
import Swal from 'sweetalert2';
import { mainColors } from '../../helpers/colors';
import { getBNB } from '../../store/bnb/bnbActions';
import { setAlertAction } from '../../store/alert/alertActions';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { setLoadingFullAction } from '../../store/loading/loadingActions';
import { Badge } from 'react-bootstrap';
import { transactionRequest } from '../../store/bo_transaction/bo_transactionActions';
const MercurxContractStakeDetailInputs = ({ ...props }) => {
  const [selectedUnit, setSelectedUnit] = useState('WEI');

  const handleUnitChange = (unit) => {
    setSelectedUnit(unit);
  };
  const {
    project,
    abiHistoryRequest,
    abiHistory,
    getBNBRequest,
    bnbData,
    setAlert,
    setLoadingFull,
    isLoading,
    transactionRequest,
    accounts
  } = props;

  const [stake, setClaim] = useState('');
  const [isStaker, setCompletedUniqueAddresses] = useState('');
  const [stakedBalance, setCompletedStakedBalance] = useState('');
  const [uniqueAddresses, setUniqueAddresses] = useState('');
  const [rewardRates, setRewardRates] = useState('');
  const [spenderStakeedInfos, setSpenderStakeedInfos] = useState('');
  const [valueStakeedInfos, setValueStakeedInfos] = useState('');
  const [amountStakeTokens, setAmountStakeTokens] = useState('');
  const [durationStakeTokens, setDurationStakeTokens] = useState('');
  const [transferNewOwnership, setTransferNewOwnership] = useState('');
  const [updateDuration, setUpdateDuration] = useState('');
  const [newRewardRate, setNewRewardRate] = useState('');
  const [recipient, setRecipient] = useState('');
  const [amountWithDrawToken, setAmountWithDrawToken] = useState('');
  const [results, setResults] = useState(null);
  const resultList = {};
  const [resultsLists, setResultsLists] = useState({});
  const [divtransactionHash, setDivTransactionHash] = useState('');
  const [showRewardRates, setShowRewardRates] = useState(false);
  const [showUniqueAddresses, setShowUniqueAddresses] = useState(false);
  const [showStakeedInfos, setShowStakeedInfos] = useState(false);
  const [showCompletedUnique, setShowCompletedUnique] = useState(false);
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setAlert({
          title: 'Success!',
          text: 'Text copied to clipboard',
          variant: 'success',
          outTimeMS: 2000,
        });
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Unable to copy text to clipboard', err);
      });
  };
  const handleAbi = () => {
    abiHistoryRequest();
    getBNBRequest();
  };
  const [txs, setTxs] = useState([]);
  useEffect(() => {
    handleAbi();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      console.log(resultsLists);
    };

    fetchData();
  }, []); // useEffect'in sadece ilk render'da çalışması için dependency array'i boş bırakıldı.

  console.log(resultsLists);
  const handleCompletedUniqueClick = () => {
    setShowCompletedUnique(!showCompletedUnique);
  };
  const handleUniqueAddressesClick = () => {
    setShowUniqueAddresses(!showUniqueAddresses);
  };
  const handleRewardRatesClick = () => {
    setShowRewardRates(!showRewardRates);
  };
  const handleStakeedInfosClick = () => {
    setShowStakeedInfos(!showStakeedInfos);
  };
  function handleCompletedUniqueAddressesInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'isStaker') {
      console.log(`isStaker: ${inputValue}`);
      setCompletedUniqueAddresses(inputValue);
    }
  }
  function handleCompletedStakedBalanceInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'stakedBalance') {
      console.log(`stakedBalance: ${inputValue}`);
      setCompletedStakedBalance(inputValue);
    }
  }
  function handleUniqueAddressesInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'uniqueAddresses') {
      console.log(`uniqueAddresses: ${inputValue}`);
      setUniqueAddresses(inputValue);
    }
  }
  function handleRewardRatesInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'rewardRates') {
      console.log(`rewardRates: ${inputValue}`);
      setRewardRates(inputValue);
    }
  }
  function handleStakeedInfosInputChange(event, inputType) {
    const inputValue = event.target.value;
    if (inputType === 'spenderStakeedInfos') {
      console.log(`spenderStakeedInfos: ${inputValue}`);
      setSpenderStakeedInfos(inputValue);
    } else if (inputType === 'valueStakeedInfos') {
      console.log(`valueStakeedInfos: ${inputValue}`);
      setValueStakeedInfos(inputValue);
    }
  }
  //stakeTokens
  const [amountStakeTokensDuration, setAmountStakeTokensDuration] = useState('');
  const [invalidAmountStakeTokensDuration, setInvalidAmountStakeTokensDuration] = useState(false);
  const handleStakeTokensInputDurationChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(0|[1-3])$/;


    if (regex.test(inputValue) || inputValue === '') {
      const amount = inputValue === '' ? '' : parseInt(inputValue, 10);
      setAmountStakeTokensDuration(amount);
      setInvalidAmountStakeTokensDuration(false);
    } else {
      setInvalidAmountStakeTokensDuration(true);
    }
  }
  const [amountBeforeDecimalStakeTokens, setAmountBeforeDecimalStakeTokens] = useState('1');
  const [invalidAmountStakeTokens, setInvalidAmountStakeTokens] = useState(false);
  const [amountAfterDecimalStakeTokens, setAmountAfterDecimalStakeTokens] = useState('000000000')

  const handleStakeTokensInputBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalStakeTokens(inputValue);
      setInvalidAmountStakeTokens(false);
    } else {
      setInvalidAmountStakeTokens(true);
    }
  }
  const handleStakeTokensInputAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 4)) {
      setAmountAfterDecimalStakeTokens(inputValue);
    }
  };
  //Claim
  const [amountIndex, setAmountIndex] = useState('');
  const [invalidAmountIndex, setInvalidAmountIndex] = useState(false);
  const handleClaimInputChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(0|[1-9]\d*)$/;
    // TO DO
    // Örneğin: const regex = /^(0|[1-9]\d{0,8})$/; MAKSIMUM AYARLANACAK 

    if (regex.test(inputValue) || inputValue === '') {
      const amount = inputValue === '' ? '' : parseInt(inputValue, 10);
      setAmountIndex(amount);
      setInvalidAmountIndex(false);
    } else {
      setInvalidAmountIndex(true);
    }
  }

  //Unstake
  const [amountUnstakeIndex, setAmountUnstakeIndex] = useState('');
  const [invalidAmountUnstakeIndex, setInvalidAmountUnstakeIndex] = useState(false);
  const handleUnstakeInputChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(0|[1-9]\d*)$/;
    // TO DO
    // Örneğin: const regex = /^(0|[1-9]\d{0,8})$/; MAKSIMUM AYARLANACAK 

    if (regex.test(inputValue) || inputValue === '') {
      const amount = inputValue === '' ? '' : parseInt(inputValue, 10);
      setAmountUnstakeIndex(amount);
      setInvalidAmountUnstakeIndex(false);
    } else {
      setInvalidAmountUnstakeIndex(true);
    }
  }
  //transferOwnership
  const [spender, setSpender] = useState('');
  const [invalidAddress, setInvalidAddress] = useState(false);
  const handleTransferOwnershipInputChange = (event) => {
    const inputValue = event.target.value;
    setSpender(inputValue);
    setInvalidAddress(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };

  //UpdateReward
  const [amountUpdateRewardDuration, setAmountUpdateRewardDuration] = useState('');
  const [invalidAmountUpdateRewardDuration, setInvalidAmountUpdateRewardDuration] = useState(false);
  const handleUpdateRewardDurationInputChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(0|[1-3])$/;


    if (regex.test(inputValue) || inputValue === '') {
      const amount = inputValue === '' ? '' : parseInt(inputValue, 10);
      setAmountUpdateRewardDuration(amount);
      setInvalidAmountUpdateRewardDuration(false);
    } else {
      setInvalidAmountUpdateRewardDuration(true);
    }
  }
  const [amountBeforeDecimalUpdateReward, setAmountBeforeDecimalUpdateReward] = useState('1');
  const [invalidAmountUpdateReward, setInvalidAmountUpdateReward] = useState(false);
  const [amountAfterDecimalUpdateReward, setAmountAfterDecimalUpdateReward] = useState('000000000')

  const handleUpdateRewardInputBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalUpdateReward(inputValue);
      setInvalidAmountUpdateReward(false);
    } else {
      setInvalidAmountUpdateReward(true);
    }
  }
  const handleUpdateRewardInputAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 4)) {
      setAmountAfterDecimalUpdateReward(inputValue);
    }
  };

  //WithDrawToken

  const [spenderWithDrawToken, setSpenderWithDrawToken] = useState('');
  const [invalidAddressWithDrawToken, setInvalidAddressWithDrawToken] = useState(false);
  const handleWithDrawTokenAddressInputChange = (event) => {
    const inputValue = event.target.value;
    setSpenderWithDrawToken(inputValue);
    setInvalidAddressWithDrawToken(!/^0x[0-9a-fA-F]{40}$/.test(inputValue));
  };

  const [amountBeforeDecimalWithDrawToken, setAmountBeforeDecimalWithDrawToken] = useState('1');
  const [invalidAmountWithDrawToken, setInvalidAmountWithDrawToken] = useState(false);
  const [amountAfterDecimalWithDrawToken, setAmountAfterDecimalWithDrawToken] = useState('000000000')

  const handleWithDrawTokenInputBeforeDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[1-9]\d*$/; // Minimum 1, sadece sayılar
    if (regex.test(inputValue) || inputValue === '') {
      setAmountBeforeDecimalWithDrawToken(inputValue);
      setInvalidAmountWithDrawToken(false);
    } else {
      setInvalidAmountWithDrawToken(true);
    }
  }
  const handleWithDrawTokenInputAfterDecimalChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d{0,9}$/; // Sadece sayılar, en fazla 9 basamak
    if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 4)) {
      setAmountAfterDecimalWithDrawToken(inputValue);
    }
  };

  const closeModal = () => {
    abiHistoryRequest(false);
  };
  let val = 0.0;
  const runContract = async (functionType, isCall = false, params = '', vals = '') => {
    console.log(functionType);
    let transaction;
    let transactionHashStake;
    setTxs([]);
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const project_id = 1;
      const project_name = "MERCURX_STAKE";
      const user_public_address = signerAddress;
      const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
      const transaction_time = new Date();
      const web3 = new Web3(window.ethereum);

      await wallet.controlAndSwitchOrAddNetwork();
      await window.ethereum.enable();
      const mercurx_stake = new web3.eth.Contract(
        abiHistory?.[0]?.['MERX_staking_abi'],
        '0x64E1782Ca78203AA43157518C03a13B396c1a998',
      );
      const contractMethod = mercurx_stake.methods[functionType];
      const data = contractMethod(...params).encodeABI();
      if (isCall) {
        if (params == '' && vals == '') {
          const transaction = await mercurx_stake.methods[functionType]().call();
          setResults(transaction);
          resultList[functionType] = transaction;
          setResultsLists((prevState) => {
            return {
              ...prevState,
              [functionType]: transaction,
            };
          });
          setLoadingFull({ isLoading: false });
        } else if (params != '' && vals == '') {
          const transaction = await contractMethod(...params).call();
          setResults(transaction);
          resultList[functionType] = transaction;
          setResultsLists((prevState) => {
            return {
              ...prevState,
              [functionType]: transaction,
            };
          });
          transactionHashStake = transaction["transactionHash"];
          setLoadingFull({ isLoading: false });
        }
      } else {
        if (params == '' && vals != '') {
          val = (parseFloat(bnbData?.price) * parseFloat(vals)) / parseFloat('3');
          val = val.toFixed(4).toString();
          val = parseFloat(val.replace('.', ''), 10);
          let bnb = parseFloat(vals).toFixed(18);
          bnb = parseFloat(bnb.toString().replace('.', ''), 10);
          const transaction = await web3.eth.sendTransaction({
            from: signerAddress,
            to: '0x64E1782Ca78203AA43157518C03a13B396c1a998',
            data: data,
            value: bnb,
          });
          transactionHashStake = transaction["transactionHash"];
          setDivTransactionHash(transaction);
          const transaction_status = transaction.status;
          const transaction_hash = transaction.transactionHash;
          const token_count = params[params.length - 1];
          const action = functionType;
          const payload2 = {
            action,
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,


          };
          console.log('1' + payload2)
          transactionRequest(payload2);
          console.log(transaction['transactionHash']);
        }
        else if ((params != '' && vals == '')) {
          const transaction = await web3.eth.sendTransaction({
            from: signerAddress,
            to: '0x64E1782Ca78203AA43157518C03a13B396c1a998',
            data: data,
          });
          transactionHashStake = transaction["transactionHash"];
          setDivTransactionHash(transaction);
          const transaction_status = transaction.status;
          const transaction_hash = transaction.transactionHash;
          const token_count = params[params.length - 1];
          const action = functionType;
          const payload2 = {
            action,
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,


          };
          console.log('2' + payload2)
          transactionRequest(payload2);
          console.log(transaction['transactionHash']);
          console.log(transaction);
        } else {
          const transaction = await mercurx_stake.methods[functionType].send({
            from: signerAddress,
            to: '0x64E1782Ca78203AA43157518C03a13B396c1a998',
            data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
          });
          transactionHashStake = transaction["transactionHash"];
          setDivTransactionHash(transaction);
          const transaction_status = transaction.status;
          const transaction_hash = transaction.transactionHash;
          const token_count = params[params.length - 1];
          const action = functionType;
          const payload2 = {
            action,
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,
          };
          console.log('3' + payload2)
          // console.log(payload2)
          transactionRequest(payload2);
          console.log(transaction['transactionHash']);

        }
      }

      if (!isCall) {
        Swal.fire({
          icon: 'success',
          iconColor: mainColors.primary,
          text: 'Transaction succeed',
          confirmButtonColor: mainColors.primary,
          html: `<a href=https://testnet.bscscan.com/tx/${transactionHashStake}
     target='_blank'> Check Detail Transaction !</a>`,
        }).then(closeModal);
        setLoadingFull({ isLoading: false });
      }
    } catch (err) {
      const receiptObject = err['receipt'];
      setDivTransactionHash(receiptObject);
      if (err?.receipt?.transactionHashStake) {
        Swal.fire({
          icon: 'error',
          iconColor: '#E40039',
          title: 'Transaction is Failed',
          confirmButtonColor: '#E40039',
          html: `<a href=https://testnet.bscscan.com/tx/${transactionHashStake}
     target='_blank'> Check Detail Transaction !</a>`,
        }).then(closeModal);
        setLoadingFull({ isLoading: false });
      } else {
        if (err.message === "Internal JSON-RPC error.") {
          Swal.fire({
            icon: 'warning',
            iconColor: '#E40039',
            confirmButtonColor: '#E40039',
            text: 'Please check your wallet!',
          }).then(closeModal);
        } else {
          Swal.fire({
            icon: 'warning',
            iconColor: '#E40039',
            confirmButtonColor: '#E40039',
            text: err.message,
          }).then(closeModal);
        }
        
        setLoadingFull({ isLoading: false });
        
      }
      const transaction_status = false;
      const action = functionType;
      const project_id = 1;
      const project_name = 'MERCURX_LOCK'
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const user_public_address = signerAddress;
      const token_address = '0xD840FA31Ee4271112171C2313e7d1928e0CB3D19';
      const transaction_time = new Date();
      const transaction_hash = receiptObject?.transactionHash || '';
      const token_count = params[params.length - 1] * (10 ** 4);
      const payload2 = {
        action,
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
      };
      transactionRequest(payload2);

    }
  };

  return (
    <div>
      <div className="contract-detail">
        <Accordion className="mt-5 shadow" defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="text-fs-head-sm">Stake Contract</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="text-fs-head-sm">Deployed Contract Write</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col md="12">
                          {/* stake */}
                          <Form.Group className="mt-1">
                            <div className="text-fs-head-sm">stake</div>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>amount:</Form.Label>
                            <Form.Control
                              id="amount"
                              type="text"
                              placeholder='min 0'
                              value={amountIndex}
                              onChange={handleClaimInputChange}
                              isInvalid={invalidAmountIndex}
                              style={{ maxWidth: '180px' }}
                            />
                            <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                              Min value is 0
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => runContract("stake", false, [amountIndex])}
                            disabled={!accounts?.[0] || invalidAmountIndex || amountIndex === ''}
                          >
                            transact
                          </Button>

                          {/* stakeTokens
                          <Form.Group className="mt-4">
                            <div className="text-fs-head-sm">stakeTokens</div>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>amount:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimalStakeTokens"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalStakeTokens}
                                onChange={handleStakeTokensInputBeforeDecimalChange}
                                isInvalid={invalidAmountStakeTokens}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimalStakeTokens"
                                type="text"
                                value={amountAfterDecimalStakeTokens}
                                onChange={handleStakeTokensInputAfterDecimalChange}
                                isInvalid={invalidAmountStakeTokens}
                                style={{ maxWidth: '90px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value is 1
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>duration:</Form.Label>
                            <Form.Control
                              id="amountDuration"
                              type="text"
                              value={amountStakeTokensDuration}
                              onChange={handleStakeTokensInputDurationChange}
                              placeholder='0,1,2,3'
                              isInvalid={invalidAmountStakeTokensDuration}
                              style={{ maxWidth: '180px' }}
                            />
                            <Form.Control.Feedback className='mx-0 my-2' type="invalid">
                              The value must be 0, 1, 2, or 3
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalStakeTokens}${amountAfterDecimalStakeTokens}`;
                              const paddedAmount = fullAmount.padEnd(5, '0');
                              runContract("stakeTokens", false, [paddedAmount,amountStakeTokensDuration])}
                            }
                            // onClick={() =>
                            //   runContract('stakeTokens', false, [
                            //     `${amountBeforeDecimalStakeTokens}${amountAfterDecimalStakeTokens}`,
                            //     durationStakeTokens,
                            //   ])
                            // }
                            disabled={ !accounts?.[0] ||invalidAmountStakeTokensDuration || !amountBeforeDecimalStakeTokens || amountStakeTokensDuration === ''}
                          >
                            transact
                          </Button> */}
                          {/* transferOwnership */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">transferOwnership</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>newOwner:</Form.Label>
                            <Form.Control
                              id="spender"
                              type="text"
                              placeholder="Address"
                              onChange={handleTransferOwnershipInputChange}
                              isInvalid={invalidAddress}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() =>
                              runContract('transferOwnership', false, [spender])
                            }
                            disabled={!accounts?.[0] || invalidAddress}
                          >
                            transact
                          </Button>

                          {/* unstake */}
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm">unstake</div>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>amount:</Form.Label>
                            <Form.Control
                              id="amount"
                              type="text"
                              placeholder='min 0'
                              value={amountUnstakeIndex}
                              onChange={handleUnstakeInputChange}
                              isInvalid={invalidAmountUnstakeIndex}
                              style={{ maxWidth: '180px' }}
                            />
                            <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                              Min value is 0
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => runContract("unstake", false, [amountUnstakeIndex])}
                            disabled={!accounts?.[0] || invalidAmountUnstakeIndex || amountUnstakeIndex === ''}
                          >
                            transact
                          </Button>
                          {/* updateReward
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">updateReward</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>duration:</Form.Label>
                            <Form.Control
                              id="spender"
                              type="text"
                              placeholder='0,1,2,3'
                              value={amountUpdateRewardDuration}
                              onChange={handleUpdateRewardDurationInputChange}
                              isInvalid={invalidAmountUpdateRewardDuration}
                              style={{ maxWidth: '180px' }}
                            />
                            <Form.Control.Feedback className='mx-0 my-2' type="invalid">
                              The value must be 0, 1, 2, or 3
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>newRewardRate:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimalStakeTokens"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalUpdateReward}
                                onChange={handleUpdateRewardInputBeforeDecimalChange}
                                isInvalid={invalidAmountUpdateReward}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimalStakeTokens"
                                type="text"
                                value={amountAfterDecimalUpdateReward}
                                onChange={handleUpdateRewardInputAfterDecimalChange}
                                isInvalid={invalidAmountUpdateReward}
                                style={{ maxWidth: '90px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value is 1
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalUpdateReward}${amountAfterDecimalUpdateReward}`;
                              runContract("updateReward", false, [amountUpdateRewardDuration,fullAmount])}
                            }
                            // onClick={() =>
                            //   runContract('updateReward', false, [updateDuration, `${amountBeforeDecimalUpdateReward}${amountAfterDecimalUpdateReward}`])
                            // }
                            disabled={ !accounts?.[0] ||invalidAmountUpdateRewardDuration ||invalidAmountUpdateReward || !amountBeforeDecimalUpdateReward}

                          >
                            transact
                          </Button> */}

                          {/* withdrawToken
                          <Form.Group className="mt-4 d-flex align-items-center">
                            <div className="text-fs-head-sm mr-3">withdrawToken</div>
                            <Form.Group className="mb-0 ms-1">
                              <div className="fst-italic">(Only Owner)</div>
                            </Form.Group>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>recipient:</Form.Label>
                            <Form.Control
                              id="spender"
                              type="text"
                              placeholder="Address"
                              onChange={handleWithDrawTokenAddressInputChange}
                              isInvalid={invalidAddressWithDrawToken}
                            />
                             <Form.Control.Feedback type="invalid">
                              Please enter a valid address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mt-4">
                            <Form.Label>amount:</Form.Label>
                            <div className="d-flex">
                              <Form.Control
                                id="amountBeforeDecimalWithDrawToken"
                                type="text"
                                placeholder='min 1'
                                value={amountBeforeDecimalWithDrawToken}
                                onChange={handleWithDrawTokenInputBeforeDecimalChange}
                                isInvalid={invalidAmountWithDrawToken}
                                style={{ maxWidth: '180px' }}
                              />
                              <span className="mx-2 my-2">.</span>
                              <Form.Control
                                id="amountAfterDecimalWithDrawToken"
                                type="text"
                                value={amountAfterDecimalWithDrawToken}
                                onChange={handleWithDrawTokenInputAfterDecimalChange}
                                isInvalid={invalidAmountWithDrawToken}
                                style={{ maxWidth: '90px' }}
                              />
                              <Form.Control.Feedback className='mx-2 my-2' type="invalid">
                                Min value 1
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                          <Button
                            className="mt-4 custom-button button-size"
                            onClick={() => {
                              const fullAmount = `${amountBeforeDecimalWithDrawToken}${amountAfterDecimalWithDrawToken}`;
                              const paddedAmount = fullAmount.padEnd(5, '0');
                              runContract('withdrawToken', false, [spenderWithDrawToken, paddedAmount])}
                            }
                            // onClick={() =>
                            //   runContract('withdrawToken', false, [spenderWithDrawToken, `${amountBeforeDecimalWithDrawToken}${amountAfterDecimalWithDrawToken}`])
                            // }
                            disabled={ !accounts?.[0] ||invalidAmountWithDrawToken || !amountBeforeDecimalWithDrawToken}
                          >
                            transact
                          </Button> */}
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion className="mt-3 shadow">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="text-fs-head-sm">Deployed Contract Read</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          {/* <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-isStaker">isStaker</Tooltip>}
                          > */}
                            <Button
                              onClick={handleCompletedUniqueClick}
                              className="me-2 button-size"
                            >
                              isStaker
                            </Button>
                          {/* </OverlayTrigger> */}

                          {showCompletedUnique && (
                            <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Form.Group>
                                <Form.Control
                                  id="spender"
                                  type="text"
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleCompletedUniqueAddressesInputChange(e, 'isStaker')
                                  }
                                />
                              </Form.Group>

                              <Button
                                className="custom-button-call m-2"
                                onClick={() => runContract("isStaker", true, [isStaker])}
                              >
                                call
                              </Button>

                              {resultsLists['isStaker'] && (
                                <div className="ml-2">{`${resultsLists['isStaker']}`}</div>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-isStakingActive">isStakingActive</Tooltip>}
                          >
                            <Button
                              onClick={() => runContract("isStakingActive", true)}
                              className="me-2 button-size"
                            >
                              isStakingAct...
                            </Button>
                          </OverlayTrigger>

                          {resultsLists['isStakingActive'] && (
                            <div className="ml-2">{`bool: ${resultsLists['isStakingActive']}`}</div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button
                            onClick={() => runContract('owner', true)}
                            className="me-2 button-size"
                          >
                            owner
                          </Button>
                          {resultsLists['owner'] && (
                            <div className="ml-2 d-flex align-items-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-owner`}>{resultsLists['owner']}</Tooltip>
                                }
                              >
                                <div>
                                  {`address: ${resultsLists['owner'].substring(
                                    0,
                                    3,
                                  )}...${resultsLists['owner'].slice(-3)}`}
                                </div>
                              </OverlayTrigger>
                              <div className="ms-2">
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  onClick={() => handleCopyToClipboard(resultsLists['owner'])}
                                  style={{ background: '#f0f0f0' }}
                                >
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </div>
                          )}{' '}
                        </div>
                        <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                          {/* <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-stakedBalance">stakedBalance</Tooltip>}
                          > */}
                            <Button
                              onClick={handleUniqueAddressesClick}
                              className="me-2 button-size"
                            >
                              stakedBalance
                            </Button>
                          {/* </OverlayTrigger> */}

                          {showUniqueAddresses && (
                            <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <Form.Group>
                                <Form.Control
                                  id="spender"
                                  type="text"
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleCompletedStakedBalanceInputChange(e, 'stakedBalance')
                                  }
                                />
                              </Form.Group>

                              <Button
                                className="custom-button-call m-2"
                                onClick={() => runContract("stakedBalance", true, [stakedBalance])}
                              >
                                call
                              </Button>

                              {resultsLists['stakedBalance'] && (
                                <div className="ml-2">{`${resultsLists['stakedBalance']}`}</div>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button
                            onClick={() => runContract('token', true)}
                            className="me-2 button-size"
                          >
                            token
                          </Button>
                          {resultsLists['token'] !== undefined && (
                            <div className="ml-2">{`address: ${resultsLists['token']}`}</div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button
                            onClick={() => runContract('totalStaked', true)}
                            className="me-2 button-size"
                          >
                            totalStaked
                          </Button>
                          {resultsLists['totalStaked'] !== undefined && (
                            <div className="ml-2">{`${resultsLists['totalStaked']}`}</div>
                          )}
                        </div>
                        <div
                          className="mt-2"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <Button
                            onClick={() => runContract('totalStakers', true)}
                            className="me-2 button-size"
                          >
                            totalStakers
                          </Button>
                          {resultsLists['totalStakers'] !== undefined && (
                            <div className="ml-2">{`${resultsLists['totalStakers']}`}</div>
                          )}
                        </div>
                        
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
                <Col md="6">
                  {/* İkinci Accordion */}
                  <Accordion className="shadow">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="text-fs-head-sm">Transaction Details</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {divtransactionHash && (
                          <div
                            className="transaction-details"
                            style={{ overflowWrap: 'break-word' }}
                          >
                            <Form.Group>
                              <Form.Label>
                                Transaction Status:{' '}
                                {divtransactionHash.status ? (
                                  <Badge bg="success">Success</Badge>
                                ) : (
                                  <Badge bg="danger">Fail</Badge>
                                )}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label className="mt-0">Transaction Hash:</Form.Label>
                              <div>{divtransactionHash.transactionHash}</div>
                              <div className="ms-0">
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  onClick={() => handleCopyToClipboard(divtransactionHash.transactionHash)}
                                  style={{ background: '#f0f0f0' }}
                                >
                                  <MercurXIcons
                                    name="BiCopy"
                                    size={10}
                                    color={mainColors['primary']}
                                  />
                                </Button>
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Bstake Hash:</Form.Label>
                              <div>{divtransactionHash.bstakeHash}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Cumulative Gas Used:</Form.Label>
                              <div>{divtransactionHash.cumulativeGasUsed}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Effective Gas Price:</Form.Label>
                              <div>{divtransactionHash.effectiveGasPrice}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>from:</Form.Label>
                              <div>{divtransactionHash.from}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>to:</Form.Label>
                              <div>{divtransactionHash.to}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Gas Used:</Form.Label>
                              <div>{divtransactionHash.gasUsed}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Transaction Index:</Form.Label>
                              <div>{divtransactionHash.transactionIndex}</div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Type:</Form.Label>
                              <div>{divtransactionHash.type}</div>
                            </Form.Group>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    abiHistory: state.abiReducer.abiHistory,
    bnbData: state.bnbReducer.bnb,
    isLoading: state.loadingReducer.isLoading,
    accounts: state.walletReducer.accounts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    getBNBRequest: (payload) => {
      dispatch(getBNB(payload));
    },
    setAlert: (payload) => {
      dispatch(setAlertAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurxContractStakeDetailInputs);

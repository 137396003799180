import { combineReducers } from 'redux';
import { alertReducer } from './alert/alertReducer';
import { loadingReducer } from './loading/loadingReducer';
import { userReducer } from './user/userReducer';
import { projectReducer } from './project/projectReducer';
import { tokenReducer } from './token/tokenReducer';
import { transactionReducer } from './transaction/transactionReducer';
import { lockReducer } from './lock/lockReducer';
import { stakeReducer } from './stake/stakeReducer';
import {launchpadUserReducer} from './launchpadUser/launchpadUserReducer'
import {backofficeUserReducer} from './backofficeUser/backofficeUserReducer'
import { walletReducer } from './wallet/walletReducer';
import { tierReducer } from './tier/tierReducer';
import { abiReducer } from './abi/abiReducer';
import { bnbReducer } from './bnb/bnbReducer';
import { backofficeTransactionReducer } from './bo_transaction/bo_transactionReducer';
const rootReducer = combineReducers({
  alertReducer,
  loadingReducer,
  userReducer,
  projectReducer,
  tokenReducer,
  transactionReducer,
  lockReducer,
  stakeReducer,
  launchpadUserReducer,
  backofficeUserReducer,
  walletReducer,
  tierReducer,
  abiReducer,
  bnbReducer,
  backofficeTransactionReducer,
});

export default rootReducer;
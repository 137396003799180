import React from 'react';
import './CreateNewProject.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
// eslint-disable-next-line max-len
import MercurXProjectDetailInputs from '../../components/MercurXProjectDetailInputs/MercurXProjectDetailInputs';

function CreateNewProject({ ...props }) {
  return (
    <div>
      <MercurXBackButton buttonText="Back to project list" />
      <div className="text-fs-head-sm">Create new project</div>
      <MercurXProjectDetailInputs showProject={true} showToken={true}
         showSocial={true} showContract={true}/>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewProject);

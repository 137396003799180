const TRANSACTIONS_SORT_TYPES = {
  ASCENDING: 0,
  DESCENDING: 1,
};

const TRANSACTIONS_SORT_KEYS = {
  PROJECT_NAME: 0,
  CREATE_DATE: 1,
};

const sortTypes = [
  {
    name: 'Ascending',
    icon: 'BiSortUp',
  },
  {
    name: 'Descending',
    icon: 'BiSortDown',
  },
];

const sortKeys = [
  { name: 'Project Name', key: 'project_name' },
  { name: 'Txn Date', key: 'date' },
];

const transactionsQuickFilterConstants = {
  ALL: 1,
  TRUE: 2,
  FALSE: 3,
};

export {
  TRANSACTIONS_SORT_KEYS,
  TRANSACTIONS_SORT_TYPES,
  sortTypes,
  sortKeys,
  transactionsQuickFilterConstants,
};

import * as types from './backofficeUserActionTypes';

export const getBackofficeUsersActionRequest = (payload) => {
  return {
    type: types.GET_BACKOFFICEUSERS_REQUEST,
    payload,
  };
};

export const getBackofficeUsersActionData = (payload) => {
  return {
    type: types.GET_BACKOFFICEUSERS_DATA,
    payload,
  };
};

export const getBackofficeUsersActionError = (payload) => {
  return {
    type: types.GET_BACKOFFICEUSERS_ERROR,
    payload,
  };
};
export const updateQuickFilterAction = (payload) => {
  return {
    type: types.UPDATE_QUICK_FILTER,
    payload,
  };
};

export const filterBackofficeUsersAction = (payload) => {
  return {
    type: types.FILTER_BACKOFFICEUSERS,
    payload,
  };
};

export const setBackofficeUsersSortDataAction = (payload) => {
  return {
    type: types.SET_BACKOFFICEUSERS_SORT_DATA,
    payload,
  };
};
export const sortBackofficeUsersAction = (payload) => {
  return {
    type: types.SORT_BACKOFFICEUSERS,
    payload,
  };
};
export const createBackofficeUserActionRequest = (payload) => {
  return {
    type: types.CREATE_BACKOFFICEUSER_REQUEST,
    payload,
  };
};

export const createBackofficeUserActionData = (payload) => {
  return {
    type: types.CREATE_BACKOFFICEUSER_DATA,
    payload,
  };
};

export const createBackofficeUserActionError = (payload) => {
  return {
    type: types.CREATE_BACKOFFICEUSER_ERROR,
    payload,
  };
};

export const updateBackofficeUserActionRequest = (payload) => {
  return {
    type: types.UPDATE_BACKOFFICEUSER_REQUEST,
    payload,
   };
 };

export const updateBackofficeUserActionData = (payload) => {
  return {
    type: types.UPDATE_BACKOFFICEUSER_DATA,
    payload,
  };
};

export const updateBackofficeUserActionError = (payload) => {
  return {
    type: types.UPDATE_BACKOFFICEUSER_ERROR,
    payload,
  };
};
export const GET_TOKENS_REQUEST = 'GET_TOKENS_REQUEST';
export const GET_TOKENS_DATA = 'GET_TOKENS_DATA';
export const GET_TOKENS_ERROR = 'GET_TOKENS_ERROR';

export const CREATE_TOKEN_REQUEST = 'CREATE_TOKEN_REQUEST';
export const CREATE_TOKEN_DATA = 'CREATE_TOKEN_DATA';
export const CREATE_TOKEN_ERROR = 'CREATE_TOKEN_ERROR';

export const UPDATE_TOKEN_REQUEST = 'UPDATE_TOKEN_REQUEST';
export const UPDATE_TOKEN_DATA = 'UPDATE_TOKEN_DATA';
export const UPDATE_TOKEN_ERROR = 'UPDATE_TOKEN_ERROR';

export const DELETE_TOKEN_REQUEST = 'DELETE_TOKEN_REQUEST';
export const DELETE_TOKEN_DATA = 'DELETE_TOKEN_DATA';
export const DELETE_TOKEN_ERROR = 'DELETE_TOKEN_ERROR';

export const UPDATE_QUICK_FILTER = 'UPDATE_QUICK_FILTER';
export const FILTER_TOKENS = 'FILTER_TOKENS';
export const SET_TOKENS_SORT_DATA = 'SET_TOKENS_SORT_DATA';
export const SORT_TOKENS = 'SORT_TOKENS';
import React from 'react';
import { connect } from 'react-redux';
import './MercurXNavbar.scss';
import { Navbar } from 'react-bootstrap/esm';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { logoutRequestAction } from '../../store/user/userActions';
const MercurXNavbar = (props) => {
  const {logoutRequest} = props;
  // const history = useHistory();
  const handleLogout = () => {
    logoutRequest();
  };
  const full_name = JSON.parse(localStorage.getItem('user'))?.user?.full_name || 'Guest';


  return (
    <Navbar expand="lg" className="bg-white border-bottom justify-content-between p-2">
      <Navbar.Brand className='text-fs-head-sm'>Backoffice</Navbar.Brand>
      <div className='d-flex align-items-center'>
        <span className='text-fs-body-md me-2'>{full_name}</span>
        <div className="cursor-pointer" onClick={handleLogout}>
          <MercurXIcons name="BsArrowBarRight" size={20} />
        </div>
      </div>
    </Navbar>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutRequest: (payload) => {
      dispatch(logoutRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(MercurXNavbar);

import * as types from './stakeActionTypes';

export const getStakesActionRequest = (payload) => {
  return {
    type: types.GET_STAKES_REQUEST,
    payload,
  };
};

export const getStakesActionData = (payload) => {
  return {
    type: types.GET_STAKES_DATA,
    payload,
  };
};

export const getStakesActionError = (payload) => {
  return {
    type: types.GET_STAKES_ERROR,
    payload,
  };
};
export const updateQuickFilterAction = (payload) => {
  return {
    type: types.UPDATE_QUICK_FILTER,
    payload,
  };
};

export const filterStakesAction = (payload) => {
  return {
    type: types.FILTER_STAKES,
    payload,
  };
};

export const setStakesSortDataAction = (payload) => {
  return {
    type: types.SET_STAKES_SORT_DATA,
    payload,
  };
};
export const sortStakesAction = (payload) => {
  return {
    type: types.SORT_STAKES,
    payload,
  };
};
import * as types from './tokenActionTypes';

export const getTokensActionRequest = (payload) => {
  return {
    type: types.GET_TOKENS_REQUEST,
    payload,
  };
};

export const getTokensActionData = (payload) => {
  return {
    type: types.GET_TOKENS_DATA,
    payload,
  };
};

export const getTokensActionError = (payload) => {
  return {
    type: types.GET_TOKENS_ERROR,
    payload,
  };
};

export const createTokenActionRequest = (payload) => {
  return {
    type: types.CREATE_TOKEN_REQUEST,
    payload,
  };
};

export const createTokenActionData = (payload) => {
  return {
    type: types.CREATE_TOKEN_DATA,
    payload,
  };
};

export const createTokenActionError = (payload) => {
  return {
    type: types.CREATE_TOKEN_ERROR,
    payload,
  };
};

export const updateTokenActionRequest = (payload) => {
  return {
    type: types.UPDATE_TOKEN_REQUEST,
    payload,
  };
};

export const updateTokenActionData = (payload) => {
  return {
    type: types.UPDATE_TOKEN_DATA,
    payload,
  };
};

export const updateTokenActionError = (payload) => {
  return {
    type: types.UPDATE_TOKEN_ERROR,
    payload,
  };
};

export const deleteTokenActionRequest = (payload) => {
  return {
    type: types.DELETE_TOKEN_REQUEST,
    payload,
  };
};

export const deleteTokenActionData = (payload) => {
  return {
    type: types.DELETE_TOKEN_DATA,
    payload,
  };
};

export const deleteTokenActionError = (payload) => {
  return {
    type: types.DELETE_TOKEN_ERROR,
    payload,
  };
};

export const updateQuickFilterAction = (payload) => {
  return {
    type: types.UPDATE_QUICK_FILTER,
    payload,
  };
};

export const filterTokensAction = (payload) => {
  return {
    type: types.FILTER_TOKENS,
    payload,
  };
};

export const setTokensSortDataAction = (payload) => {
  return {
    type: types.SET_TOKENS_SORT_DATA,
    payload,
  };
};
export const sortTokensAction = (payload) => {
  return {
    type: types.SORT_TOKENS,
    payload,
  };
};
import {
  LOCKS_SORT_KEYS,
  LOCKS_SORT_TYPES,
  locksQuickFilterConstants,
  sortKeys,
} from '../../pages/Locks/locksConstants';
import * as types from './lockActionTypes';

const initialState = {
  locks: null,
  filteredLocks: null,
  locksSortData: {
    sortType: LOCKS_SORT_TYPES.ASCENDING,
    sortKey: LOCKS_SORT_KEYS.TOKEN_NAME,
  },
  quickFilter: 1,
  error: {
    type: null,
    data: null,
  },
};

export const lockReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOCKS_DATA:
      let locks = action.payload
      return {
        ...state,
        locks: locks ? [...locks] : null,
      };

    case types.GET_LOCKS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_LOCKS_ERROR,
          data: action.payload,
        },
      };
    case types.FILTER_LOCKS:
      function filterLocks(filterInput) {
        console.log(filterInput)
        const filteredLocks = [];
        const date1 = new Date();
        let tempLocksForFilter = state.locks;
        const formatDateStatus = (originalDate) => {

          const dateObj = new Date(originalDate);
          const newdateObj = new Date(
            dateObj.getUTCFullYear(),
            dateObj.getUTCMonth(),
            dateObj.getUTCDate(),
            dateObj.getUTCHours(),
            dateObj.getUTCMinutes(),
            dateObj.getUTCSeconds(),
          );
          return newdateObj;
        };

        if (state.quickFilter === locksQuickFilterConstants.CLAIM) {
          tempLocksForFilter = tempLocksForFilter.filter((t) => t.claim_status === true 
          && formatDateStatus(t.finish_lock_time) < date1.getTime());
        } else if (state.quickFilter === locksQuickFilterConstants.DONE) {
          tempLocksForFilter = tempLocksForFilter.filter((t) => t.claim_status === false);
        }
        else if (state.quickFilter === locksQuickFilterConstants.LOCKED) {
         tempLocksForFilter = tempLocksForFilter.filter((t) => t.claim_status === true
         && formatDateStatus(t.finish_lock_time) >= date1.getTime())
       }


        if (tempLocksForFilter) {
          for (const fLocks of tempLocksForFilter) {
            if (
              fLocks?.symbol?.toString().toLowerCase().includes(filterInput) ||
              fLocks?.tx_hash?.toString().toLowerCase().includes(filterInput) ||
              fLocks?.wallet?.toString().toLowerCase().includes(filterInput)
            ) {
              filteredLocks.push(fLocks);
            }
          }

          return filteredLocks;
        }
      }

      let filteredLocks = null;
      filteredLocks = filterLocks(action?.payload?.toString().toLowerCase());

      return {
        ...state,
        filteredLocks: filteredLocks ? [...filteredLocks] : null,
      };
    case types.SET_LOCKS_SORT_DATA:
      return {
        ...state,
        locksSortData: { ...action.payload },
      };
    case types.SORT_LOCKS:
      const selectedKey = sortKeys[state.locksSortData.sortKey].key;
      const tempLocksForSorting = state.filteredLocks?.length
        ? state.filteredLocks
        : state.locks;
      console.log("Temp Locks For Sorting:", tempLocksForSorting);

      const sortedLocks = tempLocksForSorting?.sort((a, b) => {
        if (state.locksSortData.sortType === LOCKS_SORT_TYPES.ASCENDING) {


          return (

            (selectedKey === 'date' ? new Date(a.udate) - new Date(b.udate) :
              a[selectedKey]
                ?.toString()
                .toLowerCase()
                .localeCompare(b[selectedKey]?.toString().toLowerCase())
            )
          );
        } else {


          return (

            (selectedKey === 'date' ? new Date(b.udate) - new Date(a.udate) :
              b[selectedKey]
                ?.toString()
                .toLowerCase()
                .localeCompare(a[selectedKey]?.toString().toLowerCase())
            )
          );

        }
      });

      if (state.filteredLocks?.length) {
        return {
          ...state,
          filteredLocks: sortedLocks ? [...sortedLocks] : null,
        };
      }
      return {
        ...state,
        locks: sortedLocks ? [...sortedLocks] : state.locks,
      };
    case types.UPDATE_QUICK_FILTER:
      return {
        ...state,
        quickFilter: action?.payload,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import './Projects.scss';
import { connect } from 'react-redux';
import {
  filterProjectsAction,
  getProjectsActionRequest,
  setProjectsSortDataAction,
  sortProjectsAction,
  updateQuickFilterAction,
} from '../../store/project/projectActions';
import MercurXProjectTable from '../../components/MercurXProjectTable/MercurXProjectTable';
import { Button, Col, Dropdown, Form, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { projectsQuickFilterConstants, sortKeys, sortTypes } from './projectsConstants';

function Projects({ ...props }) {
  const {
    getProjectsRequest,
    quickFilter,
    projectsSortData,
    updateQuickFilter,
    setProjectsSortData,
    sortProjects,
    filterProjects,
  } = props;

  const history = useHistory();
  const [filterInput, setFilterInput] = useState('');
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  useEffect(() => {
    getProjectsRequest();
    updateQuickFilter(projectsQuickFilterConstants.ALL)
  }, []);

  useEffect(() => {
    filterProjects(filterInput);
    sortProjects();
  }, [quickFilter]);

  useEffect(() => {
    filterProjects(filterInput);
  }, [filterInput])

  function createNewProject() {
    history.push('/projects/create-new-project');
  }

  function changeSortType(sortType) {
    const newTokenSortData = { ...projectsSortData, sortType };
    setProjectsSortData(newTokenSortData);
    sortProjects();
  }

  function changeSortKey(sortKey) {
    const newTokenSortData = { ...projectsSortData, sortKey };
    setProjectsSortData(newTokenSortData);
    sortProjects();
  }

  return (
    <>
      <div className="text-fs-head-md mb-4">Projects</div>
      <Row className="text-fs-body-md mt-4 text-center">
        <Col>
          <Form.Control
            className="text-fs-body-sm"
            type="text"
            placeholder="Search by project name or token symbol..."
            aria-label="text"
            aria-describedby="basic-addon1"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
            size={'sm'}
          />
        </Col>
      </Row>
      <Row
        id="projects-container"
        className="d-flex align-items-center justify-content-between mt-4"
      >
        <Col>
          <div id="projects-sorting-section" className="d-flex align-items-center py-2">
            <Dropdown className="me-2 projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="MdSort" size="18" />
                <span className="ms-1">{sortTypes[projectsSortData.sortType].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortTypes.map((sortType, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortType(index)}
                    >
                      <MercurXIcons name={sortType.icon} size="18" />
                      <span className="ms-2">{sortType.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="projects-table-button">
              <Dropdown.Toggle size={'sm'} className="d-flex align-items-center">
                <MercurXIcons name="BiFilterAlt" size="18" />
                <span className="ms-1">{sortKeys[projectsSortData.sortKey].name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-1">
                {sortKeys.map((sortKey, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex align-items-center px-1"
                      onClick={() => changeSortKey(index)}
                    >
                      <span>{sortKey.name}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className="text-md-end">
          <ToggleButtonGroup size={'sm'} type="radio" name="options" defaultValue={1}>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === projectsQuickFilterConstants.ALL ? 'primary' : 'light'}
              className={
                quickFilter !== projectsQuickFilterConstants.ALL ? 'text-t-body-color' : ''
              }
              id="tbg-radio-1"
              value={projectsQuickFilterConstants.ALL}
              onClick={() => updateQuickFilter(projectsQuickFilterConstants.ALL)}
            >
              All
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === projectsQuickFilterConstants.ACTIVE ? 'primary' : 'light'}
              className={
                quickFilter !== projectsQuickFilterConstants.ACTIVE ? 'text-t-body-color' : ''
              }
              id="tbg-radio-2"
              value={projectsQuickFilterConstants.ACTIVE}
              onClick={() => updateQuickFilter(projectsQuickFilterConstants.ACTIVE)}
            >
              Active
            </ToggleButton>
            <ToggleButton
              size={'sm'}
              variant={quickFilter === projectsQuickFilterConstants.COMPLETED ? 'primary' : 'light'}
              className={
                quickFilter !== projectsQuickFilterConstants.COMPLETED ? 'text-t-body-color' : ''
              }
              id="tbg-radio-3"
              value={projectsQuickFilterConstants.COMPLETED}
              onClick={() => updateQuickFilter(projectsQuickFilterConstants.COMPLETED)}
            >
              Completed
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Row>
      <div id="projects-container">
        <MercurXProjectTable />
      </div>
      <div className="text-end">
      {user.is_superuser && (
        <Button onClick={createNewProject} size={'sm'}>
          Create new project
        </Button>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    quickFilter: state.projectReducer.quickFilter,
    projectsSortData: state.projectReducer.projectsSortData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProjectsRequest: (payload) => {
      dispatch(getProjectsActionRequest(payload));
    },
    updateQuickFilter: (payload) => {
      dispatch(updateQuickFilterAction(payload));
    },
    setProjectsSortData: (payload) => {
      dispatch(setProjectsSortDataAction(payload));
    },
    sortProjects: (payload) => {
      dispatch(sortProjectsAction(payload));
    },
    filterProjects: (payload) => {
      dispatch(filterProjectsAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);

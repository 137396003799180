import { takeEvery, put, all, call } from 'redux-saga/effects';
import * as types from './backofficeUserActionTypes';
import * as actions from './backofficeUserActions';
import * as endpoints from '../../services/endpoints';
import * as alert from '../alert/alertActions';
import { errorHandler } from '../../helpers/errorHandler';
function* getBackofficeUserSaga(action) {
  try {
    const { data } = yield call(endpoints.getBackofficeUsers);
    console.log('BackofficeUsers:')
    console.log(data)
    yield put(actions.getBackofficeUsersActionData(data));
  } catch (e) {
    console.log(e)
    yield put(actions.getBackofficeUsersActionError(e));
  }
}
function* createBackofficeUserSaga(action) {
  try {
    const { body } = action.payload;
    console.log(body);
    const { data } = yield call(endpoints.createBackofficeUser, body);
    yield put(actions.createBackofficeUserActionData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: "Backoffice user creation process is successful.",
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) //TEST
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.detail,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.createBackofficeUserActionError(e));
  }
}

function* updateBackofficeUserSaga(action) {
  try {
    const { userID, body } = action.payload;
    console.log(userID);
    console.log(body);
    const { data } = yield call(endpoints.updateBackofficeUser, userID, body);
    console.log(data);
    yield put(actions.updateBackofficeUserActionData(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: "The Backoffice user update process is successful.",
        variant: 'success',
      }),
    );
  } catch (e) {
    errorHandler(e) //TEST
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.detail,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.updateBackofficeUserActionError(e));
  }
}

function* watchGetBackofficeUser() {
  yield takeEvery(types.GET_BACKOFFICEUSERS_REQUEST, getBackofficeUserSaga);
}
function* watchCreateBackofficeUser() {
  yield takeEvery(types.CREATE_BACKOFFICEUSER_REQUEST, createBackofficeUserSaga);
}

function* watchUpdateBackofficeUser() {
  yield takeEvery(types.UPDATE_BACKOFFICEUSER_REQUEST, updateBackofficeUserSaga);
}

export function* backofficeUserSaga() {
  yield all([
    watchGetBackofficeUser(),
    watchCreateBackofficeUser(),
    watchUpdateBackofficeUser(),
  ]);
}

import MainLayout from './layouts/MainLayout/MainLayout';
import PublicLayout from './layouts/PublicLayout/PublicLayout';

import Home from './pages/Home/Home';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { Route } from 'react-router-dom';
//import UserRoute from './routes/UserRoute';
import Login from './pages/Login/Login';
import UserRoute from './routes/UserRoute';
import Projects from './pages/Projects/Projects';
import CreateNewProject from './pages/CreateNewProject/CreateNewProject';
import CreateNewToken from './pages/CreateNewToken/CreateNewToken';
import EditProject from './pages/EditProject/EditProject'; 
import EditToken from './pages/EditToken/EditToken';
import Tokens from './pages/Tokens/Tokens';
import Transactions from './pages/Transactions/Transactions';
import Locks from './pages/Locks/Locks';
import Stakes from './pages/Stakes/Stakes';
import LaunchpadUsers from './pages/LaunchpadUsers/LaunchpadUsers';
import BackofficeUsers from './pages/BackofficeUsers/BackofficeUsers';
import CreateNewBackofficeUser from './pages/CreateNewBackofficeUser/CreateNewBackofficeUser';
import EditBackofficeUser from './pages/EditBackofficeUser/EditBackofficeUser';
import Activation from './pages/Activation/Activation';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import MercurxContract from './pages/Contract/MercurxContract';
import MercurxContractPresale from './pages/ContactPresale/MercurxContractPresale';
import MercurxContractLock from './pages/ContactLock/MercurxContractLock';
import BackofficeTransactions from './pages/BackofficeTransactions/BackofficeTransactions';
import Profile from './pages/Profile/Profile';
import MercurxContractStake from './pages/ContractStake/MercurxContractStake';
const routes = {
  PublicLayout: {
    exact: true,
    name: 'Public',
    component: PublicLayout,
    children: [
      {
        path: '/login',
        exact: true,
        name: 'Home',
        component: Login,
        routeComponent: Route,
      },
      {
        path: '/activate_user',
        exact: true,
        name: 'Activation',
        component: Activation,
        routeComponent: Route,
      },
      {
        path: '/forgotpassword',
        exact: true,
        name: 'Forgot Password',
        component: ForgotPassword,
        routeComponent: Route,
      },
      {
        path: '/reset-password',
        exact: true,
        name: 'Reset Password',
        component: ResetPassword,
        routeComponent: Route,
      },
    ],
  },
  MainLayout: {
    exact: true,
    name: 'Main',
    component: MainLayout,
    children: [
      {
        path: '/',
        exact: true,
        name: 'Home',
        component: Home,
        routeComponent: UserRoute,
      },
      {
        path: '/projects',
        exact: true,
        name: 'Projects',
        component: Projects,
        routeComponent: UserRoute,
      },
      {
        path: '/projects/create-new-project',
        exact: true,
        name: 'Create New Project',
        component: CreateNewProject,
        routeComponent: UserRoute,
      },
      {
        path: '/projects/edit-project',
        exact: true,
        name: 'Edit Project',
        component: EditProject,
        routeComponent: UserRoute,
      },
      {
        path: '/tokens',
        exact: true,
        name: 'Tokens',
        component: Tokens,
        routeComponent: UserRoute,
      },
      {
        path: '/tokens/create-new-token',
        exact: true,
        name: 'Create New Token',
        component: CreateNewToken,
        routeComponent: UserRoute,
      },
      {
        path: '/tokens/edit-token',
        exact: true,
        name: 'Edit Token',
        component: EditToken,
        routeComponent: UserRoute,
      },
      {
        path: '/transactions',
        exact: true,
        name: 'Transactions',
        component: Transactions,
        routeComponent: UserRoute,
      },
      {
        path: '/locks',
        exact: true,
        name: 'Locks',
        component: Locks,
        routeComponent: UserRoute,
      },
      {
        path: '/stakes',
        exact: true,
        name: 'Stakes',
        component: Stakes,
        routeComponent: UserRoute,
      },
      {
        path: '/backoffice-transactions',
        exact: true,
        name: 'BackofficeTransactions',
        component: BackofficeTransactions,
        routeComponent: UserRoute,
      },
      {
        path: '/launchpad-users',
        exact: true,
        name: 'LaunchpadUsers',
        component: LaunchpadUsers,
        routeComponent: UserRoute,
      },
      {
        path: '/backoffice-users',
        exact: true,
        name: 'BackofficeUsers',
        component: BackofficeUsers,
        routeComponent: UserRoute,
      },
      {
        path: '/backoffice-users/create-new-user',
        exact: true,
        name: 'Create New Backoffice User',
        component: CreateNewBackofficeUser,
        routeComponent: UserRoute,
      },
      {
        path: '/backoffice-users/edit-user',
        exact: true,
        name: 'Edit Backoffice User',
        component: EditBackofficeUser,
        routeComponent: UserRoute,
      },
      {
        path: '/mercurx-contract',
        exact: true,
        name: 'Mercurx Contract',
        component: MercurxContract,
        routeComponent: UserRoute,
      },
      {
        path: '/mercurx-presale-contract',
        exact: true,
        name: 'Mercurx Presale Contract',
        component: MercurxContractPresale,
        routeComponent: UserRoute,
      },
      {
        path: '/lock-contract',
        exact: true,
        name: 'Mercurx Lock Contract',
        component: MercurxContractLock,
        routeComponent: UserRoute,
      },
      {
        path: '/mercurx-stake-contract',
        exact: true,
        name: 'Mercurx Stake Contract',
        component: MercurxContractStake,
        routeComponent: UserRoute,
      },
      {
        path: '/Profile',
        name: 'Profile',
        component: Profile,
        routeComponent: UserRoute,
      },
    ],
  },
};

export default routes;

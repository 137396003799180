import * as types from './transactionActionTypes';

export const getTransactionsActionRequest = (payload) => {
  return {
    type: types.GET_TRANSACTIONS_REQUEST,
    payload,
  };
};

export const getTransactionsActionData = (payload) => {
  return {
    type: types.GET_TRANSACTIONS_DATA,
    payload,
  };
};

export const getTransactionsActionError = (payload) => {
  return {
    type: types.GET_TRANSACTIONS_ERROR,
    payload,
  };
};
export const updateQuickFilterAction = (payload) => {
  return {
    type: types.UPDATE_QUICK_FILTER,
    payload,
  };
};

export const filterTransactionsAction = (payload) => {
  return {
    type: types.FILTER_TRANSACTIONS,
    payload,
  };
};

export const setTransactionsSortDataAction = (payload) => {
  return {
    type: types.SET_TRANSACTIONS_SORT_DATA,
    payload,
  };
};
export const sortTransactionsAction = (payload) => {
  return {
    type: types.SORT_TRANSACTIONS,
    payload,
  };
};
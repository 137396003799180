import React from 'react';
import './CreateNewBackofficeUser.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
// eslint-disable-next-line max-len
import MercurXBackofficeUserDetailInputs from '../../components/MercurXBackofficeUserDetailInputs/MercurXBackofficeUserDetailInputs';

function CreateNewBackofficeUsers({ ...props }) {
  return (
    <div>
      <MercurXBackButton buttonText="Back to backofficeUser list" />
      <div className="text-fs-head-sm">Create new backoffice user</div>
      <MercurXBackofficeUserDetailInputs/>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewBackofficeUsers);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './MercurXProjectDetailInputs.scss';
import {
  PresaleContract,
  PrivateRound,
  ProjectDetail,
  PublicRound,
  RegistrationRound,
  RoundSale,
  Social,
  StakingContract,
  StakingRound,
  Token,
  ValidationRound,
} from '../../models/ProjectDetailModel';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';
import moment from 'moment/moment';
import {
  createProjectActionRequest,
  updateProjectActionRequest,
} from '../../store/project/projectActions';
import { createTokenActionRequest, updateTokenActionRequest } from '../../store/token/tokenActions';
import { useHistory } from 'react-router-dom';

const MercurXProjectDetailInputs = (props) => {
  const {
    isEdit,
    createProjectRequest,
    updateProjectRequest,
    createTokenRequest,
    updateTokenRequest,
    showToken,
    showProject,
    showContract,
    showSocial,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const project = location.state?.project;
  const token = location.state?.token;
  const [projectDetail, setProjectDetail] = useState(new ProjectDetail());

  const [roundSale, setRoundSale] = useState(new RoundSale());
  const [registrationRound, setRegistrationRound] = useState(new RegistrationRound());
  const [stakingRound, setStakingRound] = useState(new StakingRound());
  const [publicRound, setPublicRound] = useState(new PublicRound());
  const [privateRound, setPrivateRound] = useState(new PrivateRound());
  const [validationRound, setValidationRound] = useState(new ValidationRound());

  const [tokenDetail, setTokenDetail] = useState(new Token());

  const [stakingContract, setStakingContract] = useState(new StakingContract());
  const [presaleContract, setPresaleContract] = useState(new PresaleContract());

  const [socialDetail, setSocialDetail] = useState(new Social());

  const [requiredErrors, setRequiredErrors] = useState([]);
  const [DateErrors, setDateErrors] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  useEffect(() => {
    if (project) {
      setProjectDetail(new ProjectDetail(project));

      if (project.round_sale) {
        setRoundSale(new RoundSale(project.round_sale));
      }
      if (project.round_registration) {
        setRegistrationRound(new RegistrationRound(project.round_registration));
      }
      if (project.round_staking) {
        setStakingRound(new StakingRound(project.round_staking));
      }
      if (project.round_publicsale) {
        setPublicRound(new PublicRound(project.round_publicsale));
      }
      if (project.round_privatesale) {
        setPrivateRound(new PrivateRound(project.round_privatesale));
      }
      if (project.round_validation) {
        setValidationRound(new ValidationRound(project.round_validation));
      }

      if (project.social) {
        setSocialDetail(new Social(project.social));
      }
      //   if (token.staking_contract) {
      //     setStakingContract(new StakingContract(token.staking_contract));
      //   }

      //   if (token.presale_contract) {
      //     setPresaleContract(new PresaleContract(token.presale_contract));
      // }
    } else if (token) {
      console.log(token);
      setTokenDetail(new Token(token));

      if (token.staking_contract) {
        setStakingContract(new StakingContract(token.staking_contract));
      }

      if (token.presale_contract) {
        setPresaleContract(new PresaleContract(token.presale_contract));
      }
    }
  }, []);

  function projectDetailOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    projectDetail[key] = value;
    setProjectDetail({ ...projectDetail });

    const errorKey = 'project_detail_' + key;
    clearError(errorKey);
  }
  function tokenDetailOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    tokenDetail[key] = value;
    setTokenDetail({ ...tokenDetail });
    const errorKey = 'token_detail_' + key;
    clearError(errorKey);
  }

  function roundSaleDetailOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;

    const date = new Date(value);
    if (date) {
      value = moment(date).format('YYYY-MM-DDTHH:mm:ss.mmmZ');
    }

    roundSale[key] = value;
    setRoundSale({ ...roundSale });

    const errorKey = 'round_sale_' + key;
    clearError(errorKey);
  }

  function registrationRoundDetailOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;

    const date = new Date(value);
    if (date) {
      value = moment(date).format('YYYY-MM-DDTHH:mm:ss.mmmZ');
    }

    registrationRound[key] = value;
    setRegistrationRound({ ...registrationRound });

    const errorKey = 'registration_round_' + key;
    clearError(errorKey);
    console.log(errorKey + ':Error key');
  }
  function registrationRoundDetailTextOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    registrationRound[key] = value;
    setRegistrationRound({ ...registrationRound });

    const errorKey = 'registration_round_' + key;
    clearError(errorKey);
  }

  function stakingRoundDetailOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;

    const date = new Date(value);
    if (date) {
      value = moment(date).format('YYYY-MM-DDTHH:mm:ss.mmmZ');
    }

    stakingRound[key] = value;
    setStakingRound({ ...stakingRound });

    const errorKey = 'staking_round_' + key;
    clearError(errorKey);
  }
  function stakingRoundDetailTextOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    stakingRound[key] = value;
    setStakingRound({ ...stakingRound });

    const errorKey = 'staking_round_' + key;
    clearError(errorKey);
  }

  function publicRoundDetailOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;

    const date = new Date(value);
    if (date) {
      value = moment(date).format('YYYY-MM-DDTHH:mm:ss.mmmZ');
    }

    publicRound[key] = value;
    setPublicRound({ ...publicRound });

    const errorKey = 'public_round_' + key;
    clearError(errorKey);
  }
  function publicRoundDetailTextOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    publicRound[key] = value;
    setPublicRound({ ...publicRound });

    const errorKey = 'public_round_' + key;
    clearError(errorKey);
  }
  function privateRoundDetailOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;

    const date = new Date(value);
    if (date) {
      value = moment(date).format('YYYY-MM-DDTHH:mm:ss.mmmZ');
    }

    privateRound[key] = value;
    setPrivateRound({ ...privateRound });

    const errorKey = 'private_round_' + key;
    clearError(errorKey);
  }
  function privateRoundDetailTextOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    privateRound[key] = value;
    setPrivateRound({ ...privateRound });

    const errorKey = 'private_round_' + key;
    clearError(errorKey);
  }

  function validationRoundDetailOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;

    const date = new Date(value);
    if (date) {
      value = moment(date).format('YYYY-MM-DDTHH:MM:SS.mmmZ');
    }

    validationRound[key] = value;
    setValidationRound({ ...validationRound });

    const errorKey = 'validation_round_' + key;
    clearError(errorKey);
  }
  function validationRoundDetailTextOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    validationRound[key] = value;
    setValidationRound({ ...validationRound });

    const errorKey = 'validation_round_' + key;
    clearError(errorKey);
  }

  function stakingContractOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    stakingContract[key] = value;
    setStakingContract({ ...stakingContract });
  }

  function presaleContractOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    presaleContract[key] = value;
    setPresaleContract({ ...presaleContract });
  }

  function socialDetailOnChange(e, value) {
    const key = e.target.id;
    if (value === undefined || value === null) value = e.target.value;
    socialDetail[key] = value;
    setSocialDetail({ ...socialDetail });
  }

  function convertDateToLocale(date) {
    const isoDate = new Date(date);
    const convertedDate = moment(isoDate).format('YYYY-MM-DDTHH:mm');
    return convertedDate;
  }

  function controlProjectUpdateDetail() {
    const requiredErrors = [];
    //project:
    if (!projectDetail.name) {
      requiredErrors.push('project_detail_name');
    }
    // if (!projectDetail.number_of_registrations) {
    //   requiredErrors.push('number_of_registrations');
    // }
    // if (!projectDetail.number_of_participants) {
    //   requiredErrors.push('number_of_participants');
    // }
    if (!projectDetail.percent_raised) {
      requiredErrors.push('percent_raised');
    }
    if (!projectDetail.target_raised) {
      requiredErrors.push('target_raised');
    }
    if (!projectDetail.total_tokens_sold) {
      requiredErrors.push('total_tokens_sold');
    }
    if (!projectDetail.total_raised) {
      requiredErrors.push('total_raised');
    }
    if (!projectDetail.state) {
      requiredErrors.push('state');
    }
    if (!projectDetail.explanation_text) {
      requiredErrors.push('explanation_text');
    }
    if (!projectDetail.website_url) {
      requiredErrors.push('website_url');
    }
    // if (!projectDetail.withdraw_all) {
    //   requiredErrors.push('withdraw_all');
    // }
    // if (!projectDetail.staking_idle_time) {
    //   requiredErrors.push('staking_idle_time');
    // }
    // if (!projectDetail.is_staking_idle) {
    //   requiredErrors.push('is_staking_idle');
    // }
    // if (!projectDetail.is_airdrop) {
    //   requiredErrors.push('is_airdrop');
    // }
    // if (!projectDetail.sale_type) {
    //   requiredErrors.push('sale_type');
    // }
    if (!roundSale.start_date || roundSale.start_date?.toString().includes('Invalid')) {
      requiredErrors.push('round_sale_start_date');
    }
    if (!roundSale.end_date || roundSale.start_date?.toString().includes('Invalid')) {
      requiredErrors.push('round_sale_end_date');
    }
    if (!registrationRound.start_date && registrationRound.end_date) {
      requiredErrors.push('registration_round_start_date');
    }
    if (registrationRound.start_date && !registrationRound.end_date) {
      requiredErrors.push('registration_round_end_date');
    }

    if (!stakingRound.start_date && stakingRound.end_date) {
      requiredErrors.push('staking_round_start_date');
    }
    if (stakingRound.start_date && !stakingRound.end_date) {
      requiredErrors.push('staking_round_end_date');
    }

    if (!publicRound.start_date && publicRound.end_date) {
      requiredErrors.push('public_round_start_date');
    }
    if (publicRound.start_date && !publicRound.end_date) {
      requiredErrors.push('public_round_end_date');
    }

    if (!privateRound.start_date && privateRound.end_date) {
      requiredErrors.push('private_round_start_date');
    }
    if (privateRound.start_date && !privateRound.end_date) {
      requiredErrors.push('private_round_end_date');
    }

    if (!validationRound.start_date && validationRound.end_date) {
      requiredErrors.push('validation_round_start_date');
    }
    if (validationRound.start_date && !validationRound.end_date) {
      requiredErrors.push('validation_round_end_date');
    }

    setRequiredErrors([...requiredErrors]);
    if (requiredErrors.length) {
      return false;
    } else {
      return true;
    }
  }
  function controlProjectDetail() {
    const requiredErrors = [];
    //project:
    if (!projectDetail.name) {
      requiredErrors.push('project_detail_name');
    }
    if (!projectDetail.number_of_registrations) {
      requiredErrors.push('number_of_registrations');
    }
    if (!projectDetail.number_of_participants) {
      requiredErrors.push('number_of_participants');
    }
    if (!projectDetail.percent_raised) {
      requiredErrors.push('percent_raised');
    }
    if (!projectDetail.target_raised) {
      requiredErrors.push('target_raised');
    }
    if (!projectDetail.total_tokens_sold) {
      requiredErrors.push('total_tokens_sold');
    }
    if (!projectDetail.total_raised) {
      requiredErrors.push('total_raised');
    }
    if (!projectDetail.state) {
      requiredErrors.push('state');
    }
    if (!projectDetail.explanation_text) {
      requiredErrors.push('explanation_text');
    }
    if (!projectDetail.website_url) {
      requiredErrors.push('website_url');
    }
    if (!projectDetail.withdraw_all) {
      requiredErrors.push('withdraw_all');
    }
    if (!projectDetail.staking_idle_time) {
      requiredErrors.push('staking_idle_time');
    }
    if (!projectDetail.is_staking_idle) {
      requiredErrors.push('is_staking_idle');
    }
    if (!projectDetail.is_airdrop) {
      requiredErrors.push('is_airdrop');
    }
    if (!projectDetail.sale_type) {
      requiredErrors.push('sale_type');
    }
    if (!roundSale.start_date || roundSale.start_date?.toString().includes('Invalid')) {
      requiredErrors.push('round_sale_start_date');
    }
    if (!roundSale.end_date || roundSale.start_date?.toString().includes('Invalid')) {
      requiredErrors.push('round_sale_end_date');
    }
    if (!registrationRound.start_date && registrationRound.end_date) {
      requiredErrors.push('registration_round_start_date');
    }
    if (registrationRound.start_date && !registrationRound.end_date) {
      requiredErrors.push('registration_round_end_date');
    }

    if (!stakingRound.start_date && stakingRound.end_date) {
      requiredErrors.push('staking_round_start_date');
    }
    if (stakingRound.start_date && !stakingRound.end_date) {
      requiredErrors.push('staking_round_end_date');
    }

    if (!publicRound.start_date && publicRound.end_date) {
      requiredErrors.push('public_round_start_date');
    }
    if (publicRound.start_date && !publicRound.end_date) {
      requiredErrors.push('public_round_end_date');
    }

    if (!privateRound.start_date && privateRound.end_date) {
      requiredErrors.push('private_round_start_date');
    }
    if (privateRound.start_date && !privateRound.end_date) {
      requiredErrors.push('private_round_end_date');
    }

    if (!validationRound.start_date && validationRound.end_date) {
      requiredErrors.push('validation_round_start_date');
    }
    if (validationRound.start_date && !validationRound.end_date) {
      requiredErrors.push('validation_round_end_date');
    }
    //token:
    if (!tokenDetail.name) {
      requiredErrors.push('token_detail_name');
    }
    if (!tokenDetail.symbol) {
      requiredErrors.push('token_detail_symbol');
    }
    if (!tokenDetail.decimals) {
      requiredErrors.push('token_detail_decimals');
    }
    if (!tokenDetail.address) {
      requiredErrors.push('token_detail_address');
    }
    if (!tokenDetail.owner_address) {
      requiredErrors.push('token_detail_owner_address');
    }
    if (!tokenDetail.all_time_high) {
      requiredErrors.push('token_detail_all_time_high');
    }
    if (!tokenDetail.total_supply) {
      requiredErrors.push('token_detail_total_supply');
    }
    if (!tokenDetail.price_in_usd) {
      requiredErrors.push('token_detail_price_in_usd');
    }
    if (!tokenDetail.price_in_mercurx) {
      requiredErrors.push('token_detail_price_in_mercurx');
    }
    if (!tokenDetail.distribution) {
      requiredErrors.push('token_detail_distribution');
    }
    if (!tokenDetail.current_price) {
      requiredErrors.push('token_detail_current_price');
    }

    setRequiredErrors([...requiredErrors]);
    if (requiredErrors.length) {
      return false;
    } else {
      return true;
    }
  }

  function controlTokenDetail() {
    const requiredErrors = [];

    if (!tokenDetail.name) {
      requiredErrors.push('token_detail_name');
    }
    if (!tokenDetail.symbol) {
      requiredErrors.push('token_detail_symbol');
    }
    if (!tokenDetail.decimals) {
      requiredErrors.push('token_detail_decimals');
    }
    if (!tokenDetail.address) {
      requiredErrors.push('token_detail_address');
    }
    if (!tokenDetail.owner_address) {
      requiredErrors.push('token_detail_owner_address');
    }
    if (!tokenDetail.all_time_high) {
      requiredErrors.push('token_detail_all_time_high');
    }
    if (!tokenDetail.total_supply) {
      requiredErrors.push('token_detail_total_supply');
    }
    if (!tokenDetail.price_in_usd) {
      requiredErrors.push('token_detail_price_in_usd');
    }
    if (!tokenDetail.price_in_mercurx) {
      requiredErrors.push('token_detail_price_in_mercurx');
    }
    if (!tokenDetail.distribution) {
      requiredErrors.push('token_detail_distribution');
    }
    if (!tokenDetail.current_price) {
      requiredErrors.push('token_detail_current_price');
    }

    setRequiredErrors([...requiredErrors]);
    if (requiredErrors.length) {
      return false;
    } else {
      return true;
    }
  }

  function controlDate() {
    if (roundSale.end_date && roundSale.start_date && roundSale.end_date < roundSale.start_date) {
      DateErrors.push('round_sale_end_date');
    }
    if (roundSale.end_date && roundSale.start_date && roundSale.end_date < roundSale.start_date) {
      DateErrors.push('round_sale_end_date');
    }
    if (registrationRound.end_date < registrationRound.start_date) {
      DateErrors.push('registration_round_end_date');
    }
    if (stakingRound.end_date < stakingRound.start_date) {
      DateErrors.push('staking_round_end_date');
    }
    if (publicRound.end_date < publicRound.start_date) {
      DateErrors.push('public_round_end_date');
    }
    if (privateRound.end_date < privateRound.start_date) {
      DateErrors.push('private_round_end_date');
    }
    if (validationRound.end_date < validationRound.start_date) {
      DateErrors.push('validation_round_end_date');
    }
    setDateErrors([...DateErrors]);
    console.log(DateErrors.length);
    if (DateErrors.length) {
      return false;
    } else {
      return true;
    }
  }

  function controlInputs(key) {
    const errors = new Set(requiredErrors);
    const dateError = new Set(DateErrors);
    if (errors.has(key) || dateError.has(key)) {
      return true;
    } else {
      return false;
    }
  }

  function clearError(key) {
    const errors = new Set(requiredErrors);
    const dateError = new Set(DateErrors);
    if (errors.has(key) || dateError.has(key)) {
      errors.delete(key);
      dateError.delete(key);
    }
    setRequiredErrors([...errors]);
    setDateErrors([...dateError]);
  }

  function updateProject() {
    if (controlProjectUpdateDetail() && controlDate()) {
      projectDetail.social = socialDetail;
      if (roundSale.start_date && roundSale.end_date) {
        projectDetail.round_sale = roundSale;
      } else {
        delete projectDetail.round_sale;
      }

      if (registrationRound.start_date && registrationRound.end_date) {
        projectDetail.round_registration = registrationRound;
      } else {
        delete projectDetail.round_registration;
      }

      // projectDetail.round_registration = registrationRound;

      if (stakingRound.start_date && stakingRound.end_date) {
        projectDetail.round_staking = stakingRound;
      } else {
        delete projectDetail.round_staking;
      }
      //  projectDetail.round_staking = stakingRound;
      if (publicRound.start_date && publicRound.end_date) {
        projectDetail.round_publicsale = publicRound;
      } else {
        delete projectDetail.round_publicsale;
      }
      // projectDetail.round_publicsale = publicRound;
      if (privateRound.start_date && privateRound.end_date) {
        projectDetail.round_privatesale = privateRound;
      } else {
        delete projectDetail.round_privatesale;
      }
      // projectDetail.round_privatesale = privateRound;
      if (validationRound.start_date && validationRound.end_date) {
        projectDetail.round_validation = validationRound;
      } else {
        delete projectDetail.round_validation;
      }
      // projectDetail.round_validation = validationRound;
      delete projectDetail.token;
      const payload = {
        projectID: project.id,
        body: projectDetail,
      };

      updateProjectRequest(payload);
      setTimeout(() => {
        history.goBack();
      }, 2000);
    }
  }

  function createProject() {
    if (controlProjectDetail() && controlDate()) {
      if (roundSale.start_date && roundSale.end_date) {
        projectDetail.round_sale = roundSale;
      } else {
        delete projectDetail.round_sale;
      }
      if (registrationRound.start_date && registrationRound.end_date) {
        projectDetail.round_registration = registrationRound;
      } else {
        delete projectDetail.round_registration;
      }
      if (stakingRound.start_date && stakingRound.end_date) {
        projectDetail.round_staking = stakingRound;
      } else {
        delete projectDetail.round_staking;
      }
      if (publicRound.start_date && publicRound.end_date) {
        projectDetail.round_publicsale = publicRound;
      } else {
        delete projectDetail.round_publicsale;
      }
      if (privateRound.start_date && privateRound.end_date) {
        projectDetail.round_privatesale = privateRound;
      } else {
        delete projectDetail.round_privatesale;
      }
      if (validationRound.start_date && validationRound.end_date) {
        projectDetail.round_validation = validationRound;
      } else {
        delete projectDetail.round_validation;
      }

      projectDetail.social = socialDetail;
      projectDetail.token = tokenDetail;
      projectDetail.token.staking_contract = stakingContract;
      projectDetail.token.presale_contract = presaleContract;

      const payload = {
        body: projectDetail,
      };
      createProjectRequest(payload);
      setTimeout(() => {
        history.goBack();
      }, 2000);
   
    }
  }

  function createToken() {
    if (controlTokenDetail()) {
      if (tokenDetail.staking_contract) {
        tokenDetail.staking_contract = stakingContract;
      } else {
        delete tokenDetail.staking_contract;
      }
      if (tokenDetail.presale_contract) {
        tokenDetail.presale_contract = presaleContract;
      } else {
        delete tokenDetail.presale_contract;
      }
      const payload = {
        body: tokenDetail,
      };

      createTokenRequest(payload);
      setTimeout(() => {
        history.goBack();
      }, 2000);
    }
  }
  function updateToken() {
    if (controlTokenDetail()) {
      if (tokenDetail.staking_contract) {
        tokenDetail.staking_contract = stakingContract;
      } else {
        delete tokenDetail.staking_contract;
      }
      if (tokenDetail.presale_contract) {
        tokenDetail.presale_contract = presaleContract;
      } else {
        delete tokenDetail.presale_contract;
      }
      const payload = {
        tokenID: token.id,
        body: tokenDetail,
      };

      updateTokenRequest(payload);
      setTimeout(() => {
        history.goBack();
      }, 2000);
    }
  }

  if (isEdit && !project && !token) {
    return null;
  }

  return (
    <div className="project-detail">
      <Accordion className="mt-5 shadow" defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
        {showProject && (
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="text-fs-head-sm">Project</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      id="name"
                      type="text"
                      value={projectDetail.name}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('project_detail_name')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Number of Registrations*</Form.Label>
                    <Form.Control
                      id="number_of_registrations"
                      type="number"
                      value={projectDetail.number_of_registrations}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('number_of_registrations')}

                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Number of Participants*</Form.Label>
                    <Form.Control
                      id="number_of_participants"
                      type="number"
                      value={projectDetail.number_of_participants}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('number_of_participants')}

                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Percent Raised*</Form.Label>
                    <Form.Control
                      id="percent_raised"
                      type="number"
                      value={projectDetail.percent_raised}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('percent_raised')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Target Raised*</Form.Label>
                    <Form.Control
                      id="target_raised"
                      type="number"
                      value={projectDetail.target_raised}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('target_raised')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Explanation Text*</Form.Label>
                    <Form.Control
                      id="explanation_text"
                      as="textarea"
                      value={projectDetail.explanation_text}
                      onChange={projectDetailOnChange}
                      rows={3}
                      isInvalid={controlInputs('explanation_text')}

                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Staking Idle Time*</Form.Label>
                    <Form.Control
                      id="staking_idle_time"
                      type="datetime-local"
                      value={convertDateToLocale(projectDetail.staking_idle_time)}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('staking_idle_time')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Sale Start Date*</Form.Label>
                    <Form.Control
                      id="start_date"
                      type="datetime-local"
                      value={convertDateToLocale(roundSale.start_date)}
                      onChange={roundSaleDetailOnChange}
                      isInvalid={controlInputs('round_sale_start_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Sale End Date*</Form.Label>
                    <Form.Control
                      id="end_date"
                      type="datetime-local"
                      value={convertDateToLocale(roundSale.end_date)}
                      onChange={roundSaleDetailOnChange}
                      isInvalid={controlInputs('round_sale_end_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Registration Round Start Date</Form.Label>
                    <Form.Control
                      id="start_date"
                      type="datetime-local"
                      value={convertDateToLocale(registrationRound.start_date)}
                      onChange={registrationRoundDetailOnChange}
                      isInvalid={controlInputs('registration_round_start_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Registration Round End Date</Form.Label>
                    <Form.Control
                      id="end_date"
                      type="datetime-local"
                      value={convertDateToLocale(registrationRound.end_date)}
                      onChange={registrationRoundDetailOnChange}
                      isInvalid={controlInputs('registration_round_end_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Registration Round Description</Form.Label>
                    <Form.Control
                      id="description"
                      type="text"
                      value={registrationRound.description}
                      onChange={registrationRoundDetailTextOnChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Staking Round Start Date</Form.Label>
                    <Form.Control
                      id="start_date"
                      type="datetime-local"
                      value={convertDateToLocale(stakingRound.start_date)}
                      onChange={stakingRoundDetailOnChange}
                      isInvalid={controlInputs('staking_round_start_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Staking Round End Date</Form.Label>
                    <Form.Control
                      id="end_date"
                      type="datetime-local"
                      value={convertDateToLocale(stakingRound.end_date)}
                      onChange={stakingRoundDetailOnChange}
                      isInvalid={controlInputs('staking_round_end_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Staking Round Description</Form.Label>
                    <Form.Control
                      id="description"
                      type="text"
                      value={stakingRound.description}
                      onChange={stakingRoundDetailTextOnChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>Total Raised*</Form.Label>
                    <Form.Control
                      id="total_raised"
                      type="number"
                      value={projectDetail.total_raised}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('total_raised')}

                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Total Tokens Sold*</Form.Label>
                    <Form.Control
                      id="total_tokens_sold"
                      type="number"
                      value={projectDetail.total_tokens_sold}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('total_tokens_sold')}

                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>State*</Form.Label>
                    <Form.Control
                      id="state"
                      type="text"
                      value={projectDetail.state}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('state')}

                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Website URL*</Form.Label>
                    <Form.Control
                      id="website_url"
                      type="text"
                      value={projectDetail.website_url}
                      onChange={projectDetailOnChange}
                      isInvalid={controlInputs('website_url')}

                    />
                  </Form.Group>
                  <div className="d-flex justify-content-around">
                    <Form.Group className="mt-2">
                      <Form.Label>Sale Type*</Form.Label>
                      <div className="d-flex">
                        <Form.Check
                          className="me-3"
                          id="sale_type"
                          type="radio"
                          label="ido"
                          checked={projectDetail.sale_type === 'ido'}
                          onChange={(e) => projectDetailOnChange(e, 'ido')}
                          isInvalid={controlInputs('sale_type')}
                        />
                        <Form.Check
                          id="sale_type"
                          type="radio"
                          label="ico"
                          checked={projectDetail.sale_type === 'ico'}
                          onChange={(e) => projectDetailOnChange(e, 'ico')}
                          isInvalid={controlInputs('sale_type')}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mt-2">
                      <Form.Label>Staking Idle*</Form.Label>
                      <div className="d-flex">
                        <Form.Check
                          className="me-3"
                          id="is_staking_idle"
                          type="radio"
                          label="True"
                          checked={projectDetail.is_staking_idle}
                          onChange={(e) => projectDetailOnChange(e, true)}
                        />
                        <Form.Check
                          id="is_staking_idle"
                          type="radio"
                          label="False"
                          checked={!projectDetail.is_staking_idle}
                          onChange={(e) => projectDetailOnChange(e, false)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-around">
                    <Form.Group className="mt-2">
                      <Form.Label>Airdrop*</Form.Label>
                      <div className="d-flex">
                        <Form.Check
                          className="me-3"
                          id="is_airdrop"
                          type="radio"
                          label="True"
                          checked={projectDetail.is_airdrop}
                          onChange={(e) => projectDetailOnChange(e, true)}
                        />
                        <Form.Check
                          id="is_airdrop"
                          type="radio"
                          label="False"
                          checked={!projectDetail.is_airdrop}
                          onChange={(e) => projectDetailOnChange(e, false)}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mt-2">
                      <Form.Label>Withdraw All*</Form.Label>
                      <div className="d-flex">
                        <Form.Check
                          className="me-3"
                          id="withdraw_all"
                          type="radio"
                          label="True"
                          checked={projectDetail.withdraw_all}
                          onChange={(e) => projectDetailOnChange(e, true)}
                        />
                        <Form.Check
                          id="withdraw_all"
                          type="radio"
                          label="False"
                          checked={!projectDetail.withdraw_all}
                          onChange={(e) => projectDetailOnChange(e, false)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <Form.Group className="mt-2">
                    <Form.Label>Public Sale Start Date</Form.Label>
                    <Form.Control
                      id="start_date"
                      type="datetime-local"
                      value={convertDateToLocale(publicRound.start_date)}
                      onChange={publicRoundDetailOnChange}
                      isInvalid={controlInputs('public_round_start_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Public Sale End Date</Form.Label>
                    <Form.Control
                      id="end_date"
                      type="datetime-local"
                      value={convertDateToLocale(publicRound.end_date)}
                      onChange={publicRoundDetailOnChange}
                      isInvalid={controlInputs('public_round_end_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Public Sale Description</Form.Label>
                    <Form.Control
                      id="description"
                      type="text"
                      value={publicRound.description}
                      onChange={publicRoundDetailTextOnChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Private Sale Start Date</Form.Label>
                    <Form.Control
                      id="start_date"
                      type="datetime-local"
                      value={convertDateToLocale(privateRound.start_date)}
                      onChange={privateRoundDetailOnChange}
                      isInvalid={controlInputs('private_round_start_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Private Sale End Date</Form.Label>
                    <Form.Control
                      id="end_date"
                      type="datetime-local"
                      value={convertDateToLocale(privateRound.end_date)}
                      onChange={privateRoundDetailOnChange}
                      isInvalid={controlInputs('private_round_end_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Private Sale Description</Form.Label>
                    <Form.Control
                      id="description"
                      type="text"
                      value={privateRound.description}
                      onChange={privateRoundDetailTextOnChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Validation Round Sale Start Date</Form.Label>
                    <Form.Control
                      id="start_date"
                      type="datetime-local"
                      value={convertDateToLocale(validationRound.start_date)}
                      onChange={validationRoundDetailOnChange}
                      isInvalid={controlInputs('validation_round_start_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Validation Round Sale End Date</Form.Label>
                    <Form.Control
                      id="end_date"
                      type="datetime-local"
                      value={convertDateToLocale(validationRound.end_date)}
                      onChange={validationRoundDetailOnChange}
                      isInvalid={controlInputs('validation_round_end_date')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Validation Round Sale Description</Form.Label>
                    <Form.Control
                      id="description"
                      type="text"
                      value={validationRound.description}
                      onChange={validationRoundDetailTextOnChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {showToken && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="text-fs-head-sm">Token</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>Token Name*</Form.Label>
                    <Form.Control
                      id="name"
                      type="text"
                      value={tokenDetail.name}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_name')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Token Symbol*</Form.Label>
                    <Form.Control
                      id="symbol"
                      type="text"
                      value={tokenDetail.symbol}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_symbol')}

                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Token Decimals*</Form.Label>
                    <Form.Control
                      id="decimals"
                      type="number"
                      value={tokenDetail.decimals}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_decimals')}

                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Token Address*</Form.Label>
                    <Form.Control
                      id="address"
                      type="text"
                      value={tokenDetail.address}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_address')}

                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Token Owner Address*</Form.Label>
                    <Form.Control
                      id="owner_address"
                      type="text"
                      value={tokenDetail.owner_address}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_owner_address')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>All Time High*</Form.Label>
                    <Form.Control
                      id="all_time_high"
                      type="number"
                      value={tokenDetail.all_time_high}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_all_time_high')}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>Total Supply*</Form.Label>
                    <Form.Control
                      id="total_supply"
                      type="number"
                      value={tokenDetail.total_supply}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_total_supply')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Price in USD*</Form.Label>
                    <Form.Control
                      id="price_in_usd"
                      type="number"
                      value={tokenDetail.price_in_usd}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_price_in_usd')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Price in Mercurx*</Form.Label>
                    <Form.Control
                      id="price_in_mercurx"
                      type="number"
                      value={tokenDetail.price_in_mercurx}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_price_in_mercurx')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Distribution*</Form.Label>
                    <Form.Control
                      id="distribution"
                      type="number"
                      value={tokenDetail.distribution}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_distribution')}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Current Price*</Form.Label>
                    <Form.Control
                      id="current_price"
                      type="number"
                      value={tokenDetail.current_price}
                      onChange={tokenDetailOnChange}
                      isInvalid={controlInputs('token_detail_current_price')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {showContract && (
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="text-fs-head-sm">Contract</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>Staking Contract Address</Form.Label>
                    <Form.Control
                      id="contract_address"
                      type="text"
                      value={stakingContract.contract_address}
                      onChange={stakingContractOnChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Staking Contract Owner Address</Form.Label>
                    <Form.Control
                      id="contract_owner_address"
                      type="text"
                      value={stakingContract.contract_owner_address}
                      onChange={stakingContractOnChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Staking Reward Token Address</Form.Label>
                    <Form.Control
                      id="reward_token_address"
                      type="text"
                      value={stakingContract.reward_token_address}
                      onChange={stakingContractOnChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Staking Reward Token Owner Address</Form.Label>
                    <Form.Control
                      id="reward_token_owner_address"
                      type="text"
                      value={stakingContract.reward_token_owner_address}
                      onChange={stakingContractOnChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>Presale Contract Address</Form.Label>
                    <Form.Control
                      id="contract_address"
                      type="text"
                      value={presaleContract.contract_address}
                      onChange={presaleContractOnChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Presale Contract Owner Address</Form.Label>
                    <Form.Control
                      id="contract_owner_address"
                      type="text"
                      value={presaleContract.contract_owner_address}
                      onChange={presaleContractOnChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {showSocial && (
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <div className="text-fs-head-sm">Social</div>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>Twitter</Form.Label>
                    <Form.Control
                      id="twitter"
                      type="text"
                      value={socialDetail.twitter}
                      onChange={socialDetailOnChange}
                    />
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Form.Label>Telegram</Form.Label>
                    <Form.Control
                      id="telegram"
                      type="text"
                      value={socialDetail.telegram}
                      onChange={socialDetailOnChange}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mt-2">
                    <Form.Label>Discord</Form.Label>
                    <Form.Control
                      id="discord"
                      type="text"
                      value={socialDetail.discord}
                      onChange={socialDetailOnChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>

      <div className="text-end">
        {requiredErrors.length > 0 ? (
          <div className="text-danger mt-2">*Required fields must be fill.</div>
        ) : (
          <></>
        )}
        {DateErrors.length > 0 ? (
          <div className="text-danger mt-2">*The date is invalid.</div>
        ) : (
          <></>
        )}
        {isEdit && showProject && user.is_superuser &&(
          <Button className="mt-2" onClick={updateProject}>
            update Project
          </Button>
        )}
        {!isEdit && showProject && user.is_superuser && (
          <Button className="mt-2" onClick={createProject}>
            Create Project
          </Button>
        )}
        {!showProject && isEdit && showToken && user.is_superuser && (
          <Button className="mt-2" onClick={updateToken}>
            update Token
          </Button>
        )}
        {!showProject && !isEdit && showToken && user.is_superuser && (
          <Button className="mt-2" onClick={createToken}>
            Create Token
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    project: state.projectReducer.project,
    // token: state.tokenReducer.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createProjectRequest: (payload) => {
      dispatch(createProjectActionRequest(payload));
    },
    updateProjectRequest: (payload) => {
      dispatch(updateProjectActionRequest(payload));
    },
    createTokenRequest: (payload) => {
      dispatch(createTokenActionRequest(payload));
    },
    updateTokenRequest: (payload) => {
      dispatch(updateTokenActionRequest(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurXProjectDetailInputs);

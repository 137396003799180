import React from 'react';
import './MercurxContractPresale.scss';
import { connect } from 'react-redux';
// eslint-disable-next-line max-len
import MercurxContractPresaleDetailInputs from '../../components/MercurxContractPresaleDetailInputs/MercurxContractPresaleDetailInputs';
// import { useHistory, useLocation } from 'react-router';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import {
  walletAccountDetailModalAction,
  WalletConnectModalAction,
} from '../../store/wallet/walletActions';
import wallet from '../../helpers/wallet';
import { useEffect } from 'react';
// eslint-disable-next-line max-len
import MercurXWalletAccountDetailModal from '../../components/MercurXWalletAccountDetailModal/MercurXWalletAccountDetailModal'
// eslint-disable-next-line max-len
// eslint-disable-next-line max-len
import MercurXWalletConnectModal from '../../components/MercurXWalletConnectModal/MercurXWalletConnectModal';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';
import { getTierRequestAction } from '../../store/tier/tierActions';


function MercurxContractPreale({ ...props }) {

  const {
    accounts,
    balance_,
    walletAccountDetailModalRequest,
    WalletConnectModalRequest,
    getTierRequest,
  } = props;
  const { utils } = require('ethers');
  const handleShow = () => {
    walletAccountDetailModalRequest(true);
  };
  const handleShowWallet = () => {
    WalletConnectModalRequest(true);
  };
  useEffect(() => {
    if (accounts?.[0]) {
      wallet.getMyBalance('0xD840FA31Ee4271112171C2313e7d1928e0CB3D19');
      const address = utils.getAddress(accounts?.[0]);
      const payload = {
        id: address,
      };
      getTierRequest(payload);
    }
  }, [accounts]);
  function calculateBalance() {
    const formatBalance = (Math.floor((Number(balance_))) / (10**9)).toString().split('.');
      // TODO: 10**9 or 1000000000 = projects.token.decimals
      const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
      const secondValueFormatBalance = formatBalance[1] ?
       formatBalance[1].padEnd(4, '0').slice(0, 4) : "0000";
      return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  const user = JSON.parse(localStorage.getItem('user'))?.user;
    return (
      user.is_superuser && ( 
      <div>
      {<div class="container">
        <div class="row">
          <div class="col-sm">
            <div className="text-fs-head-sm">Mercurx Presale Contract</div>
          </div>
          <div class="col-sm">
      <div className='right-aligned-container '>
        {!accounts?.[0] && (
          <Nav.Link
            as={Link}
            className={'text-fs-head-xs px-2 public-nav'}
            to="#"
            onClick={handleShowWallet}
          >
            Connect Wallet
          </Nav.Link>
        )}
        {accounts?.[0] && (
          <div
            className={
              'd-flex justify-content-center justify-content-lg-end rounded text-white ' +
              'm-auto mx-lg-3' +
              (balance_ !== null && balance_ !== undefined
                ? ' bg-primary navbar-wallet-account-container'
                : '')
            }
          >
            {balance_ !== null && balance_ !== undefined && (
              <div
                title={calculateBalance()}
                className="d-none d-sm-flex align-items-center navbar-balance-button 
                bg-primary rounded px-2 justify-content-center"
              >
                <div className="navbar-balance-text text-truncate mr-1 py-1">
                  {' '}
                  {calculateBalance()}
                </div>
                <span>MERX</span>
              </div>
            )}

            <div
              title={accounts?.[0]}
              className="navbar-account-button
                bg-white rounded text-tertiary text-fs-head-xxs d-flex align-items-center px-2"
                style={{ border: '2px solid', borderColor: mainColors['primary'] }}
              onClick={handleShow}
            >
              <span className="text-truncate me-1 ps-3 pe-2 py-1">{accounts?.[0]}</span>
              <MercurXIcons name="BiWallet" color={mainColors['primary']} size="24" />
              </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      }
      <MercurxContractPresaleDetailInputs />
      <MercurXWalletAccountDetailModal />
      <MercurXWalletConnectModal />
    </div>
    )
    );
}
const mapStateToProps = (state) => {
  return {
    accounts: state.walletReducer.accounts,
    balance_: state.walletReducer.balance_,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    walletAccountDetailModalRequest: (payload) => {
      dispatch(walletAccountDetailModalAction(payload));
    },
    WalletConnectModalRequest: (payload) => {
      dispatch(WalletConnectModalAction(payload));
    },
    getTierRequest: (payload) => {
      dispatch(getTierRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MercurxContractPreale);

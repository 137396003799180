import * as types from './bo_transactionActionTypes';

export const getBackofficeTransactionsActionRequest = (payload) => {
  return {
    type: types.GET_BACKOFFICETRANSACTIONS_REQUEST,
    payload,
  };
};


export const getBackofficeTransactionsActionData = (payload) => {
  return {
    type: types.GET_BACKOFFICETRANSACTIONS_DATA,
    payload,
  };
};

export const getBackofficeTransactionsActionError = (payload) => {
  return {
    type: types.GET_BACKOFFICETRANSACTIONS_ERROR,
    payload,
  };
};
export const updateQuickFilterAction = (payload) => {
  return {
    type: types.UPDATE_QUICK_FILTER,
    payload,
  };
};

export const filterBackofficeTransactionsAction = (payload) => {
  return {
    type: types.FILTER_BACKOFFICETRANSACTIONS,
    payload,
  };
};

export const setBackofficeTransactionsSortDataAction = (payload) => {
  return {
    type: types.SET_BACKOFFICETRANSACTIONS_SORT_DATA,
    payload,
  };
};
export const sortBackofficeTransactionsAction = (payload) => {
  return {
    type: types.SORT_BACKOFFICETRANSACTIONS,
    payload,
  };
};


export const transactionRequest = (creds) => {
  
  return {
    
    type: types.TRANSACTION_REQUEST,
    creds,
  };
};
export const transactionData = (payload) => {
  return {
    type: types.TRANSACTION_DATA,
    payload,
  };
};
export const transactionError = (payload) => {
  return {
    type: types.TRANSACTION_ERROR,
    payload,
  };
};
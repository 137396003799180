import { takeEvery, put, all, call } from 'redux-saga/effects';
import * as types from './launchpadUserActionTypes';
import * as actions from './launchpadUserActions';
import * as endpoints from '../../services/endpoints';


// function* getLaunchpadUserSaga(action) {
//   try {
    
//     const { data } = yield call(endpoints.getLaunchpadUsers);
//     console.log(data)
//     yield put(actions.getLaunchpadUsersActionData(data));
//     // yield put (projectActions.getProjectByIDData(data))
//   } catch (e) {
//     console.log(e)
//     yield put(actions.getLaunchpadUsersActionError(e));
//   }
// }


function* getLaunchpadUserSaga(action) {
  try {
    const { data } = yield call(endpoints.getLaunchpadUsers);
    yield put(actions.getLaunchpadUsersActionData(data));
  } catch (e) {
    console.log(e)
    yield put(actions.getLaunchpadUsersActionError(e));
  }
}

function* watchGetLaunchpadUser() {
  yield takeEvery(types.GET_LAUNCHPADUSERS_REQUEST, getLaunchpadUserSaga);
}


export function* launchpadUserSaga() {
  yield all([
    watchGetLaunchpadUser(),
  ]);
}

import * as Bootstrap from 'react-icons/bs';
import * as BoxIcons from 'react-icons/bi';
import { TbSettings,Tb2Fa } from "react-icons/tb";
import * as Material from 'react-icons/md';
function MercurXIcons({ ...props }) {
  const { className, iconClassName, style, iconStyle, name, color, size } = props;

  function getIcon() {
    if (name === 'BsChevronRight') {
      return (
        <Bootstrap.BsChevronRight
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsChevronLeft') {
      return (
        <Bootstrap.BsChevronLeft
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } 
    else if (name === 'BsEye') {
      return (
        <Bootstrap.BsEye
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    }
    else if (name === 'BsEyeSlash') {
      return (
        <Bootstrap.BsEyeSlash
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    }  else if (name === 'BsHouse') {
      return (
        <Bootstrap.BsHouse className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } else if (name === 'BsArrowBarRight') {
      return (
        <Bootstrap.BsArrowBarRight
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsFolder') {
      return (
        <Bootstrap.BsFolder className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } else if (name === 'BsActivity') {
      return (
        <Bootstrap.BsActivity
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsPerson') {
      return (
        <Bootstrap.BsPerson className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } else if (name === 'BsFileText') {
      return (
        <Bootstrap.BsFileText
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsFileCode') {
      return (
        <Bootstrap.BsFileCode
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsLayoutTextWindowReverse') {
      return (
        <Bootstrap.BsLayoutTextWindowReverse
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsCoin') {
      return (
        <Bootstrap.BsCoin className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } else if (name === 'BsCoin') {
      return (
        <Bootstrap.BsCoin className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } else if (name === 'BsCpu') {
      return (
        <Bootstrap.BsCpu className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } else if (name === 'BsFileLock') {
      return (
        <Bootstrap.BsFileLock
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsPersonGear') {
      return (
        <Bootstrap.BsPersonGear
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsPersonExclamation') {
      return (
        <Bootstrap.BsPersonExclamation
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsFileEarmarkSpreadsheet') {
      return (
        <Bootstrap.BsFileEarmarkSpreadsheet
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsFileEarmarkLock2') {
      return (
        <Bootstrap.BsFileEarmarkLock2
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsFileEarmarkPerson') {
      return (
        <Bootstrap.BsFileEarmarkPerson
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsCodeSlash') {
      return (
        <Bootstrap.BsCodeSlash
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsCode') {
      return (
        <Bootstrap.BsCode className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } else if (name === 'BsPencilSquare') {
      return (
        <Bootstrap.BsPencilSquare
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BsTrash3') {
      return (
        <Bootstrap.BsTrash3 className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } else if (name === 'BsArrowLeftShort') {
      return (
        <Bootstrap.BsArrowLeftShort
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } else if (name === 'BiSortDown') {
      return (
        <BoxIcons.BiSortDown
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } 
    else if (name === 'BiCopy') {
      return (
        <BoxIcons.BiCopy className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } 
    else if (name === 'BiExport') {
      return (
        <BoxIcons.BiExport className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } 
    else if (name === 'BiHistory') {
      return (
        <BoxIcons.BiHistory className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } 
    else if (name === 'BiExit') {
      return (
        <BoxIcons.BiExit className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    } 
    else if (name === 'BiWallet') {
      return (
        <BoxIcons.BiWallet className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    }
    else if (name === 'BsFileEarmarkBarGraphFill') {
      return (
        <Bootstrap.BsFileEarmarkBarGraphFill
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    }
    else if (name === 'BsFillInfoSquareFill') {
      return (
        <Bootstrap.BsFillInfoSquareFill
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    }else if (name === 'BiSortUp') {
      return (
        <BoxIcons.BiSortUp className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    }
    else if (name === 'BiCopy') {
      return (
        <BoxIcons.BiCopy className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    }
    else if (name === 'BsListTask') {
      return (
        <Bootstrap.BsListTask 
        className={iconClassName} 
        style={iconStyle} 
        color={color} 
        size={size} 
        />
      );
    }
    else if (name === 'TbSettings') {
      return (
        <TbSettings 
        className={iconClassName} 
        style={iconStyle} 
        color={color} 
        size={size} 
        />
      );
    }
    else if (name === 'MdPerson') {
      return (
        <Material.MdPerson className={iconClassName} style={iconStyle} color={color} size={size} />
      );
    }
    else if (name === 'BiCheckShield') {
      return (
        <BoxIcons.BiCheckShield
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    }
    else if (name === 'Tb2Fa') {
      return (
        <Tb2Fa
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    }
    else if (name === 'BiBook') {
      return (
        <BoxIcons.BiBook
          className={iconClassName}
          style={iconStyle}
          color={color}
          size={size}
        />
      );
    } 
  }

  return (
    <span className={'d-flex align-items-center justify-content-center ' + className} style={style}>
      {getIcon()}
    </span>
  );
}

export default MercurXIcons;

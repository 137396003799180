import React from 'react';
import './EditProject.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
// eslint-disable-next-line max-len
import MercurXProjectDetailInputs from '../../components/MercurXProjectDetailInputs/MercurXProjectDetailInputs';
import { useHistory, useLocation } from 'react-router';

function EditProject({ ...props }) {
  const history = useHistory();
  const location = useLocation();
  const project = location.state?.project;

  if (!project) {
    history.goBack();
  }

  if (project) {
    return (
      <div>
        <MercurXBackButton buttonText="Back to project list" />
        <div className="text-fs-head-sm">Edit project</div>
        <MercurXProjectDetailInputs isEdit={true} showProject={true} showToken={false}
         showSocial={true} showContract={false}/>
      </div>
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);

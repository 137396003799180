import React from 'react';
import './EditBackofficeUser.scss';
import { connect } from 'react-redux';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
// eslint-disable-next-line max-len
import MercurXBackofficeUserDetailInputs from '../../components/MercurXBackofficeUserDetailInputs/MercurXBackofficeUserDetailInputs';
import { useHistory, useLocation } from 'react-router';

function EditBackofficeUser({ ...props }) {
  const history = useHistory();
  const location = useLocation();
  const backofficeUser = location.state?.backofficeUser;

  if (!backofficeUser) {
   history.goBack();
  }

  if (backofficeUser) {
    return (
      <div>
        <MercurXBackButton buttonText="Back to backofficeUser list" />
        <div className="text-fs-head-sm">Edit Backoffice User</div>
        <MercurXBackofficeUserDetailInputs isEdit={true} />
      </div>
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBackofficeUser);

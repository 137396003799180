import * as types from './projectActionTypes';

export const getProjectsActionRequest = (payload) => {
  return {
    type: types.GET_PROJECTS_REQUEST,
    payload,
  };
};

export const getProjectsActionData = (payload) => {
  return {
    type: types.GET_PROJECTS_DATA,
    payload,
  };
};

export const getProjectsActionError = (payload) => {
  return {
    type: types.GET_PROJECTS_ERROR,
    payload,
  };
};

export const createProjectActionRequest = (payload) => {
  return {
    type: types.CREATE_PROJECT_REQUEST,
    payload,
  };
};

export const createProjectActionData = (payload) => {
  return {
    type: types.CREATE_PROJECT_DATA,
    payload,
  };
};

export const createProjectActionError = (payload) => {
  return {
    type: types.CREATE_PROJECT_ERROR,
    payload,
  };
};

export const updateProjectActionRequest = (payload) => {
  return {
    type: types.UPDATE_PROJECT_REQUEST,
    payload,
  };
};

export const updateProjectActionData = (payload) => {
  return {
    type: types.UPDATE_PROJECT_DATA,
    payload,
  };
};

export const updateProjectActionError = (payload) => {
  return {
    type: types.UPDATE_PROJECT_ERROR,
    payload,
  };
};

export const deleteProjectActionRequest = (payload) => {
  return {
    type: types.DELETE_PROJECT_REQUEST,
    payload,
  };
};

export const deleteProjectActionData = (payload) => {
  return {
    type: types.DELETE_PROJECT_DATA,
    payload,
  };
};

export const deleteProjectActionError = (payload) => {
  return {
    type: types.DELETE_PROJECT_ERROR,
    payload,
  };
};

export const updateQuickFilterAction = (payload) => {
  return {
    type: types.UPDATE_QUICK_FILTER,
    payload,
  };
};

export const filterProjectsAction = (payload) => {
  return {
    type: types.FILTER_PROJECTS,
    payload,
  };
};

export const setProjectsSortDataAction = (payload) => {
  return {
    type: types.SET_PROJECTS_SORT_DATA,
    payload,
  };
};
export const sortProjectsAction = (payload) => {
  return {
    type: types.SORT_PROJECTS,
    payload,
  };
};
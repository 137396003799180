const PROJECTS_SORT_TYPES = {
  ASCENDING: 0,
  DESCENDING: 1,
};

const PROJECTS_SORT_KEYS = {
  TOKEN_NAME: 0,
  TOKEN_SYMBOL: 1,
  TOKEN_ADDRESS: 2,
};

const sortTypes = [
  {
    name: 'Ascending',
    icon: 'BiSortUp',
  },
  {
    name: 'Descending',
    icon: 'BiSortDown',
  },
];

const sortKeys = [
  { name: 'Project Name', key: 'name' },
  { name: 'Token Symbol', key: 'symbol' },
];

const projectsQuickFilterConstants = {
  ALL: 1,
  ACTIVE: 2,
  COMPLETED: 3,
};

export {
  PROJECTS_SORT_KEYS,
  PROJECTS_SORT_TYPES,
  sortTypes,
  sortKeys,
  projectsQuickFilterConstants,
};

import { useEffect, useState } from 'react';
import { Badge, Pagination, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import './MercurXProjectTable.scss';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';
import {
  deleteProjectActionRequest,
} from '../../store/project/projectActions';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function MercurXProjectTable(props) {
  const { deleteProjectRequest, projects, filteredProjects } = props;
  const history = useHistory();

  const maxRowCountPerPage = 10;
  const maxShowingPage = 5;
  const [sourceData, setSourceData] = useState(filteredProjects ? filteredProjects : projects);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedProjects, setRenderedProjects] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] = useState(maxShowingPage);

  useEffect(() => {
    setSourceData(filteredProjects ? filteredProjects : projects);

  }, [projects, filteredProjects]);

  useEffect(() => {
    if (sourceData) {
      setCurrentPage(1);
      const tempPages = [];
      for (let index = 0; index < Math.ceil(sourceData.length / maxRowCountPerPage); index++) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [sourceData]);

  useEffect(() => {
    if (sourceData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = sourceData?.slice(firstIndex, lastIndex);
      setRenderedProjects([...tempRendered]);
    }
  }, [currentPage, sourceData]);

  function scrollToTokensContainer() {
    const projectsContainer = document.getElementById('projects-container');
    if (projectsContainer) {
      projectsContainer.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
        maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
        maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToTokensContainer();
  };

  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToTokensContainer();
  };

  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToTokensContainer();
    }
  };

  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToTokensContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToTokensContainer();
  };

  function editProject(project) {
    history.push({
      pathname: '/projects/edit-project',
      state: {
        project: project,
      },
    });
  }

  function deleteProject(projectID, projectIndex) {
    Swal.fire({
      icon: 'warning',
      iconColor: mainColors.warning,
      text: 'This process can not be undo. Are you sure?',
      showCancelButton: true,
    }).then((control) => {
      if (control.isConfirmed) {
        const payload = {
          projectID,
          projectIndex,
        };
        deleteProjectRequest(payload);
      }
    });
  }
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  function calculateFormat(x) {

    const formatBalance = (Math.floor((Number(x) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  return (
    <>
      <Table className="projects-table-design" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Project Name</th>
            <th>Token Symbol</th>
            <th>Token Price in MERX</th>
            <th>Token Price in USD</th>
            <th>Total Raised</th>
            <th>Target Raised</th>
            <th>Total Token Sold</th>
            <th>Distribution</th>
            <th>Total Supply</th>
            <th>Status</th>
          </tr>
        </thead>
        {renderedProjects?.length ? (
          <tbody>
            {renderedProjects.map((item, index) => {
              if (item.status){
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr className="text-t-head-color">
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.token.symbol}</td>
                    <td>{item.token.price_in_mercurx}</td>
                    <td>
                      {' '}
                      {item.token.price_in_usd.toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'USD',
                        currencyDisplay: 'symbol',
                        useGrouping: true,
                        minimumFractionDigits: 4,
                      })}
                    </td>
                    <td>
                      {item.total_raised.toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'USD',
                        currencyDisplay: 'symbol',
                        useGrouping: true,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td>
                      {item.target_raised.toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'USD',
                        currencyDisplay: 'symbol',
                        useGrouping: true,
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td>
                      {calculateFormat(item.total_tokens_sold)}
                    </td>
                    <td>
                      {calculateFormat(item.token.distribution)}
                    </td>
                    <td>
                      {calculateFormat(item.token.total_supply)}
                    </td>

                    <td>
                      {item.is_active === 'active' ? (
                        <Badge bg="success">Active</Badge>
                      ) : item.is_active === 'completed' ? (
                        <Badge bg="danger">Completed</Badge>
                      ) : (
                        <Badge bg="secondary">Other</Badge>
                      )}
                    </td>
                    <td>
                    {user.is_superuser && (
                      <div className="cursor-pointer" onClick={() => editProject(item)}>
                        <MercurXIcons
                          name="BsPencilSquare"
                          size={18}
                          color={mainColors['primary']}
                        />
                      </div>
                        )}
                    </td>
                    <td>
                        {user.is_superuser && (
                      <div
                        className="cursor-pointer"
                        onClick={() => deleteProject(item.id, index)}
                      >
                        <MercurXIcons name="BsTrash3" size={18} color={mainColors['danger']} />
                      </div>
                        )}
                    </td>
                  </tr>
                );
              }}
            })}
          </tbody>
        ) : null}
      </Table>

      {!renderedProjects?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No token found according to search results.
        </div>
      )}

      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    projects: state.projectReducer.projects,
    filteredProjects: state.projectReducer.filteredProjects
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteProjectRequest: (payload) => {
      dispatch(deleteProjectActionRequest(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXProjectTable);
